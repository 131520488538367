import type { MaybeObservable } from "knockout";
import ko, { unwrap } from "knockout";

export type ClickOffCallback = () => void;

export type ClickOffElementParams =
   | ClickOffCallback
   | {
        onClickOff: ClickOffCallback;

        /**
         * Whether click events occurring within modals are ignored.
         * Defaults to `true`.
         */
        isIgnoringModals: MaybeObservable<boolean>;
     };

ko.bindingHandlers.clickOffElement = {
   init: (element: Element, valueAccessor: () => ClickOffElementParams) => {
      const params = valueAccessor();
      const onClickOff = params instanceof Function ? params : params.onClickOff;
      const isIgnoringModals = params instanceof Function ? true : params.isIgnoringModals ?? true;
      const modalContainer = document.querySelector("body > .modal");

      const handleEvent = (event: Event) => {
         const path = event.composedPath();

         // Ignore events that occur within the elements descendants.
         const isWithinElement = path.some((e) => e == element);
         if (isWithinElement) return;

         // Ignore events from within a modal when `isIgnoringModals` is true.
         if (unwrap(isIgnoringModals) && path.some((e) => e == modalContainer)) return;

         onClickOff();
      };

      document.addEventListener("click", handleEvent);
      document.addEventListener("touchstart", handleEvent);
      ko.utils.domNodeDisposal.addDisposeCallback(element, () => {
         document.removeEventListener("click", handleEvent);
         document.removeEventListener("touchstart", handleEvent);
      });
   },
};
