import ko from "knockout"

ko.bindingHandlers["boundedNumber"] =
   init: (element, valueAccessor) ->
      { min = null, max = null } = valueAccessor() || {}

      onChange = (event) ->
         value = event.target.value
         return if min == null &&  max == null

         if isNaN(Number(event.target.value))
            element.value = min || 0

         if max != null && value > max
            element.value = max
         if min != null && value < min
            element.value = min


      element.addEventListener("input", onChange)
      ko.utils.domNodeDisposal.addDisposeCallback element, ->
         element.removeEventListener('input', onChange)