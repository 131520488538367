import type { Size } from "@/lib/utils/geometry";
import type { Observable, Subscribable, Subscription } from "knockout";
import ko, { isObservable } from "knockout";

type SizeParams =
   | Observable<Size>
   | {
        size: Observable<Size>;
        trigger?: Subscribable | null;
     };

ko.bindingHandlers.size = {
   init: (element: Element, valueAccessor) => {
      const params = valueAccessor() as SizeParams;
      const size = isObservable(params) ? params : (params as { size: Observable<Size> }).size;
      const trigger = isObservable(params)
         ? null
         : (params as { trigger?: Subscribable }).trigger ?? null;

      const updateSize = () => {
         const newSize = {
            height: element.clientHeight,
            width: element.clientWidth,
         };
         const currentSize = size.peek();
         if (newSize.height != currentSize.height || newSize.width != currentSize.width) {
            size(newSize);
         }
      };

      // Subscribe to the trigger if it's visible.
      let subscription: Subscription | null = null;
      if (trigger) {
         subscription = trigger.subscribe(() => {
            updateSize();
            requestAnimationFrame(updateSize);
         });
      }

      window.addEventListener("resize", updateSize);
      ko.utils.domNodeDisposal.addDisposeCallback(element, () => {
         window.removeEventListener("resize", updateSize);
         if (subscription) subscription.dispose();
      });

      // Trigger the initial update.
      updateSize();
      requestAnimationFrame(updateSize);
   },
};
