import type { Observable } from "knockout";
import ko, { unwrap } from "knockout";

ko.bindingHandlers.scrollTop = {
   init: (element: Element, valueAccessor) => {
      const scrollTop: Observable<number> = valueAccessor();
      const updateScroll = () => {
         scrollTop(element.scrollTop);
      };
      element.addEventListener("scroll", updateScroll);
      ko.utils.domNodeDisposal.addDisposeCallback(element, () => {
         element.removeEventListener("scroll", updateScroll);
      });

      // Trigger the initial update.
      updateScroll();
   },

   update: (element: Element, valueAccessor) => {
      const value = unwrap(valueAccessor());
      if (element.scrollTop != value) {
         element.scrollTop = value;
      }
   },
};
