ko.bindingHandlers["animateHeight"] =
   init: (element, valueAccessor) ->
      $element = $(element)
      value = ko.unwrap(valueAccessor())

      if value.trigger
         value.trigger.subscribe(() ->
            return unless $element.children().length
            child = $element.children().get(0)
            $element.css("height", child.offsetHeight)
            requestAnimationFrame ->
               $element.css("height", child.offsetHeight)
         , null, "beforeChange")

      $element.on "transitionend", -> $element.css("height", "")