import ko from "knockout";

export type OnEscapeCallback = (event: KeyboardEvent, isElementOrDescendant: boolean) => void;

ko.bindingHandlers.onEscape = {
   init: (element: Element, valueAccessor) => {
      const onEscape = valueAccessor() as OnEscapeCallback;
      const onWindowKeyDown = (event: KeyboardEvent) => {
         if (event.key == "Escape") {
            onEscape(
               event,
               Boolean(event.target instanceof Node && element.contains(event.target)),
            );
         }
      };

      window.addEventListener("keydown", onWindowKeyDown);
      ko.utils.domNodeDisposal.addDisposeCallback(element, () => {
         document.removeEventListener("keydown", onWindowKeyDown);
      });
   },
};
