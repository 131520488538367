import * as d3 from "d3"
import ko from "knockout"
import $ from "jquery"
import * as Format from '@laborchart-modules/common/dist/format'
import { DateUtils } from "@/lib/utils/date"
import { defaultStore } from "@/stores/default-store"

drawChart = (element, totalsData, options, pagingDraw) ->
   frameWidth = element.offsetWidth
   frameHeight = element.offsetHeight

   infoSecWidth = 200
   projectTitleRowHeight = 30
   detailRowHeight = 20
   primaryRowPadding = 10
   initialCategoryPadding = 5

   showTimeOff = options.permissions.canViewTimeOff && options.days <= 56;

   # Factoring showing assigned rollup.
   rollupChartHeight = detailRowHeight
   # Factoring showing requests and totals rollup.
   rollupChartHeight += detailRowHeight * 2 if options.permissions.canViewRequests

   # Factor in the time off row 
   rollupChartHeight += detailRowHeight if showTimeOff
   # Factoring showing delta rollup.
   rollupChartHeight += detailRowHeight if options.permissions.canViewRequests and options.totalsUnits == "people"
   primaryColorCircleDiameter = 10

   todayDetachedDay = DateUtils.getDetachedDay(new Date())

   chartWidth = frameWidth - infoSecWidth

   rangeStart = options.startDate

   rangeEnd = new Date(options.startDate.getTime())
   rangeEnd.setDate(rangeStart.getDate() + options.days)

   # Setup X Axis
   xScale = d3.scaleTime()
   # This needs to be be 1 tick longer so that the labels can be
   # centered and it will still display properly.
   .domain([rangeStart, rangeEnd])
   .range([0, chartWidth])
   .clamp(true)

   hoursToPixels = (hours) ->
      d1 = new Date(options.startDate.getTime())
      return xScale(d3.timeHour.offset(d1, hours)) - xScale(d1)

   daysToPixels = (days) ->
      d1 = new Date(options.startDate.getTime())
      return xScale(d3.timeDay.offset(d1, days)) - xScale(d1)

   monthsToPixels = (months) ->
      d1 = new Date(options.startDate.getTime())
      return xScale(d3.timeMonth.offset(d1, months)) - xScale(d1)

   # Gets valid temporal values.
   if options.days == 1
      ticks = xScale.ticks(d3.timeHour.every(1))
   else if options.days < 91
      ticks = xScale.ticks(d3.timeDay.every(1))
   else if options.days < 182
      ticks = xScale.ticks(d3.timeWeek.every(1))
   else
      ticks = xScale.ticks(d3.timeMonth.every(1))

   # Clear any existing data
   unless pagingDraw
      $("#totals-rollup__x-axis").empty()
      $("#totals-rollup-chart-wrapper").empty()
      $("#totals-breakdown__x-axis").empty()

   $("#totals-wrapper").empty()

   
   svg = d3.select("#totals-wrapper")
      .append("svg")
      .attr("class", "totals-svg")
      .attr("width", frameWidth)
      .attr("height", frameHeight)
      .attr("overflow", "visible")

   # Grid lines
   gridlines = d3.axisTop()
     .tickFormat("")
     .tickSize(-frameHeight)
     .scale(xScale)

   gridlines.tickValues(ticks)

   if options.days == 1
      tickFormat = d3.timeFormat("%I:%M %p")
   else if options.days < 28
      tickFormat = d3.timeFormat("%a %e")
   else if options.days < 56
      if frameWidth <= 1200
         tickFormat = d3.timeFormat("%e")
      else
         tickFormat = d3.timeFormat("%a %e")
   else if options.days < 91
      tickFormat = d3.timeFormat("%e")
   else if options.days < 182
      tickFormat = if DateUtils.hasDayBeforeMonth(defaultStore.getDateFormat()) then d3.timeFormat("%e %b") else d3.timeFormat("%b %e")
   else
      tickFormat = d3.timeFormat("%b")

   if options.days == 1
      secondaryTickFormat = (date) -> DateUtils.formatDate(date, defaultStore.getDateFormat(), DateUtils.LONG_FORM_OPTIONS)
   else if options.days < 182
      secondaryTickFormat = d3.timeFormat("%B")
   else
      secondaryTickFormat = d3.timeFormat("%Y")

   xAxis = d3.axisBottom(xScale)
      .tickFormat(tickFormat)
      .tickSize(18.6)

   xAxis.tickValues(ticks)

   # For secondary axis
   secondaryStart = new Date(rangeStart.getTime())
   unless options.days == 1
      secondaryStart.setDate(1)

   unless options.days < 182
      secondaryStart.setMonth(0)

   if options.days == 1
      secondaryEnd = new Date(rangeStart.getTime())
   else
      secondaryEnd = new Date(rangeEnd.getTime())
      secondaryEnd.setDate(1)

   secondaryXScale = d3.scaleTime()
      .domain([secondaryStart, secondaryEnd])
      .range([0, chartWidth])
      .clamp(true)

   colorWeekends = (selection) ->
      if 1 < options.days < 91
         # Daily
         selection.selectAll('.tick').each (data) ->
            detachedDay = DateUtils.getDetachedDay(data)
            day = data.getDay()
            # Needs to be in this order to make sure weekends don't cover today.
            if detachedDay == todayDetachedDay
               d3.select(@).insert("rect", ":first-child")
                  .attr("class", "today-bg")
                  # Allowing the domain line to show.
                  .attr("height", 19)
                  .attr("width", daysToPixels(1))
                  .attr("y", 1)
                  .attr("fill", "orange")

            if day == 0 or day == 6
               d3.select(@).insert("rect", ":first-child")
                  .attr("class", "weekend-bg")
                  # .attr("height", frameHeight)
                  .attr("height", 20)
                  .attr("width", daysToPixels(1))
                  .attr("fill", "black")
                  .attr("opacity", "0.2")

      else if 91 <= options.days < 182
         # Weekly
         thisWeekStartDate = DateUtils.getAttachedDate(todayDetachedDay)
         thisWeekStartMs = thisWeekStartDate.setDate(thisWeekStartDate.getDate() - thisWeekStartDate.getDay())
         thisWeeksStartingDay = DateUtils.getDetachedDay(new Date(thisWeekStartMs))

         selection.selectAll('.tick').each (data) ->
            # Decouple
            weekStartDate = new Date(data.getTime())
            weekStartMs = weekStartDate.setDate(weekStartDate.getDate() - weekStartDate.getDay())
            weeksStartingDay = DateUtils.getDetachedDay(new Date(weekStartMs))

            if weeksStartingDay == thisWeeksStartingDay
               d3.select(@).insert("rect", ":first-child")
                  .attr("class", "today-bg")
                  # Allowing the domain line to show.
                  .attr("height", 19)
                  .attr("width", daysToPixels(7))
                  .attr("y", 1)
                  .attr("fill", "orange")

      else if 182 <= options.days
         # Monthly
         thisMonthChunk = String(todayDetachedDay).slice(0, -2)

         selection.selectAll('.tick').each (data) ->
            detachedDay = DateUtils.getDetachedDay(data)
            monthChunk = String(detachedDay).slice(0, -2)

            if monthChunk == thisMonthChunk
               sectionStart = xScale(data)
            
               nextTickDate = new Date(data.getTime())
               nextTickDate.setMonth(nextTickDate.getMonth() + 1)
               nextTickDate.setDate(1)
               sectionEnd = xScale(nextTickDate)

               offset = (sectionEnd - sectionStart)

               d3.select(@).insert("rect", ":first-child")
                  .attr("class", "today-bg")
                  # Allowing the domain line to show.
                  .attr("height", 19)
                  .attr("width", offset)
                  .attr("y", 1)
                  .attr("fill", "orange")

   colorDeltas = (selection) ->
      selection.selectAll('.tick').each (data) ->
         # Evaluate weekend and today backgrounds
         foundToday = false
         if 1 < options.days < 91
            # Daily
            detachedDay = DateUtils.getDetachedDay(data)
            day = data.getDay()
            # Needs to be in this order to make sure weekends don't cover today.
            if detachedDay == todayDetachedDay
               foundToday = true
               d3.select(@).insert("rect", ":first-child")
                  .attr("class", "today-bg")
                  # Allowing the domain line to show.
                  .attr("height", 19)
                  .attr("width", daysToPixels(1))
                  .attr("y", 1)
                  .attr("fill", "orange")

            if day == 0 or day == 6
               d3.select(@).insert("rect", ":first-child")
                  .attr("class", "weekend-bg")
                  # .attr("height", frameHeight)
                  .attr("height", 20)
                  .attr("width", daysToPixels(1))
                  .attr("fill", "black")
                  .attr("opacity", "0.2")

         else if 91 <= options.days < 182
            # Weekly
            thisWeekStartDate = DateUtils.getAttachedDate(todayDetachedDay)
            thisWeekStartMs = thisWeekStartDate.setDate(thisWeekStartDate.getDate() - thisWeekStartDate.getDay())
            thisWeeksStartingDay = DateUtils.getDetachedDay(new Date(thisWeekStartMs))

            # Decouple
            weekStartDate = new Date(data.getTime())
            weekStartMs = weekStartDate.setDate(weekStartDate.getDate() - weekStartDate.getDay())
            weeksStartingDay = DateUtils.getDetachedDay(new Date(weekStartMs))

            if weeksStartingDay == thisWeeksStartingDay
               foundToday = true
               d3.select(@).insert("rect", ":first-child")
                  .attr("class", "today-bg")
                  # Allowing the domain line to show.
                  .attr("height", 19)
                  .attr("width", daysToPixels(7))
                  .attr("y", 1)
                  .attr("fill", "orange")

         else if 182 <= options.days
            # Monthly
            thisMonthChunk = String(todayDetachedDay).slice(0, -2)
            detachedDay = DateUtils.getDetachedDay(data)
            monthChunk = String(detachedDay).slice(0, -2)

            if monthChunk == thisMonthChunk
               foundToday = true
               sectionStart = xScale(data)
            
               nextTickDate = new Date(data.getTime())
               nextTickDate.setMonth(nextTickDate.getMonth() + 1)
               nextTickDate.setDate(1)
               sectionEnd = xScale(nextTickDate)

               offset = (sectionEnd - sectionStart)

               d3.select(@).insert("rect", ":first-child")
                  .attr("class", "today-bg")
                  # Allowing the domain line to show.
                  .attr("height", 19)
                  .attr("width", offset)
                  .attr("y", 1)
                  .attr("fill", "orange")

         return if foundToday

         text = d3.select(@).select('text').text()
         if text? and text != ''
            value = Number(text.replaceAll(",", ""))
            if 1 < options.days < 91
               width = daysToPixels(1)
            else if 91 <= options.days < 182
               width = daysToPixels(7)
            else if 182 <= options.days
               sectionStart = xScale(data)
               
               nextTickDate = new Date(data.getTime())
               nextTickDate.setMonth(nextTickDate.getMonth() + 1)
               nextTickDate.setDate(1)
               sectionEnd = xScale(nextTickDate)

               width = (sectionEnd - sectionStart)

            if value < 0
               className = 'delta-bg--negative'
            else if value == 0
               className = 'delta-bg--zero'
            else if value > 0
               className = 'delta-bg--positive'

            d3.select(@).insert("rect", ":first-child")
               .attr("class", className)
               # Allowing the domain line to show.
               .attr("height", 19)
               .attr("width", width)
               .attr("y", 1)

   adjustTextLabels = (selection) ->
      if 91 <= options.days < 182
         thisWeekStartDate = DateUtils.getAttachedDate(todayDetachedDay)
         thisWeekStartMs = thisWeekStartDate.setDate(thisWeekStartDate.getDate() - thisWeekStartDate.getDay())
         thisWeeksStartingDay = DateUtils.getDetachedDay(new Date(thisWeekStartMs))
      else if 182 <= options.days
         thisMonthChunk = String(todayDetachedDay).slice(0, -2)

      selection.selectAll('.tick').each (data) ->
         if 1 < options.days < 91
            detachedDay = DateUtils.getDetachedDay(data)
            day = data.getDay()
            # Need to be in this order so weekends don't cover today.
            if detachedDay == todayDetachedDay
               d3.select(@).insert("rect", ":first-child")
                  .attr("class", "axis-today-bg")
                  # Allowing the domain line to show.
                  .attr("height", 19)
                  .attr("width", daysToPixels(1))
                  .attr("y", 1)
                  .attr("fill", "orange")

            if day == 0 or day == 6
               d3.select(@).insert("rect", ":first-child")
                  .attr("class", "axis-weekend-bg")
                  .attr("height", 20)
                  .attr("width", daysToPixels(1))
                  .attr("fill", "black")
                  .attr("opacity", "0.2")


         else if 91 <= options.days < 182
            # Weekly
            # Decouple
            weekStartDate = new Date(data.getTime())
            weekStartMs = weekStartDate.setDate(weekStartDate.getDate() - weekStartDate.getDay())
            weeksStartingDay = DateUtils.getDetachedDay(new Date(weekStartMs))

            if weeksStartingDay == thisWeeksStartingDay
               d3.select(@).insert("rect", ":first-child")
                  .attr("class", "axis-today-bg")
                  # Allowing the domain line to show.
                  .attr("height", 19)
                  .attr("width", daysToPixels(7))
                  .attr("y", 1)
                  .attr("fill", "orange")

         else if 182 <= options.days
            detachedDay = DateUtils.getDetachedDay(data)
            monthChunk = String(detachedDay).slice(0, -2)

            if monthChunk == thisMonthChunk
               sectionStart = xScale(data)
               
               nextTickDate = new Date(data.getTime())
               nextTickDate.setMonth(nextTickDate.getMonth() + 1)
               nextTickDate.setDate(1)
               sectionEnd = xScale(nextTickDate)

               offset = (sectionEnd - sectionStart)

               d3.select(@).insert("rect", ":first-child")
                  .attr("class", "axis-today-bg")
                  # Allowing the domain line to show.
                  .attr("height", 19)
                  .attr("width", offset)
                  .attr("y", 1)
                  .attr("fill", "orange")

         if options.days == 1
            xDelta = hoursToPixels(1)
         else if options.days < 91
            xDelta = daysToPixels(1)
         else if options.days < 182
            # TODO: evaluate per tick calculation to handle non-whole segmeents (like secondary axis).
            xDelta = daysToPixels(7)
         else
            # TODO: evaluate per tick calculation to handle non-whole segmeents (like secondary axis).
            xDelta = monthsToPixels(1)

         d3.select(@).selectAll('text')
            .attr('transform', 'translate(' + xDelta / 2 + ',-14)')

   adjustSecondaryTextLabels = (selection) ->
      selection.selectAll('.tick').each (data) ->
         sectionStart = xScale(data)
         if options.days < 182
            nextTickDate = new Date(data.getTime())
            nextTickDate.setMonth(nextTickDate.getMonth() + 1)
            nextTickDate.setDate(1)
            sectionEnd = xScale(nextTickDate)

         else
            nextTickDate = new Date(data.getTime())
            nextTickDate.setYear(nextTickDate.getFullYear() + 1)
            nextTickDate.setMonth(0)
            nextTickDate.setDate(1)
            sectionEnd = xScale(nextTickDate)

         if 1 < options.days <= 91
            # Hide months that don't fit
            offset = (sectionEnd - sectionStart) / 2
            d3.select(@).selectAll('text')
               .each () ->
                  width = @.getComputedTextLength()
                  if width >= offset
                     @.remove()
               .attr('transform', "translate(#{offset},-14)")
         else
            offset = (sectionEnd - sectionStart) / 2
            d3.select(@).selectAll('text')
               .attr('transform', "translate(#{offset},-14)")

   roundAndFormatValue = (num) ->
      return Format.formatNumberShort(Format.ceilToNearestHalf(num))

   adjustRowTicks = (selection, rowData, timeOffData = {}) ->
      selection.selectAll('.tick').each (data) ->
         if options.days == 1
            xDelta = hoursToPixels(1)
         else if options.days < 91
            xDelta = daysToPixels(1)
         else if options.days < 182
            # TODO: evaluate per tick calculation to handle non-whole segmeents (like secondary axis).
            xDelta = daysToPixels(7)
         else
            # TODO: evaluate per tick calculation to handle non-whole segmeents (like secondary axis).
            xDelta = monthsToPixels(1)

         day = DateUtils.getDetachedDay(data)

         totalsValue = rowData[day]

         timeOffValue = timeOffData[day]

         totalsValue = '' if totalsValue == 0 or !totalsValue?

         tickFont = "11px OpenSans"
         if totalsValue != ''
            if options.days < 91
               if xDelta < 30
                  # Short format.
                  tickFont = "8px OpenSans"

                  totalsValue = roundAndFormatValue(totalsValue)
               else
                  totalsValue = Number(Format.ceilToNearestHalf(totalsValue)).toLocaleString()
               if showTimeOff && timeOffValue 
                  totalsValue += " (#{Number(Format.ceilToNearestHalf(timeOffValue)).toLocaleString()})"
            else
               if xDelta < 50
                  # Long format.
                  tickFont = "9px OpenSans"

                  totalsValue = roundAndFormatValue(totalsValue)
               else
                  totalsValue = Number(Format.ceilToNearestHalf(totalsValue)).toLocaleString()

         d3.select(@).selectAll('text')
            .text(totalsValue)
            .attr('transform', 'translate(' + xDelta / 2 + ',-14)')
            .style('font', tickFont)

   # TODO: After all layers are added, evaluate joining all these into a shared utility function.
   adjustRollupDeltaTicks = (selection) ->
      if options.days == 1
         # TODO: Update this
         rowData = {}
      else if options.days < 91
         # Daily
         if options.totalsUnits == "people"
            rowData = {}
            for key, val of totalsData.rollupData.dailyAssTotals
               rowData[key] = val.peopleIds.length

            for key, val of totalsData.rollupData.dailyRequestTotals
               if rowData[key]?
                  rowData[key] += val.requestIds.length
               else
                  rowData[key] = val.requestIds.length
         else
            rowData = {
               ...totalsData.rollupData.dailyAssTotals,
            }
            for key, val of totalsData.rollupData.dailyRequestTotals
               if rowData[key]?
                  rowData[key] += val
               else
                  rowData[key] = val

      else if options.days < 182
         # Weekly
         rawRowData = {}
         rowData = {}
         for key, val of totalsData.rollupData.dailyAssTotals
            weekStartDate = DateUtils.getAttachedDate(Number(key))
            weekStartMs = weekStartDate.setDate(weekStartDate.getDate() - weekStartDate.getDay())
            weeksStartingDay = DateUtils.getDetachedDay(new Date(weekStartMs))

            if options.totalsUnits == "people"
               if rawRowData[weeksStartingDay]?
                  for personId in val.peopleIds
                     unless rawRowData[weeksStartingDay].peopleIds.indexOf(personId) != -1
                        rawRowData[weeksStartingDay].peopleIds.push(personId)
               else
                  rawRowData[weeksStartingDay] = {peopleIds: val.peopleIds, requestIds: []}
            else
               if rowData[weeksStartingDay]?
                  rowData[weeksStartingDay] += val
               else
                  rowData[weeksStartingDay] = val

         for key, val of totalsData.rollupData.dailyRequestTotals
            weekStartDate = DateUtils.getAttachedDate(Number(key))
            weekStartMs = weekStartDate.setDate(weekStartDate.getDate() - weekStartDate.getDay())
            weeksStartingDay = DateUtils.getDetachedDay(new Date(weekStartMs))

            if options.totalsUnits == "people"
               if rawRowData[weeksStartingDay]?
                  for requestId in val.requestIds
                     unless rawRowData[weeksStartingDay].requestIds.indexOf(requestId) != -1
                        rawRowData[weeksStartingDay].requestIds.push(requestId)
               else
                  rawRowData[weeksStartingDay] = {requestIds: val.requestIds, peopleIds: []}
            else
               if rowData[weeksStartingDay]?
                  rowData[weeksStartingDay] += val
               else
                  rowData[weeksStartingDay] = val

         if options.totalsUnits == "people"
            for key, val of rawRowData
               rowData[key] = (val.requestIds.length + val.peopleIds.length)

      else if options.days >= 182
         # Monthly
         rowData = {}

         if options.totalsUnits == "people"
            rawRowData = {}

            for key, val of totalsData.rollupData.dailyAssTotals
               monthKey = String(key).slice(0, -2)
               monthKey = "#{monthKey}01"
               if rawRowData[monthKey]?
                  for personId in val.peopleIds
                     if rawRowData[monthKey].peopleIds.indexOf(personId) == -1
                        rawRowData[monthKey].peopleIds.push(personId)
               else
                  rawRowData[monthKey] = {peopleIds: val.peopleIds, requestIds: []}

            for key, val of totalsData.rollupData.dailyRequestTotals
               monthKey = String(key).slice(0, -2)
               monthKey = "#{monthKey}01"
               if rawRowData[monthKey]?
                  for requestId in val.requestIds
                     if rawRowData[monthKey].requestIds.indexOf(requestId) == -1
                        rawRowData[monthKey].requestIds.push(requestId)
               else
                  rawRowData[monthKey] = {requestIds: val.requestIds, peopleIds: []}

            for key, val of rawRowData
               rowData[key] = (val.peopleIds.length + val.requestIds.length)
         else
            for key, val of totalsData.rollupData.dailyAssTotals
               monthKey = String(key).slice(0, -2)
               monthKey = "#{monthKey}01"
               if rowData[monthKey]?
                  rowData[monthKey] += val
               else
                  rowData[monthKey] = val

            for key, val of totalsData.rollupData.dailyRequestTotals
               monthKey = String(key).slice(0, -2)
               monthKey = "#{monthKey}01"
               if rowData[monthKey]?
                  rowData[monthKey] += val
               else
                  rowData[monthKey] = val

      if 1 < options.days < 91
         minHistoricalKey = todayDetachedDay
      else if 91 <= options.days < 182
         thisWeekStartDate = DateUtils.getAttachedDate(todayDetachedDay)
         thisWeekStartMs = thisWeekStartDate.setDate(thisWeekStartDate.getDate() - thisWeekStartDate.getDay())
         minHistoricalKey = DateUtils.getDetachedDay(new Date(thisWeekStartMs))
      else if 182 <= options.days
         minHistoricalKey = Number(String(todayDetachedDay).slice(0, -2) + "01")

      # Process Deltas
      deltaData = {}
      for key, val of rowData
         if key >= minHistoricalKey
            deltaData[key] = options.activeResources - val
      adjustRowTicks(selection, deltaData)

   adjustRollupAssignedTicks = (selection) ->
      if options.days == 1
         # TODO: Update this
         rowData = {}
      else if options.days < 91
         # Daily
         if options.totalsUnits == "people"
            rowData = {}
            for key, val of totalsData.rollupData.dailyAssTotals
               rowData[key] = val.peopleIds.length
         else
            rowData = {
               ...totalsData.rollupData.dailyAssTotals
            }
      else if options.days < 182
         # Weekly
         rawRowData = {}
         rowData = {}
         for key, val of totalsData.rollupData.dailyAssTotals
            weekStartDate = DateUtils.getAttachedDate(Number(key))
            weekStartMs = weekStartDate.setDate(weekStartDate.getDate() - weekStartDate.getDay())
            weeksStartingDay = DateUtils.getDetachedDay(new Date(weekStartMs))

            if options.totalsUnits == "people"
               if rawRowData[weeksStartingDay]?
                  for personId in val.peopleIds
                     unless rawRowData[weeksStartingDay].peopleIds.indexOf(personId) != -1
                        rawRowData[weeksStartingDay].peopleIds.push(personId)
               else
                  rawRowData[weeksStartingDay] = {peopleIds: val.peopleIds}
            else
               if rowData[weeksStartingDay]?
                  rowData[weeksStartingDay] += val
               else
                  rowData[weeksStartingDay] = val

         if options.totalsUnits == "people"
            for key, val of rawRowData
               rowData[key] = val.peopleIds.length
      else if options.days >= 182
         # Monthly
         rowData = {}

         if options.totalsUnits == "people"
            rawRowData = {}

            for key, val of totalsData.rollupData.dailyAssTotals
               monthKey = String(key).slice(0, -2)
               monthKey = "#{monthKey}01"
               if rawRowData[monthKey]?
                  for personId in val.peopleIds
                     if rawRowData[monthKey].peopleIds.indexOf(personId) == -1
                        rawRowData[monthKey].peopleIds.push(personId)
               else
                  rawRowData[monthKey] = {peopleIds: val.peopleIds}

            for key, val of rawRowData
               rowData[key] = val.peopleIds.length
         else
            for key, val of totalsData.rollupData.dailyAssTotals
               monthKey = String(key).slice(0, -2)
               monthKey = "#{monthKey}01"
               if rowData[monthKey]?
                  rowData[monthKey] += val
               else
                  rowData[monthKey] = val

      adjustRowTicks(selection, rowData)
   
   adjustRollupTimeOffTicks = (selection) ->
      if options.days == 1
         # TODO: Update this
         rowData = {}
      else if options.days < 91
         # Daily
         if options.totalsUnits == "people"
            rowData = {}
            for key, val of totalsData.rollupData.dailyTimeOffTotals
               rowData[key] = val.timeOffIds.length
         else
            rowData = {
               ...totalsData.rollupData.dailyTimeOffTotals
            }
      
      adjustRowTicks(selection, rowData)

   adjustRollupRequestedTicks = (selection) ->
      if options.days == 1
         # TODO: Update this
         rowData = {}
      else if options.days < 91
         # Daily
         if options.totalsUnits == "people"
            rowData = {}
            for key, val of totalsData.rollupData.dailyRequestTotals
               rowData[key] = val.requestIds.length
         else
            rowData = {
               ...totalsData.rollupData.dailyRequestTotals
            }
      else if options.days < 182
         # Weekly
         rawRowData = {}
         rowData = {}
         for key, val of totalsData.rollupData.dailyRequestTotals
            weekStartDate = DateUtils.getAttachedDate(Number(key))
            weekStartMs = weekStartDate.setDate(weekStartDate.getDate() - weekStartDate.getDay())
            weeksStartingDay = DateUtils.getDetachedDay(new Date(weekStartMs))

            if options.totalsUnits == "people"
               if rawRowData[weeksStartingDay]?
                  for requestId in val.requestIds
                     unless rawRowData[weeksStartingDay].requestIds.indexOf(requestId) != -1
                        rawRowData[weeksStartingDay].requestIds.push(requestId)
               else
                  rawRowData[weeksStartingDay] = {requestIds: val.requestIds}
            else
               if rowData[weeksStartingDay]?
                  rowData[weeksStartingDay] += val
               else
                  rowData[weeksStartingDay] = val

         if options.totalsUnits == "people"
            for key, val of rawRowData
               rowData[key] = val.requestIds.length
      else if options.days >= 182
         # Monthly
         rowData = {}

         if options.totalsUnits == "people"
            rawRowData = {}

            for key, val of totalsData.rollupData.dailyRequestTotals
               monthKey = String(key).slice(0, -2)
               monthKey = "#{monthKey}01"
               if rawRowData[monthKey]?
                  for requestId in val.requestIds
                     if rawRowData[monthKey].requestIds.indexOf(requestId) == -1
                        rawRowData[monthKey].requestIds.push(requestId) 
               else
                  rawRowData[monthKey] = {requestIds: val.requestIds}

            for key, val of rawRowData
               rowData[key] = val.requestIds.length
         else
            for key, val of totalsData.rollupData.dailyRequestTotals
               monthKey = String(key).slice(0, -2)
               monthKey = "#{monthKey}01"
               if rowData[monthKey]?
                  rowData[monthKey] += val
               else
                  rowData[monthKey] = val
      
      adjustRowTicks(selection, rowData)

   adjustRollupTotalTicks = (selection) ->
      if options.days == 1
         # TODO: Update this
         rowData = {}
      else if options.days < 91
         # Daily
         if options.totalsUnits == "people"
            rowData = {}
            for key, val of totalsData.rollupData.dailyAssTotals
               rowData[key] = val.peopleIds.length

            for key, val of totalsData.rollupData.dailyRequestTotals
               if rowData[key]?
                  rowData[key] += val.requestIds.length
               else
                  rowData[key] = val.requestIds.length
            
            timeOffData = {}
            if showTimeOff
               for key, val of totalsData.rollupData.dailyTimeOffTotals
                  if timeOffData[key]?
                     timeOffData[key] += val.timeOffIds.length
                  else
                     timeOffData[key] = val.timeOffIds.length
         else
            rowData = {
               ...totalsData.rollupData.dailyAssTotals
            }
            for key, val of totalsData.rollupData.dailyRequestTotals
               if rowData[key]?
                  rowData[key] += val
               else
                  rowData[key] = val
            
            timeOffData = {}
            if showTimeOff 
               for key, val of totalsData.rollupData.dailyTimeOffTotals
                  if timeOffData[key]?
                     timeOffData[key] += val
                  else
                     timeOffData[key] = val

      else if options.days < 182
         # Weekly
         rawRowData = {}
         rowData = {}
         for key, val of totalsData.rollupData.dailyAssTotals
            weekStartDate = DateUtils.getAttachedDate(Number(key))
            weekStartMs = weekStartDate.setDate(weekStartDate.getDate() - weekStartDate.getDay())
            weeksStartingDay = DateUtils.getDetachedDay(new Date(weekStartMs))

            if options.totalsUnits == "people"
               if rawRowData[weeksStartingDay]?
                  for personId in val.peopleIds
                     unless rawRowData[weeksStartingDay].peopleIds.indexOf(personId) != -1
                        rawRowData[weeksStartingDay].peopleIds.push(personId)
               else
                  rawRowData[weeksStartingDay] = {peopleIds: val.peopleIds, requestIds: []}
            else
               if rowData[weeksStartingDay]?
                  rowData[weeksStartingDay] += val
               else
                  rowData[weeksStartingDay] = val

         for key, val of totalsData.rollupData.dailyRequestTotals
            weekStartDate = DateUtils.getAttachedDate(Number(key))
            weekStartMs = weekStartDate.setDate(weekStartDate.getDate() - weekStartDate.getDay())
            weeksStartingDay = DateUtils.getDetachedDay(new Date(weekStartMs))

            if options.totalsUnits == "people"
               if rawRowData[weeksStartingDay]?
                  for requestId in val.requestIds
                     unless rawRowData[weeksStartingDay].requestIds.indexOf(requestId) != -1
                        rawRowData[weeksStartingDay].requestIds.push(requestId)
               else
                  rawRowData[weeksStartingDay] = {requestIds: val.requestIds, peopleIds: []}
            else
               if rowData[weeksStartingDay]?
                  rowData[weeksStartingDay] += val
               else
                  rowData[weeksStartingDay] = val

         if options.totalsUnits == "people"
            for key, val of rawRowData
               rowData[key] = (val.requestIds.length + val.peopleIds.length)

      else if options.days >= 182
         # Monthly
         rowData = {}

         if options.totalsUnits == "people"
            rawRowData = {}

            for key, val of totalsData.rollupData.dailyAssTotals
               monthKey = String(key).slice(0, -2)
               monthKey = "#{monthKey}01"
               if rawRowData[monthKey]?
                  for personId in val.peopleIds
                     if rawRowData[monthKey].peopleIds.indexOf(personId) == -1
                        rawRowData[monthKey].peopleIds.push(personId) 
               else
                  rawRowData[monthKey] = {peopleIds: val.peopleIds, requestIds: []}

            for key, val of totalsData.rollupData.dailyRequestTotals
               monthKey = String(key).slice(0, -2)
               monthKey = "#{monthKey}01"
               if rawRowData[monthKey]?
                  for requestId in val.requestIds
                     if rawRowData[monthKey].requestIds.indexOf(requestId) == -1
                        rawRowData[monthKey].requestIds.push(requestId) 
               else
                  rawRowData[monthKey] = {requestIds: val.requestIds, peopleIds: []}

            for key, val of rawRowData
               rowData[key] = (val.peopleIds.length + val.requestIds.length)
         else
            for key, val of totalsData.rollupData.dailyAssTotals
               monthKey = String(key).slice(0, -2)
               monthKey = "#{monthKey}01"
               if rowData[monthKey]?
                  rowData[monthKey] += val
               else
                  rowData[monthKey] = val

            for key, val of totalsData.rollupData.dailyRequestTotals
               monthKey = String(key).slice(0, -2)
               monthKey = "#{monthKey}01"
               if rowData[monthKey]?
                  rowData[monthKey] += val
               else
                  rowData[monthKey] = val

      adjustRowTicks(selection, rowData, timeOffData)

   adjustBreakdownAssignedTicks = (selection) ->
      if options.days == 1
         # TODO: Update this
         rowData = {}
      else if options.days < 91
         # Daily
         if options.totalsUnits == "people"
            rowData = {}
            for key, val of selection.datum().dailyAssTotals
               rowData[key] = val.peopleIds.length
         else
            rowData = {
               ...selection.datum().dailyAssTotals,
            }
      else if options.days < 182
         # Weekly
         rawRowData = {}
         rowData = {}
         for key, val of selection.datum().dailyAssTotals
            weekStartDate = DateUtils.getAttachedDate(Number(key))
            weekStartMs = weekStartDate.setDate(weekStartDate.getDate() - weekStartDate.getDay())
            weeksStartingDay = DateUtils.getDetachedDay(new Date(weekStartMs))

            if options.totalsUnits == "people"
               if rawRowData[weeksStartingDay]?
                  for personId in val.peopleIds
                     unless rawRowData[weeksStartingDay].peopleIds.indexOf(personId) != -1
                        rawRowData[weeksStartingDay].peopleIds.push(personId)
               else
                  rawRowData[weeksStartingDay] = {peopleIds: val.peopleIds}
            else
               if rowData[weeksStartingDay]?
                  rowData[weeksStartingDay] += val
               else
                  rowData[weeksStartingDay] = val

         if options.totalsUnits == "people"
            for key, val of rawRowData
               rowData[key] = val.peopleIds.length
      else if options.days >= 182
         # Monthly
         rowData = {}

         if options.totalsUnits == "people"
            rawRowData = {}

            for key, val of selection.datum().dailyAssTotals
               monthKey = String(key).slice(0, -2)
               monthKey = "#{monthKey}01"
               if rawRowData[monthKey]?
                  for personId in val.peopleIds
                     if rawRowData[monthKey].peopleIds.indexOf(personId) == -1
                        rawRowData[monthKey].peopleIds.push(personId) 
               else
                  rawRowData[monthKey] = {peopleIds: val.peopleIds}

            for key, val of rawRowData
               rowData[key] = val.peopleIds.length
         else
            for key, val of selection.datum().dailyAssTotals
               monthKey = String(key).slice(0, -2)
               monthKey = "#{monthKey}01"
               if rowData[monthKey]?
                  rowData[monthKey] += val
               else
                  rowData[monthKey] = val
      
      adjustRowTicks(selection, rowData)

   adjustBreakdownTimeOffTicks = (selection) ->
      if options.days < 91
         # Daily
         if options.totalsUnits == "people"
            rowData = {}
            for key, val of selection.datum().dailyTimeOffTotals
               rowData[key] = val.timeOffIds.length
         else
            rowData = {
               ...selection.datum().dailyTimeOffTotals
            }
      adjustRowTicks(selection, rowData)

   adjustBreakdownRequestedTicks = (selection) ->
      if options.days == 1
         # TODO: Update this
         rowData = {}
      else if options.days < 91
         # Daily
         if options.totalsUnits == "people"
            rowData = {}
            for key, val of selection.datum().dailyRequestTotals
               rowData[key] = val.requestIds.length
         else
            rowData = {
               ...selection.datum().dailyRequestTotals
            }
      else if options.days < 182
         # Weekly
         rawRowData = {}
         rowData = {}
         for key, val of selection.datum().dailyRequestTotals
            weekStartDate = DateUtils.getAttachedDate(Number(key))
            weekStartMs = weekStartDate.setDate(weekStartDate.getDate() - weekStartDate.getDay())
            weeksStartingDay = DateUtils.getDetachedDay(new Date(weekStartMs))

            if options.totalsUnits == "people"
               if rawRowData[weeksStartingDay]?
                  for requestId in val.requestIds
                     unless rawRowData[weeksStartingDay].requestIds.indexOf(requestId) != -1
                        rawRowData[weeksStartingDay].requestIds.push(requestId)
               else
                  rawRowData[weeksStartingDay] = {requestIds: val.requestIds}
            else
               if rowData[weeksStartingDay]?
                  rowData[weeksStartingDay] += val
               else
                  rowData[weeksStartingDay] = val

         if options.totalsUnits == "people"
            for key, val of rawRowData
               rowData[key] = val.requestIds.length
      else if options.days >= 182
         # Monthly
         rowData = {}

         if options.totalsUnits == "people"
            rawRowData = {}

            for key, val of selection.datum().dailyRequestTotals
               monthKey = String(key).slice(0, -2)
               monthKey = "#{monthKey}01"
               if rawRowData[monthKey]?
                  for requestId in val.requestIds
                     if rawRowData[monthKey].requestIds.indexOf(requestId) == -1
                        rawRowData[monthKey].requestIds.push(requestId) 
               else
                  rawRowData[monthKey] = {requestIds: val.requestIds}

            for key, val of rawRowData
               rowData[key] = val.requestIds.length
         else
            for key, val of selection.datum().dailyRequestTotals
               monthKey = String(key).slice(0, -2)
               monthKey = "#{monthKey}01"
               if rowData[monthKey]?
                  rowData[monthKey] += val
               else
                  rowData[monthKey] = val
      
      adjustRowTicks(selection, rowData)

   adjustBreakdownTotalTicks = (selection) ->
      if options.days == 1
         # TODO: Update this
         rowData = {}
      else if options.days < 91
         # Daily
         if options.totalsUnits == "people"
            rowData = {}
            for key, val of selection.datum().dailyAssTotals
               rowData[key] = val.peopleIds.length

            for key, val of selection.datum().dailyRequestTotals
               if rowData[key]?
                  rowData[key] += val.requestIds.length
               else
                  rowData[key] = val.requestIds.length
         else
            rowData = {
               ...selection.datum().dailyAssTotals
            }

            for key, val of selection.datum().dailyRequestTotals
               if rowData[key]?
                  rowData[key] += val
               else
                  rowData[key] = val
      else if options.days < 182
         # Weekly
         rawRowData = {}
         rowData = {}
         for key, val of selection.datum().dailyAssTotals
            weekStartDate = DateUtils.getAttachedDate(Number(key))
            weekStartMs = weekStartDate.setDate(weekStartDate.getDate() - weekStartDate.getDay())
            weeksStartingDay = DateUtils.getDetachedDay(new Date(weekStartMs))

            if options.totalsUnits == "people"
               if rawRowData[weeksStartingDay]?
                  for personId in val.peopleIds
                     unless rawRowData[weeksStartingDay].peopleIds.indexOf(personId) != -1
                        rawRowData[weeksStartingDay].peopleIds.push(personId)
               else
                  rawRowData[weeksStartingDay] = {peopleIds: val.peopleIds, requestIds: []}
            else
               if rowData[weeksStartingDay]?
                  rowData[weeksStartingDay] += val
               else
                  rowData[weeksStartingDay] = val

         for key, val of selection.datum().dailyRequestTotals
            weekStartDate = DateUtils.getAttachedDate(Number(key))
            weekStartMs = weekStartDate.setDate(weekStartDate.getDate() - weekStartDate.getDay())
            weeksStartingDay = DateUtils.getDetachedDay(new Date(weekStartMs))

            if options.totalsUnits == "people"
               if rawRowData[weeksStartingDay]?
                  for requestId in val.requestIds
                     unless rawRowData[weeksStartingDay].requestIds.indexOf(requestId) != -1
                        rawRowData[weeksStartingDay].requestIds.push(requestId)
               else
                  rawRowData[weeksStartingDay] = {requestIds: val.requestIds, peopleIds: []}
            else
               if rowData[weeksStartingDay]?
                  rowData[weeksStartingDay] += val
               else
                  rowData[weeksStartingDay] = val

         if options.totalsUnits == "people"
            for key, val of rawRowData
               rowData[key] = (val.peopleIds.length + val.requestIds.length)

      else if options.days >= 182
         # Monthly
         rowData = {}

         if options.totalsUnits == "people"
            rawRowData = {}

            for key, val of selection.datum().dailyAssTotals
               monthKey = String(key).slice(0, -2)
               monthKey = "#{monthKey}01"
               if rawRowData[monthKey]?
                  for personId in val.peopleIds
                     if rawRowData[monthKey].peopleIds.indexOf(personId) == -1
                        rawRowData[monthKey].peopleIds.push(personId) 
               else
                  rawRowData[monthKey] = {peopleIds: val.peopleIds, requestIds: []}

            for key, val of selection.datum().dailyRequestTotals
               monthKey = String(key).slice(0, -2)
               monthKey = "#{monthKey}01"
               if rawRowData[monthKey]?
                  for requestId in val.requestIds
                     if rawRowData[monthKey].requestIds.indexOf(requestId) == -1
                        rawRowData[monthKey].requestIds.push(requestId) 
               else
                  rawRowData[monthKey] = {requestIds: val.requestIds, peopleIds: []}

            for key, val of rawRowData
               rowData[key] = (val.peopleIds.length + val.requestIds.length)
         else
            for key, val of selection.datum().dailyAssTotals
               monthKey = String(key).slice(0, -2)
               monthKey = "#{monthKey}01"
               if rowData[monthKey]?
                  rowData[monthKey] += val
               else
                  rowData[monthKey] = val

            for key, val of selection.datum().dailyRequestTotals
               monthKey = String(key).slice(0, -2)
               monthKey = "#{monthKey}01"
               if rowData[monthKey]?
                  rowData[monthKey] += val
               else
                  rowData[monthKey] = val
      
      adjustRowTicks(selection, rowData)

   adjustBreakdownDeltaTotalTicks = (selection) ->
      if options.days < 91
         # Daily
         rowData = {}
         for key, val of selection.datum().dailyAssTotals
            rowData[key] = val.peopleIds.length

         for key, val of selection.datum().dailyRequestTotals
            if rowData[key]?
               rowData[key] += val.requestIds.length
            else
               rowData[key] = val.requestIds.length
      else if options.days < 182
         # Weekly
         rawRowData = {}
         rowData = {}
         for key, val of selection.datum().dailyAssTotals
            weekStartDate = DateUtils.getAttachedDate(Number(key))
            weekStartMs = weekStartDate.setDate(weekStartDate.getDate() - weekStartDate.getDay())
            weeksStartingDay = DateUtils.getDetachedDay(new Date(weekStartMs))
            if rawRowData[weeksStartingDay]?
               for personId in val.peopleIds
                  unless rawRowData[weeksStartingDay].peopleIds.indexOf(personId) != -1
                     rawRowData[weeksStartingDay].peopleIds.push(personId)
            else
               rawRowData[weeksStartingDay] = {peopleIds: val.peopleIds, requestIds: []}
         for key, val of selection.datum().dailyRequestTotals
            weekStartDate = DateUtils.getAttachedDate(Number(key))
            weekStartMs = weekStartDate.setDate(weekStartDate.getDate() - weekStartDate.getDay())
            weeksStartingDay = DateUtils.getDetachedDay(new Date(weekStartMs))
            if rawRowData[weeksStartingDay]?
               for requestId in val.requestIds
                  unless rawRowData[weeksStartingDay].requestIds.indexOf(requestId) != -1
                     rawRowData[weeksStartingDay].requestIds.push(requestId)
            else
               rawRowData[weeksStartingDay] = {requestIds: val.requestIds, peopleIds: []}
         for key, val of rawRowData
            rowData[key] = (val.peopleIds.length + val.requestIds.length)
      else if options.days >= 182
         # Monthly
         rowData = {}
         rawRowData = {}
         for key, val of selection.datum().dailyAssTotals
            monthKey = String(key).slice(0, -2)
            monthKey = "#{monthKey}01"
            if rawRowData[monthKey]?
               for personId in val.peopleIds
                  if rawRowData[monthKey].peopleIds.indexOf(personId) == -1
                     rawRowData[monthKey].peopleIds.push(personId)
            else
               rawRowData[monthKey] = {peopleIds: val.peopleIds, requestIds: []}
         for key, val of selection.datum().dailyRequestTotals
            monthKey = String(key).slice(0, -2)
            monthKey = "#{monthKey}01"
            if rawRowData[monthKey]?
               for requestId in val.requestIds
                  if rawRowData[monthKey].requestIds.indexOf(requestId) == -1
                     rawRowData[monthKey].requestIds.push(requestId)
            else
               rawRowData[monthKey] = {requestIds: val.requestIds, peopleIds: []}
         for key, val of rawRowData
            rowData[key] = (val.peopleIds.length + val.requestIds.length)

      if 1 < options.days < 91
         minHistoricalKey = todayDetachedDay
      else if 91 <= options.days < 182
         thisWeekStartDate = DateUtils.getAttachedDate(todayDetachedDay)
         thisWeekStartMs = thisWeekStartDate.setDate(thisWeekStartDate.getDate() - thisWeekStartDate.getDay())
         minHistoricalKey = DateUtils.getDetachedDay(new Date(thisWeekStartMs))
      else if 182 <= options.days
         minHistoricalKey = Number(String(todayDetachedDay).slice(0, -2) + "01")

      # Process Deltas
      deltaData = {}
      for key, val of rowData
         if key >= minHistoricalKey
            deltaData[key] = selection.datum().assignable_job_title_people_count - val
      adjustRowTicks(selection, deltaData)

   adjustCategoryBreakdownTicks = (selection, totals) ->
      if options.days == 1
         rowData = {}
      else if options.days < 91
         # Daily
         if options.totalsUnits == "people"
            rowData = {}
            for key, val of totals
               rowData[key] = val.length
         else
            rowData = {
               ...totals
            }
      else if options.days < 182
         rawRowData = {}
         rowData = {}
         if totals
            for key, val of totals
               weekStartDate = DateUtils.getAttachedDate(Number(key))
               weekStartMs = weekStartDate.setDate(weekStartDate.getDate() - weekStartDate.getDay())
               weeksStartingDay = DateUtils.getDetachedDay(new Date(weekStartMs))
               if options.totalsUnits == "people"
                  if rawRowData[weeksStartingDay]?
                     for personId in val
                        unless rawRowData[weeksStartingDay].peopleIds.indexOf(personId) != -1
                           rawRowData[weeksStartingDay].peopleIds.push(personId)
                  else
                     rawRowData[weeksStartingDay] = {peopleIds: val}
               else
                  if rowData[weeksStartingDay]?
                     rowData[weeksStartingDay] += val
                  else
                     rowData[weeksStartingDay] = val

            if options.totalsUnits == "people"
               for key, val of rawRowData
                  rowData[key] = val.peopleIds.length
      else if options.days >= 182
         # Monthly
         rowData = {}
         if totals
            if options.totalsUnits == "people"
               rawRowData = {}
               for key, val of totals
                  monthKey = String(key).slice(0, -2)
                  monthKey = "#{monthKey}01"
                  if rawRowData[monthKey]?
                     for personId in val
                        if rawRowData[monthKey].peopleIds.indexOf(personId) == -1
                           rawRowData[monthKey].peopleIds.push(personId)
                  else
                     rawRowData[monthKey] = {peopleIds: val}
               for key, val of rawRowData
                  rowData[key] = val.peopleIds.length
            else
               for key, val of totals
                  monthKey = String(key).slice(0, -2)
                  monthKey = "#{monthKey}01"
                  if rowData[monthKey]?
                     rowData[monthKey] += val
                  else
                     rowData[monthKey] = val
      adjustRowTicks(selection, rowData)

   adjustCategoryBreakdownTotalTicks = (selection) ->
      if options.days == 1
         rowData = {}
      else if options.days < 91
         # Daily
         if options.totalsUnits == "people"
            rowData = {}
            for key, val of selection.datum().daily_assigned_totals
               rowData[key] = val.length
            for key, val of selection.datum().daily_request_totals
               if rowData[key]?
                  rowData[key] += val.length
               else
                  rowData[key] = val.length
         else
            rowData = {
               ...selection.datum().daily_assigned_totals
            }
            for key, val of selection.datum().daily_request_totals
               if rowData[key]?
                  rowData[key] += val
               else
                  rowData[key] = val
      else if options.days < 182
         # Weekly
         rawRowData = {}
         rowData = {}
         for key, val of selection.datum().daily_assigned_totals
            weekStartDate = DateUtils.getAttachedDate(Number(key))
            weekStartMs = weekStartDate.setDate(weekStartDate.getDate() - weekStartDate.getDay())
            weeksStartingDay = DateUtils.getDetachedDay(new Date(weekStartMs))
            if options.totalsUnits == "people"
               if rawRowData[weeksStartingDay]?
                  for personId in val
                     unless rawRowData[weeksStartingDay].peopleIds.indexOf(personId) != -1
                        rawRowData[weeksStartingDay].peopleIds.push(personId)
               else
                  rawRowData[weeksStartingDay] = {peopleIds: val, requestIds: []}
            else
               if rowData[weeksStartingDay]?
                  rowData[weeksStartingDay] += val
               else
                  rowData[weeksStartingDay] = val
         for key, val of selection.datum().daily_request_totals
            weekStartDate = DateUtils.getAttachedDate(Number(key))
            weekStartMs = weekStartDate.setDate(weekStartDate.getDate() - weekStartDate.getDay())
            weeksStartingDay = DateUtils.getDetachedDay(new Date(weekStartMs))
            if options.totalsUnits == "people"
               if rawRowData[weeksStartingDay]?
                  for requestId in val
                     unless rawRowData[weeksStartingDay].requestIds.indexOf(requestId) != -1
                        rawRowData[weeksStartingDay].requestIds.push(requestId)
               else
                  rawRowData[weeksStartingDay] = {requestIds: val, peopleIds: []}
            else
               if rowData[weeksStartingDay]?
                  rowData[weeksStartingDay] += val
               else
                  rowData[weeksStartingDay] = val
         if options.totalsUnits == "people"
            for key, val of rawRowData
               rowData[key] = (val.peopleIds.length + val.requestIds.length)
      else if options.days >= 182
         # Monthly
         rowData = {}
         if options.totalsUnits == "people"
            rawRowData = {}
            for key, val of selection.datum().daily_assigned_totals
               monthKey = String(key).slice(0, -2)
               monthKey = "#{monthKey}01"
               if rawRowData[monthKey]?
                  for personId in val
                     if rawRowData[monthKey].peopleIds.indexOf(personId) == -1
                        rawRowData[monthKey].peopleIds.push(personId)
               else
                  rawRowData[monthKey] = {peopleIds: val, requestIds: []}
            for key, val of selection.datum().daily_request_totals
               monthKey = String(key).slice(0, -2)
               monthKey = "#{monthKey}01"
               if rawRowData[monthKey]?
                  for requestId in val
                     if rawRowData[monthKey].requestIds.indexOf(requestId) == -1
                        rawRowData[monthKey].requestIds.push(requestId)
               else
                  rawRowData[monthKey] = {requestIds: val, peopleIds: []}
            for key, val of rawRowData
               rowData[key] = (val.peopleIds.length + val.requestIds.length)
         else
            # Monthly
            rowData = {}
            if selection.datum().daily_assigned_totals
               for key, val of selection.datum().daily_assigned_totals
                  monthKey = String(key).slice(0, -2)
                  monthKey = "#{monthKey}01"
                  if rowData[monthKey]?
                     rowData[monthKey] += val
                  else
                     rowData[monthKey] = val
            if selection.datum().daily_request_totals
               for key, val of selection.datum().daily_request_totals
                  monthKey = String(key).slice(0, -2)
                  monthKey = "#{monthKey}01"
                  if rowData[monthKey]?
                     rowData[monthKey] += val
                  else
                     rowData[monthKey] = val

      adjustRowTicks(selection, rowData)

   if options.days == 1
      secondaryTicks = secondaryXScale.ticks(d3.timeDay.every(1))
   else if options.days >= 182
      secondaryTicks = secondaryXScale.ticks(d3.timeYear.every(1))
   else
      secondaryTicks = secondaryXScale.ticks(d3.timeMonth.every(1))

   secondaryXAxis = d3.axisBottom(xScale)
   .tickFormat(secondaryTickFormat)
   .tickSize(19.6)

   secondaryXAxis.tickValues(secondaryTicks)

   unless pagingDraw
      xAxisBar = d3.select("#totals-rollup__x-axis").append("svg").attr("class", "x-axis-wrapper")
         .attr("width", frameWidth)
         .attr("height", 40)

      xAxisBar.append("g").attr("class", "x-axis-group")
         .attr("transform", "translate(#{infoSecWidth},20)")
         .style('font', -> if options.days == 1 and frameWidth < 1200 then "8px OpenSans" else "10px OpenSans")
         .call(xAxis)
         .call(adjustTextLabels)

      xAxisBar.append("g").attr("class", "x-axis-group--secondary")
         .attr("transform", "translate(#{infoSecWidth},-1)")
         .style('font', "11px OpenSans")
         .call(secondaryXAxis)
         .call(adjustSecondaryTextLabels)

      breakdownXAxisBar = d3.select("#totals-breakdown__x-axis").append("svg")
         .attr("class", "x-axis-wrapper")
         .attr("width", frameWidth)
         .attr("height", 40)

      breakdownXAxisBar.append("g").attr("class", "x-axis-group")
         .attr("transform", "translate(#{infoSecWidth},20)")
         .style('font', -> if options.days == 1 and frameWidth < 1200 then "8px OpenSans" else "10px OpenSans")
         .call(xAxis)
         .call(adjustTextLabels)

      breakdownXAxisBar.append("g").attr("class", "x-axis-group--secondary")
         .attr("transform", "translate(#{infoSecWidth},-1)")
         .style('font', "11px OpenSans")
         .call(secondaryXAxis)
         .call(adjustSecondaryTextLabels)

      # Rollup Chart
      rollupChart = d3.select("#totals-rollup-chart-wrapper").append("svg").attr("id", "totals-rollup-chart")
         .attr("transform", "translate(0,0)")
         .attr('width', frameWidth)
         .attr('height', rollupChartHeight)

      # Rollup - Primary Row
      rollupChart.append("g")
         .attr("class", "totals-primary-row totals-primary-row--rollup")
         .attr("transform", "translate(0,0)")
         .attr("width", frameWidth)
         .attr("height", projectTitleRowHeight)

      # Rollup -  Assigned Row
      assignedRollupRow = rollupChart.append("g")
         .attr("class", "totals-detail-row totals-detail-row--rollup-assigned")
         .attr("transform", "translate(0,0)")
         .attr("width", frameWidth)
         .attr("height", detailRowHeight)

      assignedRollupRow.append("text")
         .text () ->
            if options.totalsUnits == "people"
               allPeopleIds = []
               for key, val of totalsData.rollupData.dailyAssTotals
                  for personId in val.peopleIds
                     if allPeopleIds.indexOf(personId) == -1
                        allPeopleIds.push(personId)

               return "Assigned - People"
            else
               totalValue = 0
               for key, val of totalsData.rollupData.dailyAssTotals
                  totalValue += val

               totalValue = roundAndFormatValue(totalValue)
               if options.totalsUnits == "hours"
                  return  "Assigned - Hours (#{totalValue})"
               else if options.totalsUnits == "man-days"
                  return  "Assigned - Man Days (#{totalValue})"
               else if options.totalsUnits == "cost"
                  return  "Assigned - Cost ($#{totalValue})"

         .attr("class", "totals-detail-row__title")
         .style('font', "12px OpenSans")
         .attr("fill", "white")
         .attr("x", 30)
         .attr("y", detailRowHeight - 6)

      assignedRollupRow.insert("rect", ":first-child")
         .attr("class", "totals-detail-row__bg--assignment-rollup")
         .attr("height", detailRowHeight)
         .attr("width", "100%")
         .attr("fill", "gray")

      assignedRollupRow.append("g")
         .attr("class", "totals-detail-row__ticks")
         .attr("transform", "translate(#{infoSecWidth},-1)")
         .style('font', "11px OpenSans")
         .call(xAxis)
         .call(adjustRollupAssignedTicks)
         .call(colorWeekends)

      if showTimeOff
          # Rollup - Time Off Row
         timeOffRollupRow = rollupChart.append("g")
            .attr("class", "totals-detail-row totals-detail-row--rollup-time-off")
            .attr("transform", "translate(0,#{detailRowHeight})")
            .attr("width", frameWidth)
            .attr("height", detailRowHeight)

         timeOffRollupRow.append("text")
            .text ->
               if options.totalsUnits == "people"
                  allTimeOffIds = []
                  for key, val of totalsData.rollupData.dailyTimeOffTotals
                     for timeOffId in val.timeOffIds
                        if allTimeOffIds.indexOf(timeOffId) == -1
                           allTimeOffIds.push(timeOffId)
                  return "Time Off - People"
               else
                  totalValue = 0
                  for key, val of totalsData.rollupData.dailyTimeOffTotals
                     totalValue += val
                  totalValue = roundAndFormatValue(totalValue)
                  if options.totalsUnits == "hours"
                     return  "Time Off - Hours (#{totalValue})"
                  else if options.totalsUnits == "man-days"
                     return  "Time Off - Man Days (#{totalValue})"
                  else if options.totalsUnits == "cost"
                     return  "Time Off - Cost ($#{totalValue})"

            .attr("class", "totals-detail-row__title")
            .style('font', "12px OpenSans")
            .attr("fill", "white")
            .attr("x", 30)
            .attr("y", detailRowHeight - 6)

         timeOffRollupRow.insert("rect", ":first-child")
            .attr("class", "totals-detail-row__bg--time-off-rollup")
            .attr("height", detailRowHeight)
            .attr("width", "100%")
            .attr("fill", "#949494")

         timeOffRollupRow.append("rect")
            .attr("class", "totals-detail-row__under-line")
            .attr("rx", 1)
            .attr("ry", 1)
            .attr("width", "100%")
            .attr("height", 1)
            .attr("fill", "white")
            .attr("x", infoSecWidth)
            .attr("y", detailRowHeight - 1)

         timeOffRollupRow.append("g")
            .attr("class", "totals-detail-row__ticks")
            .attr("transform", "translate(#{infoSecWidth},-1)")
            .style('font', "11px OpenSans")
            .call(xAxis)
            .call(adjustRollupTimeOffTicks)
            .call(colorWeekends)

      if options.permissions.canViewRequests
         # Rollup -  Requested Row
         requestRowHeight = if showTimeOff then detailRowHeight * 2 else detailRowHeight * 1
         requestedRollupRow = rollupChart.append("g")
            .attr("class", "totals-detail-row totals-detail-row--rollup-requested")
            .attr("transform", "translate(0,#{requestRowHeight})")
            .attr("width", frameWidth)
            .attr("height", detailRowHeight)

         requestedRollupRow.append("text")
            # TODO: Update this to show tht unites
            .text ->
               if options.totalsUnits == "people"
                  allRequestIds = []
                  for key, val of totalsData.rollupData.dailyRequestTotals
                     for requestId in val.requestIds
                        if allRequestIds.indexOf(requestId) == -1
                           allRequestIds.push(requestId)

                  return "Requested - People"
               else
                  totalValue = 0
                  for key, val of totalsData.rollupData.dailyRequestTotals
                     totalValue += val

                  totalValue = roundAndFormatValue(totalValue)
                  if options.totalsUnits == "hours"
                     return  "Requested - Hours (#{totalValue})"
                  else if options.totalsUnits == "man-days"
                     return  "Requested - Man Days (#{totalValue})"
                  else if options.totalsUnits == "cost"
                     return  "Requested - Cost ($#{totalValue})"

            .attr("class", "totals-detail-row__title")
            .style('font', "12px OpenSans")
            .attr("fill", "white")
            .attr("x", 30)
            .attr("y", detailRowHeight - 6)

         requestedRollupRow.insert("rect", ":first-child")
            .attr("class", "totals-detail-row__bg--request-rollup")
            .attr("height", detailRowHeight)
            .attr("width", "100%")
            .attr("fill", "blue")

         requestedRollupRow.append("rect")
            .attr("class", "totals-detail-row__under-line")
            .attr("rx", 1)
            .attr("ry", 1)
            .attr("width", "100%")
            .attr("height", 1)
            .attr("fill", "white")
            .attr("x", infoSecWidth)
            .attr("y", detailRowHeight - 1)

         requestedRollupRow.append("g")
            .attr("class", "totals-detail-row__ticks")
            .attr("transform", "translate(#{infoSecWidth},-1)")
            .style('font', "11px OpenSans")
            .call(xAxis)
            .call(adjustRollupRequestedTicks)
            .call(colorWeekends)

         # Rollup - Total Row
         totalRowHeight = detailRowHeight
         totalRowHeight += detailRowHeight if options.permissions.canViewRequests
         totalRowHeight += detailRowHeight if showTimeOff
         totalRollupRow = rollupChart.append("g")
            .attr("class", "totals-detail-row totals-detail-row--rollup-total")
            .attr("transform", "translate(0,#{totalRowHeight})")
            .attr("width", frameWidth)
            .attr("height", detailRowHeight)

         totalRollupRow.append("text")
            .text ->
               if options.totalsUnits == "people"
                  allPeopleIds = []
                  for key, val of totalsData.rollupData.dailyAssTotals
                     for personId in val.peopleIds
                        if allPeopleIds.indexOf(personId) == -1
                           allPeopleIds.push(personId)

                  allRequestIds = []
                  for key, val of totalsData.rollupData.dailyRequestTotals
                     for requestId in val.requestIds
                        if allRequestIds.indexOf(requestId) == -1
                           allRequestIds.push(requestId)

                  return  "Total - People"
               else
                  totalValue = 0
                  for key, val of totalsData.rollupData.dailyAssTotals
                     totalValue += val

                  for key, val of totalsData.rollupData.dailyRequestTotals
                     totalValue += val

                  totalValue = roundAndFormatValue(totalValue)
                  if options.totalsUnits == "hours"
                     return  "Total - Hours (#{totalValue})"
                  else if options.totalsUnits == "man-days"
                     return  "Total - Man Days (#{totalValue})"
                  else if options.totalsUnits == "cost"
                     return  "Total - Cost ($#{totalValue})"
            .attr("class", "totals-detail-row__title")
            .style('font', "12px OpenSans")
            .attr("fill", "white")
            .attr("x", 30)
            .attr("y", detailRowHeight - 6)

         totalRollupRow.insert("rect", ":first-child")
            .attr("class", "totals-detail-row__bg--total-rollup")
            .attr("height", detailRowHeight)
            .attr("width", "100%")
            .attr("fill", "blue")

         totalRollupRow.append("rect")
            .attr("class", "totals-detail-row__under-line")
            .attr("rx", 1)
            .attr("ry", 1)
            .attr("width", "100%")
            .attr("height", 1)
            .attr("fill", "white")
            .attr("x", infoSecWidth)
            .attr("y", detailRowHeight - 1)

         totalRollupRow.append("g")
            .attr("class", "totals-detail-row__ticks")
            .attr("transform", "translate(#{infoSecWidth},-1)")
            .style('font', "11px OpenSans")
            .call(xAxis)
            .call(adjustRollupTotalTicks)
            .call(colorWeekends)

      if options.totalsUnits == "people" and options.permissions.canViewRequests
         # Rollup - Delta Row
         deltaHeight = detailRowHeight * 2
         deltaHeight += detailRowHeight if options.permissions.canViewRequests
         deltaHeight += detailRowHeight if showTimeOff
         deltaRollupRow = rollupChart.append("g")
            .attr("class", "totals-detail-row totals-detail-row--rollup-delta")
            .attr("transform", "translate(0,#{deltaHeight})")
            .attr("width", frameWidth)
            .attr("height", detailRowHeight)

         deltaRollupRow.append("text")
            .text("Delta - People")
            .attr("class", "totals-detail-row__title")
            .style('font', "12px OpenSans")
            .attr("fill", "white")
            .attr("x", 30)
            .attr("y", detailRowHeight - 6)

         deltaRollupRow.append("rect")
            .attr("class", "totals-detail-row__under-line")
            .attr("rx", 1)
            .attr("ry", 1)
            .attr("width", "100%")
            .attr("height", 1)
            .attr("fill", "white")
            .attr("x", infoSecWidth)
            .attr("y", detailRowHeight - 1)

         deltaRollupRow.append("g")
            .attr("class", "totals-detail-row__ticks")
            .attr("transform", "translate(#{infoSecWidth},-1)")
            .style('font', "11px OpenSans")
            .call(xAxis)
            .call(adjustRollupDeltaTicks)
            .call(colorDeltas)

   svg.append("g").attr("class", "grid").attr("transform", "translate(#{infoSecWidth},0)")
     .call(gridlines)
     .call(colorWeekends)

   chart = svg.append("g").attr("class", "totals-scrolling-container")
      .attr("transform", "translate(0,0)")

   breakdownChart = chart.append("g").attr("id", "totals-breakdown-chart")
      .attr("transform", "translate(0,0)")

   breakdownSet = breakdownChart.selectAll("totals-breakdown-set")
      .data(totalsData.breakdownData.slice(options.sliceStart, (options.sliceStart + options.pageDrawLimit)))
      .enter().append("svg")
      .attr("class", "totals-breakdown-set")
      .attr("width", frameWidth)
      .attr("height", (d) ->
         if options.viewBy == "job-title"
            # Accounting for the project size when view by job title is selected and categories/subcategories are inaccessible
            projectRowHeight = (projectTitleRowHeight + detailRowHeight + primaryRowPadding)
            # Account for the requests row and total row
            projectRowHeight += detailRowHeight * 2 if options.permissions.canViewRequests
            # Account for time off row 
            projectRowHeight += detailRowHeight if showTimeOff
            # Account for the delta row when unit is people
            projectRowHeight += detailRowHeight if options.permissions.canViewRequests and options.totalsUnits == "people"
         else if options.viewBy == "project"
            # Account for the Project Title Row and Assigned Resources Row height of all categories under this project 
            projectRowHeight = d.containedCategories * (projectTitleRowHeight + detailRowHeight)
            
            # Account for one padding worth of space to represent height of separating all categories
            projectRowHeight += if d.containedCategories > 0 then primaryRowPadding else 0

            # Account for the Project Title Row and Assigned Resources Row height of all subcategories under this project
            projectRowHeight += d.containedSubcategories * (projectTitleRowHeight + detailRowHeight)
            # Account for one padding worth of space to represent height of separating all subcategories
            projectRowHeight += if d.containedSubcategories > 0 then primaryRowPadding else 0

            # Account for the need of some padding at the end of a project, when no categories or subcategories are present.
            # If projectRowHeight == 0 check accounts for no containedCategories or containedSubcategories implies this project is alone
            projectRowHeight += if projectRowHeight == 0 then primaryRowPadding else 0

            # A Requested Resource Row and Total Row always go together so containedRequestTotalRows represents that grouping
            # Each of those rows has a height of detailRowHeight that we need to account for after we have summed them.
            projectRowHeight += d.containedRequestTotalRows * detailRowHeight
            # When time off is visible add height
            if showTimeOff
               projectRowHeight += detailRowHeight
               projectRowHeight += d.containedCategories * detailRowHeight
            # When Requested Resources aren't visible we still need some padding to properly distinguish Projects/Categories/SubCategories 
            projectRowHeight += if !options.permissions.canViewRequests then primaryRowPadding else 0
            # Account for the base height of a Project Title Row and a single Assigned Resources Row
            projectRowHeight += (projectTitleRowHeight + detailRowHeight)
         return projectRowHeight
      )
      .attr("x", 0)
      .attr("y", (d, i) ->
         if options.viewBy == "job-title"
            # When viewing by job-title, y offset can simply occur by relative position using i since Categories and SubCategories don't factor in
            yPadding = (i * ( detailRowHeight + projectTitleRowHeight + primaryRowPadding ))
            # Account for the requests row and total row
            yPadding += (i * (detailRowHeight * 2)) if options.permissions.canViewRequests
            # Account for time off row 
            yPadding += (i * detailRowHeight) if showTimeOff
            # Account for the delta row when unit is people
            yPadding += (i * detailRowHeight) if options.permissions.canViewRequests and options.totalsUnits == "people"
         else if options.viewBy == "project"
            yPadding = d.leadingCategories * (projectTitleRowHeight + detailRowHeight)
            yPadding += d.leadingSubcategories * (projectTitleRowHeight + detailRowHeight)
            yPadding += d.leadingRequestTotalRows * detailRowHeight
            # Account for the height of a Project Title Row and Assigned Resources Row and the necessary padding each project has gotten 
            yPadding += d.leadingProjects * (projectTitleRowHeight + detailRowHeight + primaryRowPadding)
            yPadding += if !options.permissions.canViewRequests then primaryRowPadding else 0
            if showTimeOff
               yPadding += d.leadingProjects * detailRowHeight
               yPadding += d.leadingCategories * detailRowHeight
               yPadding += d.leadingSubcategories * detailRowHeight
         return yPadding
      )

   breakdownSetPrimaryRow = breakdownSet.append("g").attr("class", "totals-primary-row")
      .attr("transform", "translate(0,#{primaryRowPadding})")
      .attr("width", frameWidth)
      .attr("height", projectTitleRowHeight)

   breakdownSetPrimaryRow.append("circle")
      .filter (d) -> return d.color?
      .attr("class", "totals__primary-title-bar-color")
      .attr("fill", (d) -> return d.color)
      .attr("width", (d) -> return if d.color then primaryColorCircleDiameter else 0)
      .attr("height", (d) -> return if d.color then primaryColorCircleDiameter else 0)
      .attr("cx", 15)
      .attr("cy", (projectTitleRowHeight / 2) + 2)
      .attr("r", 5)

   breakdownSetPrimaryRow.append("text").text (d) ->
         if d.name?
            if options.viewBy == "project" and options.showJobNumbers and d.job_number?
               return "[ #{d.job_number} ] #{d.name.toUpperCase()}"
            else
               if options.totalsUnits == "people" and d.assignable_job_title_people_count?
                  return  "#{d.name.toUpperCase()} (Active Resources: #{d.assignable_job_title_people_count})"
               return d.name.toUpperCase()
         else
            if options.viewBy == "job-title"
               if options.totalsUnits == "people" and d.assignable_job_title_people_count?
                  return  "Not Specified (Active Resources: #{d.assignable_job_title_people_count})"
               return "Not Specified"
            else
               return ''
      .attr("class", "totals-primary-row__title")
      .style('font', "14px OpenSans")
      .attr("x", (d) -> if d.color? then 25 else 10)
      .attr("y", projectTitleRowHeight - 8)

   breakdownSetPrimaryRow.insert("rect", ":first-child").attr("class", "totals-primary-row__bg")
      .attr("height", projectTitleRowHeight)
      .attr "width", (d) ->
         parent = d3.select(@.parentNode)
         children  = parent.select(".totals-primary-row__title")
         textWidth = children.node().getComputedTextLength()
         # Account for color cirlce.
         if d.color?
            textWidth += 15
         return textWidth + 20
      .attr("fill", "white")

   breakdownSetPrimaryRow.append("rect").attr("class", "totals-primary-row__under-line")
      .attr("rx", 1)
      .attr("ry", 1)
      .attr("width", "100%")
      .attr("height", 1)
      .attr("fill", "black")
      .attr("x", infoSecWidth)
      .attr("y", projectTitleRowHeight - 1)

   # Breakdown - Assigned Row
   breakdownSetAssignedRow = breakdownSet.append("g")
      .attr("class", "totals-detail-row totals-detail-row--breakdown-assigned")
      .attr("transform", "translate(0,#{primaryRowPadding + projectTitleRowHeight})")
      .attr("width", frameWidth)
      .attr("height", detailRowHeight)

   breakdownSetAssignedRow.append("rect")
      .attr("class", "totals-detail-row__bg")
      .attr("rx", 1)
      .attr("ry", 1)
      .attr("width", "100%")
      .attr("height", detailRowHeight)
      .attr("fill", "white")
      .attr("opacity", 0)
      .attr("x", 0)
      .attr("y", 0)

   breakdownSetAssignedRow.append("text")
      .text (d) ->
         if options.totalsUnits == "people"
            allPeopleIds = []
            for key, val of d.dailyAssTotals
               for personId in val.peopleIds
                  if allPeopleIds.indexOf(personId) == -1
                     allPeopleIds.push(personId)

            return "Assigned - People"
         else
            totalValue = 0
            for key, val of d.dailyAssTotals
               totalValue += val

            totalValue = roundAndFormatValue(totalValue)

            if options.totalsUnits == "hours"
               return  "Assigned - Hours (#{totalValue})"
            else if options.totalsUnits == "man-days"
               return  "Assigned - Man Days (#{totalValue})"
            else if options.totalsUnits == "cost"
               return  "Assigned - Cost ($#{totalValue})"

      .attr("class", "totals-detail-row__title")
      .style('font', "12px OpenSans")
      .attr("x", 30)
      .attr("y", detailRowHeight - 6)

   breakdownSetAssignedRow.append("rect")
      .attr("class", "totals-detail-row__under-line")
      .attr("rx", 1)
      .attr("ry", 1)
      .attr("width", "100%")
      .attr("height", 1)
      .attr("fill", "black")
      .attr("x", 20)
      .attr("y", detailRowHeight - 1)

   d3.selectAll('.totals-detail-row--breakdown-assigned').each () ->
      d3.select(@).append("g")
         .attr("class", "totals-detail-row__ticks")
         .attr("transform", "translate(#{infoSecWidth},-1)")
         .style('font', "11px OpenSans")
         .call(xAxis)
         .call(adjustBreakdownAssignedTicks)
   
   if showTimeOff
      # Breakdown - Time Off Row
      breakdownSetTimeOffRow = breakdownSet.append("g").attr("class", "totals-detail-row totals-detail-row--breakdown-time-off")
         .attr("transform", "translate(0,#{primaryRowPadding + projectTitleRowHeight + detailRowHeight})")
         .attr("width", frameWidth)
         .attr("height", detailRowHeight)

      breakdownSetTimeOffRow.append("rect").attr("class", "totals-detail-row__bg")
         .attr("rx", 1)
         .attr("ry", 1)
         .attr("width", "100%")
         .attr("height", detailRowHeight)
         .attr("fill", "white")
         .attr("opacity", 0)
         .attr("x", 0)
         .attr("y", 0)

      breakdownSetTimeOffRow.append("text").text (d) ->
            if options.totalsUnits == "people"
               allTimeOffIds = []
               for key, val of d.dailyTimeOffTotals
                  for timeOffId in val.timeOffIds
                     if allTimeOffIds.indexOf(timeOffId) == -1
                        allTimeOffIds.push(timeOffId)
               return "Time Off - People"
            else
               totalValue = 0
               for key, val of d.dailyTimeOffTotals
                  totalValue += val

               totalValue = roundAndFormatValue(totalValue)

               if options.totalsUnits == "hours"
                  return  "Time Off - Hours (#{totalValue})"
               else if options.totalsUnits == "man-days"
                  return  "Time Off - Man Days (#{totalValue})"
               else if options.totalsUnits == "cost"
                  return  "Time Off - Cost ($#{totalValue})"

         .attr("class", "totals-detail-row__title")
         .style('font', "12px OpenSans")
         .attr("x", 30)
         .attr("y", detailRowHeight - 6)

      breakdownSetTimeOffRow.append("rect").attr("class", "totals-detail-row__under-line")
         .attr("rx", 1)
         .attr("ry", 1)
         .attr("width", "100%")
         .attr("height", 1)
         .attr("fill", "black")
         .attr("x", 20)
         .attr("y", detailRowHeight - 1)

      d3.selectAll('.totals-detail-row--breakdown-time-off').each () ->
         d3.select(@).append("g")
            .attr("class", "totals-detail-row__ticks")
            .attr("transform", "translate(#{infoSecWidth},-1)")
            .style('font', "11px OpenSans")
            .call(xAxis)
            .call(adjustBreakdownTimeOffTicks)

   if options.permissions.canViewRequests
      # Breakdown - Requested Row
      breakdownRequestOffset = if showTimeOff then detailRowHeight * 2 else detailRowHeight
      breakdownSetRequestedRow = breakdownSet.append("g").attr("class", "totals-detail-row totals-detail-row--breakdown-requested")
         .attr("transform", "translate(0,#{primaryRowPadding + projectTitleRowHeight + breakdownRequestOffset})")
         .attr("width", frameWidth)
         .attr("height", detailRowHeight)

      breakdownSetRequestedRow.append("rect").attr("class", "totals-detail-row__bg")
         .attr("rx", 1)
         .attr("ry", 1)
         .attr("width", "100%")
         .attr("height", detailRowHeight)
         .attr("fill", "white")
         .attr("opacity", 0)
         .attr("x", 0)
         .attr("y", 0)

      breakdownSetRequestedRow.append("text").text (d) ->
            if options.totalsUnits == "people"
               allRequestIds = []
               for key, val of d.dailyRequestTotals
                  for requestId in val.requestIds
                     if allRequestIds.indexOf(requestId) == -1
                        allRequestIds.push(requestId)

               return "Requested - People"
            else
               totalValue = 0
               for key, val of d.dailyRequestTotals
                  totalValue += val

               totalValue = roundAndFormatValue(totalValue)

               if options.totalsUnits == "hours"
                  return  "Requested - Hours (#{totalValue})"
               else if options.totalsUnits == "man-days"
                  return  "Requested - Man Days (#{totalValue})"
               else if options.totalsUnits == "cost"
                  return  "Requested - Cost ($#{totalValue})"

         .attr("class", "totals-detail-row__title")
         .style('font', "12px OpenSans")
         .attr("x", 30)
         .attr("y", detailRowHeight - 6)

      breakdownSetRequestedRow.append("rect").attr("class", "totals-detail-row__under-line")
         .attr("rx", 1)
         .attr("ry", 1)
         .attr("width", "100%")
         .attr("height", 1)
         .attr("fill", "black")
         .attr("x", 20)
         .attr("y", detailRowHeight - 1)

      d3.selectAll('.totals-detail-row--breakdown-requested').each () ->
         d3.select(@).append("g")
            .attr("class", "totals-detail-row__ticks")
            .attr("transform", "translate(#{infoSecWidth},-1)")
            .style('font', "11px OpenSans")
            .call(xAxis)
            .call(adjustBreakdownRequestedTicks)

      # Breakdown - Total Row
      breakdownTotalOffset = if showTimeOff then detailRowHeight * 3 else detailRowHeight * 2
      breakdownSetTotalRow = breakdownSet.append("g").attr("class", "totals-detail-row totals-detail-row--breakdown-total")
         .attr("transform", "translate(0,#{primaryRowPadding + projectTitleRowHeight + breakdownTotalOffset})")
         .attr("width", frameWidth)
         .attr("height", detailRowHeight)

      breakdownSetTotalRow.append("rect").attr("class", "totals-detail-row__bg")
         .attr("rx", 1)
         .attr("ry", 1)
         .attr("width", "100%")
         .attr("height", detailRowHeight)
         .attr("fill", "white")
         .attr("opacity", 0)
         .attr("x", 0)
         .attr("y", 0)

      breakdownSetTotalRow.append("text").text (d) ->
            if options.totalsUnits == "people"
               allPeopleIds = []
               for key, val of d.dailyAssTotals
                  for personId in val.peopleIds
                     if allPeopleIds.indexOf(personId) == -1
                        allPeopleIds.push(personId)

               allRequestIds = []
               for key, val of d.dailyRequestTotals
                  for requestId in val.requestIds
                     if allRequestIds.indexOf(requestId) == -1
                        allRequestIds.push(requestId)

               return "Total - People"
            else
               totalValue = 0
               for key, val of d.dailyAssTotals
                  totalValue += val

               for key, val of d.dailyRequestTotals
                  totalValue += val

               totalValue = roundAndFormatValue(totalValue)

               if options.totalsUnits == "hours"
                  return  "Total - Hours (#{totalValue})"
               else if options.totalsUnits == "man-days"
                  return  "Total - Man Days (#{totalValue})"
               else if options.totalsUnits == "cost"
                  return  "Total - Cost ($#{totalValue})"

         .attr("class", "totals-detail-row__title")
         .style('font', "12px OpenSans")
         .attr("x", 30)
         .attr("y", detailRowHeight - 6)

      breakdownSetTotalRow.append("rect").attr("class", "totals-detail-row__under-line")
         .attr("rx", 1)
         .attr("ry", 1)
         .attr("width", "100%")
         .attr("height", 1)
         .attr("fill", "black")
         .attr("x", 20)
         .attr("y", detailRowHeight - 1)

      d3.selectAll('.totals-detail-row--breakdown-total').each () ->
         d3.select(@).append("g").attr("class", "totals-detail-row__ticks")
            .attr("transform", "translate(#{infoSecWidth},-1)")
            .style('font', "11px OpenSans")
            .call(xAxis)
            .call(adjustBreakdownTotalTicks)

      if options.viewBy == "job-title" and options.totalsUnits == "people"
         # Breakdown - Delta Row
         breakdownDeltaOffset = if showTimeOff then detailRowHeight * 4 else detailRowHeight * 3
         breakdownSetDeltaRow = breakdownSet.append("g")
            .attr("class", "totals-detail-row totals-detail-row--breakdown-delta")
            .attr("transform", "translate(0,#{primaryRowPadding + projectTitleRowHeight + breakdownDeltaOffset})")
            .attr("width", frameWidth)
            .attr("height", detailRowHeight)

         breakdownSetDeltaRow.append("rect")
            .attr("class", "totals-detail-row__bg")
            .attr("rx", 1)
            .attr("ry", 1)
            .attr("width", "100%")
            .attr("height", detailRowHeight)
            .attr("fill", "white")
            .attr("opacity", 0)
            .attr("x", 0)
            .attr("y", 0)

         breakdownSetDeltaRow.append("text")
            .text("Delta - People")
            .attr("class", "totals-detail-row__title")
            .style('font', "12px OpenSans")
            .attr("x", 30)
            .attr("y", detailRowHeight - 6)

         breakdownSetDeltaRow.append("rect")
            .attr("class", "totals-detail-row__under-line")
            .attr("rx", 1)
            .attr("ry", 1)
            .attr("width", "100%")
            .attr("height", 1)
            .attr("fill", "black")
            .attr("x", 20)
            .attr("y", detailRowHeight - 1)

         d3.selectAll('.totals-detail-row--breakdown-delta').each () ->
            d3.select(@).append("g")
               .attr("class", "totals-detail-row__ticks")
               .attr("transform", "translate(#{infoSecWidth},-1)")
               .style('font', "11px OpenSans")
               .call(xAxis)
               .call(adjustBreakdownDeltaTotalTicks)

   if options.viewBy == "project" and options.showCategories
      breakdownCategoryChart = breakdownSet.append("g")
         .attr("id", "totals-category-breakdown-chart")
         .attr("transform", "translate(0,0)")

      # Breakdown for each category
      breakdownCategorySet = breakdownCategoryChart.selectAll("totals-category-breakdown-set")
         .data( (data) -> data.dailyCategories)
         .enter().append("svg")
         .attr("class", "totals-category-breakdown-set")
         .attr("width", frameWidth)
         .attr("height", (d) ->
            # Account for the Project Title Row and Assigned Resources Row height of all SubCategories under this project 
            projectRowHeight = d.containedSubCategories * (projectTitleRowHeight + detailRowHeight)
            # Account for one padding worth of space to represent height of separating all SubCategories
            projectRowHeight += if d.containedSubCategories > 0 then primaryRowPadding else 0
            # A Requested Resource Row and Total Row always go together so containedRequestTotalRows represents that grouping
            # Each of those rows has a height of detailRowHeight that we need to account for after we have summed them.
            projectRowHeight += d.containedRequestTotalRows * detailRowHeight
            projectRowHeight += if d.containedRequestTotalRows > 0 then primaryRowPadding else 0
            # When Requested Resources aren't visible we still need some padding to properly distinguish Categories/SubCategories 
            projectRowHeight += if !options.permissions.canViewRequests then primaryRowPadding else 0
            projectRowHeight += if !showTimeOff then primaryRowPadding else 0
            projectRowHeight += (projectTitleRowHeight + detailRowHeight)
            projectRowHeight += detailRowHeight * 2 if showTimeOff
            return projectRowHeight
         )
         .attr("x", 0)
         .attr("y", (d) ->
            yPadding = d.leadingCategories * (projectTitleRowHeight + detailRowHeight)
            yPadding += if d.leadingSubCategories > 0 then primaryRowPadding else 0
            yPadding += d.leadingSubCategories * (projectTitleRowHeight + detailRowHeight)
            yPadding += if d.previousCategoryHasNoSubCategories then primaryRowPadding else 0
            yPadding += if yPadding == 0 then initialCategoryPadding else 0
            yPadding += if d.leadingRequestTotalRows > 0 then primaryRowPadding else 0
            yPadding += d.leadingRequestTotalRows * detailRowHeight
            yPadding += detailRowHeight if showTimeOff
            yPadding += if !options.permissions.canViewRequests then primaryRowPadding else 0
            yPadding += projectTitleRowHeight + detailRowHeight
            return yPadding
         )

      brkdwnCategorySetTitleRow = breakdownCategorySet.append("g").attr("class", "totals-primary-row")
         .attr("transform", "translate(0,0)")
         .attr("width", frameWidth)
         .attr("height", projectTitleRowHeight)

      brkdwnCategorySetTitleRow.append("text")
         .text (d) -> (d.name or "").toUpperCase()
         .attr("class", "totals-primary-row__title")
         .style('font', "600 12px OpenSans")
         .attr("x", 30)
         .attr("y", detailRowHeight - 6)

      brkdwnCategorySetTitleRow.insert("rect", ":first-child").attr("class", "totals-primary-row__bg")
         .attr("rx", 1)
         .attr("ry", 1)
         .attr("width", "100%")
         .attr("height", detailRowHeight)
         .attr("fill", "white")
         .attr("opacity", 0)
         .attr("x", 0)
         .attr("y", 0)

      brkdwnCategorySetTitleRow.append("rect").attr("class", "totals-primary-row__under-line")
         .attr("rx", 1)
         .attr("ry", 1)
         .attr("width", "100%")
         .attr("height", 1)
         .attr("fill", "black")
         .attr("x", infoSecWidth)
         .attr("y", detailRowHeight - 1)

      # Category Breakdown - Assigned Row
      brkdwnCategorySetAssignedRow = breakdownCategorySet.append("g")
         .attr("class", "totals-detail-row totals-detail-row--brkdwn-category-assigned")
         .attr("transform", "translate(0,#{detailRowHeight})")
         .attr("width", frameWidth)
         .attr("height", detailRowHeight)

      brkdwnCategorySetAssignedRow.insert("rect", ":first-child")
         .attr("class", "totals-detail-row__bg")
         .attr("rx", 1)
         .attr("ry", 1)
         .attr("width", "100%")
         .attr("height", detailRowHeight)
         .attr("fill", "white")
         .attr("opacity", 0)
         .attr("x", 0)
         .attr("y", 0)

      brkdwnCategorySetAssignedRow.append("text").text (d) ->
            if options.totalsUnits == "people"
               return "Assigned - People"
            else
               totalValue = 0
               for key, val of d.daily_assigned_totals
                  totalValue += val
         
               totalValue = roundAndFormatValue(totalValue)
         
               if options.totalsUnits == "hours"
                  return  "Assigned - Hours (#{totalValue})"
               else if options.totalsUnits == "man-days"
                  return  "Assigned - Man Days (#{totalValue})"
               else if options.totalsUnits == "cost"
                  return  "Assigned - Cost ($#{totalValue})"
         .attr("class", "totals-detail-row__title")
         .style('font', "12px OpenSans")
         .attr("x", 30)
         .attr("y", detailRowHeight - 6)

      brkdwnCategorySetAssignedRow.append("rect")
         .attr("class", "totals-detail-row__under-line")
         .attr("rx", 1)
         .attr("ry", 1)
         .attr("width", "100%")
         .attr("height", 1)
         .attr("fill", "black")
         .attr("x", 20)
         .attr("y", detailRowHeight - 1)

      d3.selectAll('.totals-detail-row--brkdwn-category-assigned').each () ->
         d3.select(@).append("g")
            .attr("class", "totals-detail-row__ticks")
            .attr("transform", "translate(#{infoSecWidth},-1)")
            .style('font', "11px OpenSans")
            .call(xAxis)
            .call((selection) -> adjustCategoryBreakdownTicks(selection, selection.datum().daily_assigned_totals))
      
      if showTimeOff
         # Category Breakdown - Time Off Row
         brkdwnCategorySetTimeOffRow = breakdownCategorySet.append("g").attr("class", "totals-detail-row totals-detail-row--brkdwn-category-time-off")
            .attr("transform", "translate(0,#{(detailRowHeight * 2)})")
            .attr("width", frameWidth)
            .attr("height", detailRowHeight)

         brkdwnCategorySetTimeOffRow.insert("rect", ":first-child").attr("class", "totals-detail-row__bg")
            .attr("rx", 1)
            .attr("ry", 1)
            .attr("width", "100%")
            .attr("height", detailRowHeight)
            .attr("fill", "white")
            .attr("opacity", 0)
            .attr("x", 0)
            .attr("y", 0)

         brkdwnCategorySetTimeOffRow.append("text").text (d) ->
               if options.totalsUnits == "people"
                  return "Time Off - People"
               else
                  totalValue = 0
                  for key, val of d.daily_time_off_totals
                     totalValue += val

                  totalValue = roundAndFormatValue(totalValue)

                  if options.totalsUnits == "hours"
                     return  "Time Off - Hours (#{totalValue})"
                  else if options.totalsUnits == "man-days"
                     return  "Time Off - Man Days (#{totalValue})"
                  else if options.totalsUnits == "cost"
                     return  "Time Off - Cost ($#{totalValue})"
            .attr("class", "totals-detail-row__title")
            .style('font', "12px OpenSans")
            .attr("x", 30)
            .attr("y", detailRowHeight - 6)

         brkdwnCategorySetTimeOffRow.append("rect").attr("class", "totals-detail-row__under-line")
            .attr("rx", 1)
            .attr("ry", 1)
            .attr("width", "100%")
            .attr("height", 1)
            .attr("fill", "black")
            .attr("x", 20)
            .attr("y", detailRowHeight - 1)

         d3.selectAll('.totals-detail-row--brkdwn-category-requested').each () ->
            d3.select(@).append("g")
               .attr("class", "totals-detail-row__ticks")
               .attr("transform", "translate(#{infoSecWidth},-1)")
               .style('font', "11px OpenSans")
               .call(xAxis)
               .call((selection) -> adjustCategoryBreakdownTicks(selection, selection.datum().daily_time_off_totals))


      if options.permissions.canViewRequests
         # Category Breakdown - Requested Row
         catReqHeight = if showTimeOff then detailRowHeight * 3 else detailRowHeight * 2
         brkdwnCategorySetRequestedRow = breakdownCategorySet.append("g").attr("class", "totals-detail-row totals-detail-row--brkdwn-category-requested")
            .attr("transform", "translate(0,#{catReqHeight})")
            .attr("width", frameWidth)
            .attr("height", detailRowHeight)

         brkdwnCategorySetRequestedRow.insert("rect", ":first-child").attr("class", "totals-detail-row__bg")
            .attr("rx", 1)
            .attr("ry", 1)
            .attr("width", "100%")
            .attr("height", detailRowHeight)
            .attr("fill", "white")
            .attr("opacity", 0)
            .attr("x", 0)
            .attr("y", 0)

         brkdwnCategorySetRequestedRow.append("text").text (d) ->
               if options.totalsUnits == "people"
                  return "Requested - People"
               else
                  totalValue = 0
                  for key, val of d.daily_request_totals
                     totalValue += val

                  totalValue = roundAndFormatValue(totalValue)

                  if options.totalsUnits == "hours"
                     return  "Requested - Hours (#{totalValue})"
                  else if options.totalsUnits == "man-days"
                     return  "Requested - Man Days (#{totalValue})"
                  else if options.totalsUnits == "cost"
                     return  "Requested - Cost ($#{totalValue})"
            .attr("class", "totals-detail-row__title")
            .style('font', "12px OpenSans")
            .attr("x", 30)
            .attr("y", detailRowHeight - 6)

         brkdwnCategorySetRequestedRow.append("rect").attr("class", "totals-detail-row__under-line")
            .attr("rx", 1)
            .attr("ry", 1)
            .attr("width", "100%")
            .attr("height", 1)
            .attr("fill", "black")
            .attr("x", 20)
            .attr("y", detailRowHeight - 1)

         d3.selectAll('.totals-detail-row--brkdwn-category-requested').each () ->
            d3.select(@).append("g")
               .attr("class", "totals-detail-row__ticks")
               .attr("transform", "translate(#{infoSecWidth},-1)")
               .style('font', "11px OpenSans")
               .call(xAxis)
               .call((selection) -> adjustCategoryBreakdownTicks(selection, selection.datum().daily_request_totals))

         # Category Breakdown - Total Row
         catTotalHeight = if showTimeOff then detailRowHeight * 4 else detailRowHeight * 3
         brkdwnCategorySetTotalRow = breakdownCategorySet.append("g").attr("class", "totals-detail-row totals-detail-row--brkdwn-category-total")
            .attr("transform", "translate(0,#{catTotalHeight})")
            .attr("width", frameWidth)
            .attr("height", detailRowHeight)

         brkdwnCategorySetTotalRow.insert("rect", ":first-child").attr("class", "totals-detail-row__bg")
            .attr("rx", 1)
            .attr("ry", 1)
            .attr("width", "100%")
            .attr("height", detailRowHeight)
            .attr("fill", "white")
            .attr("opacity", 0)
            .attr("x", 0)
            .attr("y", 0)

         brkdwnCategorySetTotalRow.append("text").text (d) ->
               if options.totalsUnits == "people"
                  return "Total - People"
               else
                  totalValue = 0
                  for key, val of d.daily_assigned_totals
                     totalValue += val

                  for key, val of d.daily_request_totals
                     totalValue += val

                  totalValue = roundAndFormatValue(totalValue)

                  if options.totalsUnits == "hours"
                     return  "Total - Hours (#{totalValue})"
                  else if options.totalsUnits == "man-days"
                     return  "Total - Man Days (#{totalValue})"
                  else if options.totalsUnits == "cost"
                     return  "Total - Cost ($#{totalValue})"
            .attr("class", "totals-detail-row__title")
            .style('font', "12px OpenSans")
            .attr("x", 30)
            .attr("y", detailRowHeight - 6)

         brkdwnCategorySetTotalRow.append("rect").attr("class", "totals-detail-row__under-line")
            .attr("rx", 1)
            .attr("ry", 1)
            .attr("width", "100%")
            .attr("height", 1)
            .attr("fill", "black")
            .attr("x", 20)
            .attr("y", detailRowHeight - 1)

         d3.selectAll('.totals-detail-row--brkdwn-category-total').each () ->
            d3.select(@).append("g").attr("class", "totals-detail-row__ticks")
               .attr("transform", "translate(#{infoSecWidth},-1)")
               .style('font', "11px OpenSans")
               .call(xAxis)
               .call(adjustCategoryBreakdownTotalTicks)

      if options.showSubCategories
         breakdownSubCategoryChart = breakdownCategorySet.append("g")
            .attr("id", "totals-subcategory-breakdown-chart")
            .attr("transform", "translate(0,0)")

         # SubCategory Breakdown
         breakdownSubcategorySet = breakdownSubCategoryChart.selectAll("totals-subcategory-breakdown-set")
            .data( (data) -> data.subcategories)
            .enter().append("svg")
            .attr("class", "totals-subcategory-breakdown-set")
            .attr("width", frameWidth)
            .attr("height", (d) ->
               subcategorySectionHeight = projectTitleRowHeight + detailRowHeight
               subcategorySectionHeight += d.containedRequestTotalRows * detailRowHeight
               subcategorySectionHeight += detailRowHeight if showTimeOff
               return subcategorySectionHeight
            )
            .attr("x", 0)
            .attr("y", (d) ->
               yPadding = projectTitleRowHeight + detailRowHeight
               yPadding += d.leadingSubCategories * (projectTitleRowHeight + detailRowHeight)
               yPadding += if options.permissions.canViewRequests then d.leadingSubCategories * (detailRowHeight * 2) else 0
               yPadding += if d.leadingSubCategories == 0 then d.leadingRequestTotalRows * detailRowHeight else 0
               yPadding += if d.leadingSubCategories > 0 and options.permissions.canViewRequests then (detailRowHeight * 2) else 0
               yPadding += detailRowHeight if showTimeOff
               return yPadding
            )

         brkdwnSubcategorySetTitleRow = breakdownSubcategorySet.append("g").attr("class", "totals-primary-row")
            .attr("transform", "translate(0,0)")
            .attr("width", frameWidth)
            .attr("height", projectTitleRowHeight)

         brkdwnSubcategorySetTitleRow.append("text")
            .text (d) -> (d.name or "")
            .attr("class", "totals-primary-row__title")
            .style('font', "12px OpenSans")
            .attr("x", 30)
            .attr("y", detailRowHeight - 6)

         brkdwnSubcategorySetTitleRow.insert("rect", ":first-child").attr("class", "totals-primary-row__bg")
            .attr("rx", 1)
            .attr("ry", 1)
            .attr("width", "100%")
            .attr("height", detailRowHeight)
            .attr("fill", "white")
            .attr("opacity", 0)
            .attr("x", 0)
            .attr("y", 0)

         brkdwnSubcategorySetTitleRow.append("rect").attr("class", "totals-primary-row__under-line")
            .attr("rx", 1)
            .attr("ry", 1)
            .attr("width", "100%")
            .attr("height", 1)
            .attr("fill", "black")
            .attr("x", infoSecWidth)
            .attr("y", detailRowHeight - 1)

         # SubCategory Breakdown - Assigned Row
         brkdwnSubcategorySetAssignedRow = breakdownSubcategorySet.append("g")
            .attr("class", "totals-detail-row totals-detail-row--brkdwn-subcategory-assigned")
            .attr("transform", "translate(0,#{detailRowHeight})")
            .attr("width", frameWidth)
            .attr("height", detailRowHeight)

         brkdwnSubcategorySetAssignedRow.append("text").text (d) ->
               if options.totalsUnits == "people"
                  return "Assigned - People"
               else
                  totalValue = 0
                  for key, val of d.daily_assigned_totals
                     totalValue += val
                  totalValue = roundAndFormatValue(totalValue)
                  if options.totalsUnits == "hours"
                     return  "Assigned - Hours (#{totalValue})"
                  else if options.totalsUnits == "man-days"
                     return  "Assigned - Man Days (#{totalValue})"
                  else if options.totalsUnits == "cost"
                     return  "Assigned - Cost ($#{totalValue})"
            .attr("class", "totals-detail-row__title")
            .style('font', "12px OpenSans")
            .attr("x", 30)
            .attr("y", detailRowHeight - 6)

         brkdwnSubcategorySetAssignedRow.insert("rect", ":first-child").attr("class", "totals-detail-row__bg")
            .attr("rx", 1)
            .attr("ry", 1)
            .attr("width", "100%")
            .attr("height", detailRowHeight)
            .attr("fill", "white")
            .attr("opacity", 0)
            .attr("x", 0)
            .attr("y", 0)

         brkdwnSubcategorySetAssignedRow.append("rect")
            .attr("class", "totals-detail-row__under-line")
            .attr("rx", 1)
            .attr("ry", 1)
            .attr("width", "100%")
            .attr("height", 1)
            .attr("fill", "black")
            .attr("x", 20)
            .attr("y", detailRowHeight - 1)

         d3.selectAll('.totals-detail-row--brkdwn-subcategory-assigned').each () ->
            d3.select(@).append("g")
               .attr("class", "totals-detail-row__ticks")
               .attr("transform", "translate(#{infoSecWidth},-1)")
               .style('font', "11px OpenSans")
               .call(xAxis)
               .call((selection) -> adjustCategoryBreakdownTicks(selection, selection.datum().daily_assigned_totals))

         if showTimeOff            
            # SubCategory Breakdown - Time Off Row
            brkdwnSubcategorySetRequestedRow = breakdownSubcategorySet.append("g").attr("class", "totals-detail-row totals-detail-row--brkdwn-subcategory-time-off")
               .attr("transform", "translate(0,#{(detailRowHeight * 2)})")
               .attr("width", frameWidth)
               .attr("height", detailRowHeight)

            brkdwnSubcategorySetRequestedRow.append("rect").attr("class", "totals-detail-row__bg")
               .attr("rx", 1)
               .attr("ry", 1)
               .attr("width", "100%")
               .attr("height", detailRowHeight)
               .attr("fill", "white")
               .attr("opacity", 0)
               .attr("x", 0)
               .attr("y", 0)

            brkdwnSubcategorySetRequestedRow.append("text").text (d) ->
                  if options.totalsUnits == "people"
                     return "Time Off- People"
                  else
                     totalValue = 0
                     for key, val of d.daily_request_totals
                        totalValue += val

                     totalValue = roundAndFormatValue(totalValue)

                     if options.totalsUnits == "hours"
                        return  "Time Off- Hours (#{totalValue})"
                     else if options.totalsUnits == "man-days"
                        return  "Time Off- Man Days (#{totalValue})"
                     else if options.totalsUnits == "cost"
                        return  "Time Off- Cost ($#{totalValue})"

               .attr("class", "totals-detail-row__title")
               .style('font', "12px OpenSans")
               .attr("x", 30)
               .attr("y", detailRowHeight - 6)

            brkdwnSubcategorySetRequestedRow.append("rect").attr("class", "totals-detail-row__under-line")
               .attr("rx", 1)
               .attr("ry", 1)
               .attr("width", "100%")
               .attr("height", 1)
               .attr("fill", "black")
               .attr("x", 20)
               .attr("y", detailRowHeight - 1)

            d3.selectAll('.totals-detail-row--brkdwn-subcategory-time-off').each () ->
               d3.select(@).append("g")
                  .attr("class", "totals-detail-row__ticks")
                  .attr("transform", "translate(#{infoSecWidth},-1)")
                  .style('font', "11px OpenSans")
                  .call(xAxis)
                  .call((selection) -> adjustCategoryBreakdownTicks(selection, selection.datum().daily_time_off_totals))

         if options.permissions.canViewRequests
            # SubCategory Breakdown - Requested Row
            requestY = if showTimeOff then detailRowHeight * 3 else detailRowHeight * 2
            brkdwnSubcategorySetRequestedRow = breakdownSubcategorySet.append("g").attr("class", "totals-detail-row totals-detail-row--brkdwn-subcategory-requested")
               .attr("transform", "translate(0,#{requestY})")
               .attr("width", frameWidth)
               .attr("height", detailRowHeight)

            brkdwnSubcategorySetRequestedRow.append("rect").attr("class", "totals-detail-row__bg")
               .attr("rx", 1)
               .attr("ry", 1)
               .attr("width", "100%")
               .attr("height", detailRowHeight)
               .attr("fill", "white")
               .attr("opacity", 0)
               .attr("x", 0)
               .attr("y", 0)

            brkdwnSubcategorySetRequestedRow.append("text").text (d) ->
                  if options.totalsUnits == "people"
                     return "Requested - People"
                  else
                     totalValue = 0
                     for key, val of d.daily_request_totals
                        totalValue += val

                     totalValue = roundAndFormatValue(totalValue)

                     if options.totalsUnits == "hours"
                        return  "Requested - Hours (#{totalValue})"
                     else if options.totalsUnits == "man-days"
                        return  "Requested - Man Days (#{totalValue})"
                     else if options.totalsUnits == "cost"
                        return  "Requested - Cost ($#{totalValue})"

               .attr("class", "totals-detail-row__title")
               .style('font', "12px OpenSans")
               .attr("x", 30)
               .attr("y", detailRowHeight - 6)

            brkdwnSubcategorySetRequestedRow.append("rect").attr("class", "totals-detail-row__under-line")
               .attr("rx", 1)
               .attr("ry", 1)
               .attr("width", "100%")
               .attr("height", 1)
               .attr("fill", "black")
               .attr("x", 20)
               .attr("y", detailRowHeight - 1)

            d3.selectAll('.totals-detail-row--brkdwn-subcategory-requested').each () ->
               d3.select(@).append("g")
                  .attr("class", "totals-detail-row__ticks")
                  .attr("transform", "translate(#{infoSecWidth},-1)")
                  .style('font', "11px OpenSans")
                  .call(xAxis)
                  .call((selection) -> adjustCategoryBreakdownTicks(selection, selection.datum().daily_request_totals))

            # SubCategory Breakdown - Total Row
            totalY = if showTimeOff then detailRowHeight * 4 else detailRowHeight * 3
            brkdwnSubcategorySetTotalRow = breakdownSubcategorySet.append("g").attr("class", "totals-detail-row totals-detail-row--brkdwn-subcategory-total")
               .attr("transform", "translate(0,#{totalY})")
               .attr("width", frameWidth)
               .attr("height", detailRowHeight)

            brkdwnSubcategorySetTotalRow.append("rect").attr("class", "totals-detail-row__bg")
               .attr("rx", 1)
               .attr("ry", 1)
               .attr("width", "100%")
               .attr("height", detailRowHeight)
               .attr("fill", "white")
               .attr("opacity", 0)
               .attr("x", 0)
               .attr("y", 0)

            brkdwnSubcategorySetTotalRow.append("text").text (d) ->
                  if options.totalsUnits == "people"
                     return "Total - People"
                  else
                     totalValue = 0
                     for key, val of d.daily_assigned_totals
                        totalValue += val

                     for key, val of d.daily_request_totals
                        totalValue += val

                     totalValue = roundAndFormatValue(totalValue)

                     if options.totalsUnits == "hours"
                        return  "Total - Hours (#{totalValue})"
                     else if options.totalsUnits == "man-days"
                        return  "Total - Man Days (#{totalValue})"
                     else if options.totalsUnits == "cost"
                        return  "Total - Cost ($#{totalValue})"

               .attr("class", "totals-detail-row__title")
               .style('font', "12px OpenSans")
               .attr("x", 30)
               .attr("y", detailRowHeight - 6)

            brkdwnSubcategorySetTotalRow.append("rect").attr("class", "totals-detail-row__under-line")
               .attr("rx", 1)
               .attr("ry", 1)
               .attr("width", "100%")
               .attr("height", 1)
               .attr("fill", "black")
               .attr("x", 20)
               .attr("y", detailRowHeight - 1)

            d3.selectAll('.totals-detail-row--brkdwn-subcategory-total').each () ->
               d3.select(@).append("g").attr("class", "totals-detail-row__ticks")
                  .attr("transform", "translate(#{infoSecWidth},-1)")
                  .style('font', "11px OpenSans")
                  .call(xAxis)
                  .call(adjustCategoryBreakdownTotalTicks)

   # Update heights after draw.
   chartHeight = d3.select(".totals-scrolling-container").node().getBBox().height + 46
   svg.attr("height", chartHeight) if chartHeight > frameHeight
   grid = d3.select(".grid")

   grid.selectAll('#totals-wrapper .tick line')
      .attr("y2", if chartHeight < frameHeight then frameHeight else chartHeight)

   grid.selectAll('#totals-wrapper .tick .weekend-bg')
      .attr("height", if chartHeight < frameHeight then frameHeight else chartHeight)

   grid.selectAll('#totals-wrapper .tick .today-bg')
      .attr("height", if chartHeight < frameHeight then frameHeight else chartHeight)


ko.bindingHandlers["totalsChart"] =
   init: (element, valueAccessor) ->
      accessor = ko.unwrap(valueAccessor())      
      data = accessor.data()
      options = accessor.options
      
      drawChart(element, data, options) if data?

      redraw = (newData, newOptions) =>
         data = newData
         options = newOptions
         drawChart(element, data, options)

      partialRedraw = (newData, newOptions) =>
         data = newData
         options = newOptions
         drawChart(element, data, options, true)

      resizeTimeout = null
      resizeHandler = () =>
         # This causes it to only fire after resizing stops.
         clearTimeout(resizeTimeout)
         resizeTimeout = setTimeout () ->
            # To make sure it's still showing.
            redraw(data, options)
         , 250

      $(window).on("resize", resizeHandler)
      ko.utils.domNodeDisposal.addDisposeCallback element, () ->
         $(window).off("resize", resizeHandler)

      accessor.mediator.initialize({redraw: redraw, partialRedraw: partialRedraw})
