ko.bindingHandlers["overflowCount"] =
   init: (element, valueAccessor) ->
      overflowCount = valueAccessor().count
      threshold = ko.unwrap(valueAccessor().threshold)
      checkChildren = () =>
         count = 0
         for child in $(element).children()
            count++ if $(child).position().top >= threshold

         overflowCount(count)

      $(window).on("resize", checkChildren)
      ko.utils.domNodeDisposal.addDisposeCallback element, () ->
         $(window).off("resize", checkChildren)

   update: (element, valueAccessor) ->
      trigger = ko.unwrap(valueAccessor().trigger)
      overflowCount = valueAccessor().count
      threshold = ko.unwrap(valueAccessor().threshold)
      containerMorphs = ko.unwrap(valueAccessor().containerMorphs) or false
      # Give changes time to render.
      setTimeout ->
         # If its a height morphoing container we don't we want to check children against threshold always.
         if !containerMorphs and element.offsetHeight >= element.scrollHeight
            count = 0
         else
            count = 0
            for child in $(element).children()
               count++ if $(child).position().top >= threshold

         overflowCount(count)