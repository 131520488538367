import { findBoundsWithinViewport } from "@/lib/utils/elements";
import type { Bounds } from "@/lib/utils/geometry";
import { isEqualBounds } from "@/lib/utils/geometry";
import type { Observable, Subscribable, Subscription } from "knockout";
import ko from "knockout";

export type BoundsWithinViewportParams = {
   /** viewport gets updated to match the element's viewport. */
   viewportBounds: Observable<Bounds>;

   /** visibleContentBounds gets updated to match the element's visibleContentBounds. */
   visibleContentBounds: Observable<Bounds>;

   /** contentBounds gets updated to match the element's contentBounds. */
   contentBounds?: Observable<Bounds>;

   /** topViewportElement gets updated to match the element's topViewportElement. */
   topViewportElement?: Observable<Element>;

   /** leftViewportElement gets updated to match the element's leftViewportElement. */
   leftViewportElement?: Observable<Element>;

   /** When the trigger is updated, re-evaluate. */
   trigger?: Subscribable | null;
};

ko.bindingHandlers.boundsWithinViewport = {
   init: (element: HTMLElement, valueAccessor) => {
      const params = valueAccessor() as BoundsWithinViewportParams;

      const updateBoundsWithinViewport = () => {
         const result = findBoundsWithinViewport(element);
         if (!isEqualBounds(result.viewportBounds, params.viewportBounds.peek())) {
            if (result.viewportBounds.top != 0 || params.viewportBounds.peek().top == 0) {
               params.viewportBounds(result.viewportBounds);
            }
         }
         if (!isEqualBounds(result.visibleContentBounds, params.visibleContentBounds.peek())) {
            params.visibleContentBounds(result.visibleContentBounds);
         }
         if (
            params.contentBounds &&
            !isEqualBounds(result.contentBounds, params.contentBounds.peek())
         ) {
            params.contentBounds(result.contentBounds);
         }
         if (
            params.topViewportElement &&
            result.topViewportElement != params.topViewportElement.peek()
         ) {
            params.topViewportElement(result.topViewportElement);
         }
         if (
            params.leftViewportElement &&
            result.leftViewportElement != params.leftViewportElement.peek()
         ) {
            params.leftViewportElement(result.leftViewportElement);
         }
         return result;
      };

      // Subscribe to the trigger if it's visible.
      let subscription: Subscription | null = null;
      if (params.trigger) {
         subscription = params.trigger.subscribe(() => {
            updateBoundsWithinViewport();
            setTimeout(updateBoundsWithinViewport, 0);
         });
      }

      window.addEventListener("resize", updateBoundsWithinViewport);
      ko.utils.domNodeDisposal.addDisposeCallback(element, () => {
         window.removeEventListener("resize", updateBoundsWithinViewport);
         if (subscription) subscription.dispose();
      });

      // Trigger the initial update.
      const result = updateBoundsWithinViewport();
      setTimeout(updateBoundsWithinViewport, 0);

      // Add listeners for when the elements scroll.
      result.topViewportElement.addEventListener("scroll", updateBoundsWithinViewport);
      if (result.topViewportElement != result.leftViewportElement) {
         result.leftViewportElement.addEventListener("scroll", updateBoundsWithinViewport);
      }

      ko.utils.domNodeDisposal.addDisposeCallback(element, () => {
         result.topViewportElement.removeEventListener("scroll", updateBoundsWithinViewport);
         result.leftViewportElement.removeEventListener("scroll", updateBoundsWithinViewport);
      });
   },
};
