import { DateUtils } from "@/lib/utils/date"
import { Format as FormatUtils } from "@/lib/utils/format"
import { defaultStore } from "@/stores/default-store"

import $ from "jquery"
import * as d3 from "d3"
import * as ko from "knockout"

LONG_FORM_OPTIONS = {
   monthFormat: DateUtils.MonthFormat.ABBREV
   dayFormat: DateUtils.DayFormat.ONE_DIGIT
   yearFormat: DateUtils.YearFormat.FULL
   weekdayFormat: DateUtils.WeekDayFormat.NONE
}

# The graphColumns order dictates stacking in graph.
drawGraph = (element, graphData, totalsData, graphColumns, graphColors, graphOptions) ->
   # If there is only 1 data point, provide more empty ones to complete area.
   if graphData.length == 1
      workingPoint = graphData[0]
      fillerPoint = {}
      # Not incrementing by month when data points are by month still as 
      # I thought it created a confusing visual representation.
      fillerPoint['date'] = DateUtils.incrementDate(workingPoint.date, 7)
      
      for key, val of workingPoint
         continue if key == "date"
         if val.entityIds?
            fillerPoint[key] = {color: val.color, entityIds: []}
         else
            fillerPoint[key] = {color: val.color, count: 0}

      graphData.push(fillerPoint)

   # Get chart area width
   container = document.getElementsByClassName("manpower-graph__graph-container")[0]
   return console.log "NO CONTAINER FOUND" unless container?
   containerWidth = container.offsetWidth
   
   # This calculation is fine as long as we are passing in zero'd data so that we have a 
   # data point for every x-axis tick. 
   dataWidth = graphData.length * 70

   xDomain = d3.extent(graphData, (d) -> d.date)

   domainDayDelta = DateUtils.daysBetweenDates(xDomain[0], xDomain[1])

   if graphOptions.viewTimeline == "compressed"
      frameWidth = containerWidth
   else
      frameWidth = if containerWidth > dataWidth then containerWidth else dataWidth

   frameHeight = container.offsetHeight

   stack = d3.stack().keys(graphColumns).value((d, key) ->
      if d[key]? 
         if d[key].entityIds?
            return d[key].entityIds.length
         else
            return d[key].count
      else
         return 0
   )
   
   series = stack(graphData)

   bottomMargin = if graphOptions.viewTimeline == "compressed" then 30 else 90
   margin = ({top: 20, right: 55, bottom: bottomMargin, left: 100})

   xScale = d3.scaleUtc()
      .domain(xDomain)
      .range([margin.left, frameWidth - margin.right])

   yScale = d3.scaleLinear()
      .domain([0, d3.max(series, (d) -> d3.max(d, (d) -> d[1]))]).nice()
      .range([frameHeight - margin.bottom, margin.top])

   yAxis = (g) ->
      g.attr("transform", 'translate(50,0)')
      .call(d3.axisLeft(yScale).tickSize(-frameWidth))
      .call((g) => g.select(".domain").remove())
      .call((g) -> 
         g.select(".tick:last-of-type text").clone()
            .attr("x", 3)
            .attr("text-anchor", "start")
            .attr("font-weight", "bold")
      )

   daysToPixels = (days) ->
      d1 = new Date(xDomain[0])
      return xScale(d3.timeDay.offset(d1, days)) - xScale(d1)

   if graphOptions.dataPointsBy == "weeks"
      ticks = xScale.ticks(d3.timeWeek.every(1))
      tickFormat = (date) -> DateUtils.formatDate(date, defaultStore.getDateFormat(), LONG_FORM_OPTIONS)
   else
      ticks = xScale.ticks(d3.timeMonth.every(1))
      tickFormat = d3.timeFormat("%b, %Y")

   area = d3.area()
      .x((d) -> xScale(d.data.date))
      .y0((d) -> yScale(d[0]))
      .y1((d) -> yScale(d[1]))

   svg = d3.select(element)
      .attr("width", frameWidth)
      .attr("height", frameHeight)
      .attr "viewBox", ->
         if graphOptions.viewTimeline == "compressed"
            return [0, 0, frameWidth, frameHeight]
         else
            return ""

      .append("svg")
      .attr("class", "manpower-graph__graph")
      .attr("height", frameHeight)
      .attr "viewBox", ->
         if graphOptions.viewTimeline == "compressed"
            return [0, 0, frameWidth, frameHeight]
         else
            return ""

   svg.append("g")
      .attr("class", "manpower-graph__grid-y")
      .call(yAxis);

   d3.select(".manpower-graph__fixed-y-axis-container")
      .append("g")
      .attr("class", "manpower-graph__fixed-grid-y")
      .attr("transform", 'translate(50,0)')
      .call(d3.axisLeft(yScale).tickSize(0))
      .call((g) => g.select(".domain").remove())
      .call((g) -> 
         g.select(".tick:last-of-type text").clone()
            .attr("x", 3)
            .attr("text-anchor", "start")
            .attr("font-weight", "bold")
      )

   # Set element mask to full graph size.
   $("#manpower-graph__mask-stripe__shape").width(frameWidth).height(frameHeight)

   svg.append("g")
      .selectAll("path")
      .data(series)
      .enter().append('path')
         .attr "fill", (d) ->
            # TODO: This is disgusting. Try to break D3s example and do somethign else.
            key = d['key']
            id = key.split("--")[1]
            if id? and graphColors[id]?
               return graphColors[id]
            else
               console.log "retuning no color for key: ", key
               return ''

         .attr "mask", (d) ->
            key = d['key']
            if key.indexOf("requests") != -1
               return "url(#manpower-graph__mask-stripe)"
            else
               return ''

         .attr("d", area)
         .attr "class", (d) ->
            key = d['key']
            id = key.split("--")[1]
            return id

   adjustAssignmentTextLabels = (selection) ->
      selection.selectAll('.tick').each (data) ->
         day = DateUtils.getDetachedDay(data)
         totalsValue = totalsData.assignments[day]
         if totalsValue == 0 or !totalsValue?
            totalsValue = '' 
         else
            totalsValue = Number(totalsValue)
            if Math.round(totalsValue) != totalsValue
               totalsValue = totalsValue.toFixed(2)
            totalsValue = FormatUtils.formatNumber(totalsValue)

         tickFont = "11px OpenSans"
         d3.select(@).selectAll('text')
            .attr("transform", "translate(0,3)")
            .text(totalsValue)
            .style('font', tickFont)

   adjustRequestTextLabels = (selection) ->
      selection.selectAll('.tick').each (data) ->
         day = DateUtils.getDetachedDay(data)
         totalsValue = totalsData.requests[day]
         if totalsValue == 0 or !totalsValue?
            totalsValue = '' 
         else
            totalsValue = Number(totalsValue)
            if Math.round(totalsValue) != totalsValue
               totalsValue = totalsValue.toFixed(2)
            totalsValue = FormatUtils.formatNumber(totalsValue)

         tickFont = "11px OpenSans"
         d3.select(@).selectAll('text')
            .attr("transform", "translate(0,3)")
            .text(totalsValue)
            .style('font', tickFont)

   adjustTotalTextLabels = (selection) ->
      selection.selectAll('.tick').each (data) ->
         day = DateUtils.getDetachedDay(data)
         totalsValue = totalsData.totals[day]
         if totalsValue == 0 or !totalsValue?
            totalsValue = '' 
         else
            totalsValue = Number(totalsValue)
            if Math.round(totalsValue) != totalsValue
               totalsValue = totalsValue.toFixed(2)
            totalsValue = FormatUtils.formatNumber(totalsValue)

         tickFont = "11px OpenSans"
         d3.select(@).selectAll('text')
            .attr("transform", "translate(0,3)")
            .text(totalsValue)
            .style('font', tickFont)


   dateBar = svg.append("g")
      .attr("transform", "translate(0,#{frameHeight - bottomMargin})")

   if graphOptions.viewTimeline == "compressed"
      if domainDayDelta <= 28
         ticks = xScale.ticks(d3.timeWeek.every(1))
         # This format takes up 53px on the date axis per tick.
         tickFormat = (date) -> DateUtils.formatDate(date, defaultStore.getDateFormat(), LONG_FORM_OPTIONS)
         dateBar.call(d3.axisBottom(xScale).tickValues(ticks).tickFormat(tickFormat))
      else if 28 < domainDayDelta <= 240
         # Can support around 8 months at this format with a 500px framewidth.
         ticks = xScale.ticks(d3.timeWeek.every(1))
         # This format takes up 53px on the date axis per tick. Round to 63 for buffer.
         tickFormat = (date) -> DateUtils.formatDate(date, defaultStore.getDateFormat(), LONG_FORM_OPTIONS)
         dateBar.call(d3.axisBottom(xScale).tickValues(ticks).tickFormat((d) ->
            # Finds the first full week for a monday where sunday is in that month.
            if d.getDate() < 14
               workingDate = new Date(d.getTime())
               workingDate.setDate(1)
               firstDay = workingDate.getDay()
               if firstDay == 0
                  if d.getDate() <= 7
                     return tickFormat(d)
                  else
                     return ""
               else
                  # Find the first sunday. 
                  firstSundayDate = 1 + (7 - firstDay)
                  firstWeekSaturdayDate = firstSundayDate + 6
                  if firstSundayDate <= d.getDate() <= firstWeekSaturdayDate
                     return tickFormat(d)
                  else
                     return ""
            else
               # Can't be the first week of the month
               return ""
         ))
      else if 240 < domainDayDelta <= 730
         ticks = xScale.ticks(d3.timeMonth.every(1))
         # This format takes up 46px on the date axis per tick. Round to 56 for buffer.
         tickFormat = d3.timeFormat("%b, %Y")
         tickCount = ticks.length
         maxDateLabels = Math.floor(frameWidth / 56)
         everyInt = Math.ceil(tickCount / maxDateLabels)
         if tickCount % maxDateLabels == 0 or (tickCount % maxDateLabels) / maxDateLabels > 0.75
            everyInt += 1

         dateBar.call(d3.axisBottom(xScale).tickValues(ticks).tickFormat((d, i) ->
            # Finds the first full week for a monday where sunday is in that month.
            if i % everyInt == 0
               return tickFormat(d)
            else
               return ""
         ))
      else if 730 < domainDayDelta <= 1460
         ticks = xScale.ticks(d3.timeMonth.every(1))
         # This format takes up 46px on the date axis per tick. Round to 56 for buffer.
         tickFormat = d3.timeFormat("%b, %Y")
         tickCount = ticks.length
         maxDateLabels = Math.floor(frameWidth / 56)
         everyInt = Math.ceil(tickCount / maxDateLabels)
         if tickCount % maxDateLabels == 0 or (tickCount % maxDateLabels) / maxDateLabels > 0.75
            everyInt += 1

         dateBar.call(d3.axisBottom(xScale).tickValues(ticks).tickFormat((d, i) ->
            # Finds the first full week for a monday where sunday is in that month.
            if i % everyInt == 0
               return tickFormat(d)
            else
               return ""
         ))
      else
         ticks = xScale.ticks(d3.timeMonth.every(1))
         # This format takes up 46px on the date axis per tick. Round to 56 for buffer.
         tickFormat = d3.timeFormat("%b, %Y")
         tickCount = ticks.length
         maxDateLabels = Math.floor(frameWidth / 56)
         everyInt = Math.ceil(tickCount / maxDateLabels)
         if tickCount % maxDateLabels == 0 or (tickCount % maxDateLabels) / maxDateLabels > 0.75
            everyInt += 1

         dateBar.call(d3.axisBottom(xScale).tickValues(ticks).tickFormat((d, i) ->
            # Finds the first full week for a monday where sunday is in that month.
            if i % everyInt == 0
               return tickFormat(d)
            else
               return ""
         ))
   else
      dateBar.call(d3.axisBottom(xScale).tickValues(ticks).tickFormat(tickFormat))

   if graphOptions.viewTimeline == "expanded"
      svg.append("g")
         .attr("class", "manpower-graph__assignment-totals")
         .attr("transform", "translate(0,#{frameHeight - 60})")
         .call(d3.axisBottom(xScale).tickValues(ticks).tickFormat(tickFormat).tickSize(0))
         .call(adjustAssignmentTextLabels)

      svg.append("g")
         .attr("class", "manpower-graph__request-totals")
         .attr("transform", "translate(0,#{frameHeight - 40})")
         .call(d3.axisBottom(xScale).tickValues(ticks).tickFormat(tickFormat).tickSize(0))
         .call(adjustRequestTextLabels)

      svg.append("g")
         .attr("class", "manpower-graph__total-totals")
         .attr("transform", "translate(0,#{frameHeight - 20})")
         .call(d3.axisBottom(xScale).tickValues(ticks).tickFormat(tickFormat).tickSize(0))
         .call(adjustTotalTextLabels)

   # Maybe add today line.
   startDay = DateUtils.getDetachedDay(xDomain[0])
   endDay = DateUtils.getDetachedDay(xDomain[1])
   today = DateUtils.getDetachedDay(new Date())
   if startDay <= today <= endDay
      daysFromStart = DateUtils.daysBetweenDates(xDomain[0], new Date())
      todayOffset = daysToPixels(daysFromStart)
      svg.append("rect")
         .attr("class", "manpower-graph__today-line")
         .attr("transform", "translate(#{todayOffset + margin.left},0)")
         .attr("width", 2)
         .attr("height", (frameHeight - bottomMargin))
         .append("title")
            .text("Today")


ko.bindingHandlers["stackedAreaGraph"] =
   init: (element, valueAccessor) ->
      accessor = ko.unwrap(valueAccessor())
      graphData = ko.unwrap(accessor.graphData())
      totalsData = ko.unwrap(accessor.totalsData())
      graphColumns = ko.unwrap(accessor.columns())
      graphColors = ko.unwrap(accessor.colors())
      graphOptions = ko.unwrap(accessor.options())

      drawGraph(element, graphData, totalsData, graphColumns, graphColors, graphOptions) unless graphData.length == 0

      redraw = (newGraphData, newTotalsData, newGraphColumns, newGraphColors, newGraphOptions) =>
         $(".manpower-graph__graph").empty()
         $(".manpower-graph__fixed-y-axis-container").empty()
         graphData = newGraphData
         totalsData = newTotalsData
         graphColumns = newGraphColumns
         graphColors = newGraphColors
         graphOptions = newGraphOptions

         drawGraph(element, graphData, totalsData, graphColumns, graphColors, graphOptions)

      resizeTimeout = null
      resizeHandler = () =>
         # This causes it to only fire after resizing stops.
         clearTimeout(resizeTimeout)
         resizeTimeout = setTimeout () ->
            redraw(graphData, totalsData, graphColumns, graphColors, graphOptions)
         , 250

      $(window).on("resize", resizeHandler)
      ko.utils.domNodeDisposal.addDisposeCallback element, () ->
         $(window).off("resize", resizeHandler)