import { Color } from "@/lib/utils/color"
import ko from "knockout"
import $ from "jquery"

ko.bindingHandlers["colorWheel"] =
    init: (element, valueAccessor) ->
      hexVal = valueAccessor().value
      onSelected = valueAccessor().onSelected
      hideButtons = valueAccessor().hideButtons or false
      initialColor = ko.unwrap(hexVal);
      defaultColor = initialColor || "#34FFA9"

      updateColor = (newColor) ->
         if Color.isHexColor(newColor.toHexString())
            hexVal(newColor.toHexString()) 
            onSelected(newColor.toHexString()) if onSelected?
      
      handleMove = (newColor) ->
         return unless hideButtons
         if Color.isHexColor(newColor.toHexString())
            hexVal(newColor.toHexString()) 
            onSelected(newColor.toHexString()) if onSelected?

      subscription = hexVal.subscribe((value) =>
         if Color.isHexColor(value)
            $(element).find('.color-picker').spectrum("set", value)
      )

      spectrumOptions = {
         type: "flat",
         showInput: true,
         showInitial: true,
         showAlpha: false,
         showButtons: !hideButtons,
         allowEmpty: false,
         change: updateColor,
         clickoutFiresChange: false
      }
      spectrumOptions['move'] = handleMove if hideButtons
      
      colorPicker = $(element).find('.color-picker').spectrum(spectrumOptions)

      # Set initial color
      initialValue = if Color.isHexColor(hexVal()) then hexVal() else defaultColor
      $(element).find('.color-picker').spectrum("set", initialValue)

      ko.utils.domNodeDisposal.addDisposeCallback(colorPicker, () =>
         subscription.dispose()
      );
