ko.bindingHandlers["maintainScrollPosition"] =
   init: (element, valueAccessor) ->
      elements = ko.unwrap(valueAccessor()).elements
      
      if !ko.isObservable(elements) 
         throw new Error ("'elements' must be an observable array")

      firstElement = elements()[0]
      scrollHeight = element.scrollHeight

      beforeSubscription = elements.subscribe () =>
         scrollHeight = element.scrollHeight
      , null, "beforeChange"

      afterSubscription = elements.subscribe (newElements) =>
         index = newElements.indexOf(firstElement)
         firstElement = newElements[0]
         # Elements have entirely changed or new elements were added to the end;
         # no need to adjust scroll position.
         if index == -1 or index == 0 
            return
         requestAnimationFrame () ->
            # Ignore when the scroll height has shrank.
            if element.scrollHeight <= scrollHeight
               return

            element.scrollTop += element.scrollHeight - scrollHeight
      ko.utils.domNodeDisposal.addDisposeCallback element, () ->
         beforeSubscription.dispose()
         afterSubscription.dispose()