import { AttachmentStore } from "@/stores/attachment-store.core";
import LaunchDarklyClient from "@laborchart-modules/launch-darkly-browser";
import { bindingHandlers, unwrap } from "knockout";

bindingHandlers["fileDownload"] = {
   init: function (_, valueAccessor) {
      const accessor = unwrap(valueAccessor());
      const url = unwrap(accessor.url);
      const callback = unwrap(accessor.callback);
      const isHttpPost = unwrap(accessor.http_post);
      const postPayload = unwrap(accessor.data);

      // If we are using jwt auth header, we can't use jquery
      LaunchDarklyClient.getFlagValue("use-jwt-auth")
         ? downloadFile(url, callback)
         : $.fileDownload(url, {
              successCallback: (url: string) => {
                 return callback(null, url);
              },
              failCallback: () => {
                 return callback(new Error("File Download Failed."));
              },
              httpMethod: isHttpPost ? "POST" : undefined,
              data: isHttpPost ? postPayload : undefined,
           });
   },
};

export async function downloadFile(
   downloadUrl: string,
   callback: (err: Error | null, url?: string) => void,
) {
   try {
      const response = await AttachmentStore.getSignedUrl(downloadUrl).payload;
      const { url, filename } = response.data;

      if (url) {
         forceDownload(url, filename);
         if (callback) callback(null, url);
      } else {
         throw new Error("Signed URL not found.");
      }
   } catch (error) {
      console.error("File Download Failed:", error);
      if (callback) callback(new Error("File Download Failed."));
   }
}

export function forceDownload(url: string, filename: string) {
   const a = document.createElement("a");
   a.download = filename;
   a.target = "_blank";
   a.href = url;
   document.body.appendChild(a);
   a.click();
   document.body.removeChild(a);
}
