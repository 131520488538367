ko.bindingHandlers["contentEditable"] =
   init: (element, valueAccessor) =>
      element.contentEditable = true
      content = valueAccessor().content
      element.innerHTML = content() if content()?
      range = valueAccessor().range
      parentNode = valueAccessor().parentNode
      baseContent = valueAccessor().baseContent
      valueAccessor().appliedElement(element)

      $(element).on 'keyup mouseup', (e) =>
         range(window.getSelection().getRangeAt(0))
         parentNode(range().commonAncestorContainer.parentNode)
         content(e.currentTarget.innerHTML)
         if e.currentTarget.innerHTML == ''
            e.currentTarget.innerHTML = baseContent
            content(baseContent)

   update: (element, valueAccessor) =>
      content = ko.unwrap(valueAccessor().content)
      element.innerHTML = content if element.innerHTML == ""