THRESHOLD = 40

ko.bindingHandlers["scrolledToBottom"] =
   init: (element, valueAccessor) ->
      $element = $(element)
      contentClass = ko.unwrap(valueAccessor().contentClass)
      callback = ko.unwrap(valueAccessor().callback)

      handler = () =>
         viewportHeight = $element.height()
         return unless viewportHeight?
         contentHeight = 0
         if contentClass?
            content = document.getElementsByClassName(contentClass)[0]
            return unless content?
            if content instanceof SVGElement
               contentHeight = content.getBBox().height
            else
               contentHeight = content.offsetHeight
         else
            # TODO: Shold be checking if the children are SVGs
            $element.children().each((index, child) -> contentHeight += $(child).height())
         return unless contentHeight?
         if $element.scrollTop() + viewportHeight >= contentHeight - THRESHOLD
            callback()

      $element.on("scroll", handler)
      ko.utils.domNodeDisposal.addDisposeCallback(element, -> $element.off("scroll", handler))