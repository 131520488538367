import type { Observable } from "knockout";
import { Color } from "@/lib/utils/color";
import ko from "knockout";

import $ from "jquery";

ko.bindingHandlers["colorWheel2"] = {
   init: (
      element,
      valueAccessor: () => {
         value: Observable<string | null>;
         onSelected?: (newColor: string) => void;
         hideButtons?: boolean;
      },
   ) => {
      const hexVal = valueAccessor().value;
      const onSelected = valueAccessor().onSelected;
      const hideButtons = valueAccessor().hideButtons || false;
      const defaultColor = ko.unwrap(hexVal) ?? "#34FFA9";

      const updateColor = function (newColor: { toHexString: () => string }) {
         if (Color.isHexColor(newColor.toHexString())) {
            hexVal(newColor.toHexString());
            if (onSelected != undefined) {
               onSelected(newColor.toHexString());
            }
         }
      };

      const handleMove = function (newColor: { toHexString: () => string }) {
         if (hideButtons && Color.isHexColor(newColor.toHexString())) {
            hexVal(newColor.toHexString());
            if (onSelected != undefined) {
               onSelected(newColor.toHexString());
            }
         }
      };

      const subscription = hexVal.subscribe((value: string | null) => {
         if (Color.isHexColor(value)) {
            return ($(element).find(".color-picker") as any).spectrum("set", value);
         }
      });

      const spectrumOptions: { [key: string]: any } = {
         type: "flat",
         showInput: true,
         showInitial: true,
         showAlpha: false,
         showButtons: !hideButtons,
         allowEmpty: false,
         change: updateColor,
         clickoutFiresChange: false,
      };
      if (hideButtons) {
         spectrumOptions["move"] = handleMove;
      }

      const colorPicker = ($(element).find(".color-picker") as any).spectrum(spectrumOptions);

      // Set initial color
      const initialValue = Color.isHexColor(hexVal()) ? hexVal() : defaultColor;
      ($(element).find(".color-picker") as any).spectrum("set", initialValue);

      return ko.utils.domNodeDisposal.addDisposeCallback(colorPicker, () => {
         return subscription.dispose();
      });
   },
};
