import { Guid as GUID } from "@/lib/utils/guid"

export class ClickOffManager
   constructor: ->
      @activeElements = {}

      document.addEventListener("click", @fireActiveCallbacks_)
      document.addEventListener("touchstart", @fireActiveCallbacks_)

   fireActiveCallbacks_: (data) =>
      if data.toElement?
         @clickedElement = data.toElement
      else if data.target?
         @clickedElement = data.target
      for id, options of @activeElements
         unless @clickedElement == options.element
            unless $.contains(options.element, @clickedElement)
               allowClick = false
               namesToCheck = []
               for name in @clickedElement.classList
                  namesToCheck.push(name)
               namesToCheck.push(@clickedElement.id) if @clickedElement.id != ''
               for name in namesToCheck
                  if (options.ignoreClasses.indexOf(name) != -1)
                     allowClick = true
                     break
               options.callback() unless allowClick


clickOffManager = new ClickOffManager()

ko.bindingHandlers["clickOff"] =
   init: (element, valueAccessor) ->
      options = ko.unwrap(valueAccessor())
      element = element
      unless options.ignoreClasses?
         options.ignoreClasses = ko.observableArray([])

      if options.listeningTrigger()
         # Add unique class identifier
         uniqueId = GUID()
         element.classList.add(uniqueId)
         # Add element to options
         options.element = element
         clickOffManager.activeElements[uniqueId] = options
      else
         for name in element.classList
            if clickOffManager.activeElements[name]
               delete clickOffManager.activeElements[name]
               $(element).removeClass(name)

      ko.utils.domNodeDisposal.addDisposeCallback element, (el) =>
         for name in el.classList
            if clickOffManager.activeElements[name]
               delete clickOffManager.activeElements[name]
               break

   update: (element, valueAccessor) ->
      options = ko.unwrap(valueAccessor())
      element = element
      unless options.ignoreClasses?
         options.ignoreClasses = ko.observableArray([])

      if options.listeningTrigger()
         # Check to see if already an active element.
         for name in element.classList
            return if clickOffManager.activeElements[name]

         # Add unique class identifier
         uniqueId = GUID()
         element.classList.add(uniqueId)
         # Add element to options
         options.element = element
         clickOffManager.activeElements[uniqueId] = options
      else
         for name in element.classList
            if clickOffManager.activeElements[name]
               delete clickOffManager.activeElements[name]
               $(element).removeClass(name)