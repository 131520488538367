import { Dom } from "@/lib/utils/dom"

LOGO_MAX_WIDTH = 560
LOGO_MAX_HEIGHT = 192

ko.bindingHandlers["logo-upload"] =
   init: (element, valueAccessor) ->
      fileContent = valueAccessor()

      handler = =>
         file = element.files[0]
         # Opening the file picker then canceling will trigger a 'change'
         # event without actually picking a file.
         if !file
            return fileContent(null)

         if file.type != "image/png"
            alert("Logo image must be a .png file.")
            return fileContent(null)

         reader = new FileReader()
         reader.addEventListener("load", () =>
            if await imageIsTooLarge_(reader.result)
               alert("Image must be within #{LOGO_MAX_WIDTH} pixels wide and #{LOGO_MAX_HEIGHT} pixels tall.")
               return fileContent(null)

            fileContent(reader.result)
         , false)

         reader.readAsDataURL(file)

      ko.utils.registerEventHandler element, 'change', handler

imageIsTooLarge_ = (logoImageSrc) =>
   return new Promise (resolve) =>
      image = new Image()
      image.src = logoImageSrc
      image.onload = () => 
         height = image.height
         width = image.width
         if height > LOGO_MAX_HEIGHT or width > LOGO_MAX_WIDTH
            resolve true
         else
            resolve false