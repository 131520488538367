ko.bindingHandlers["gridColumnWidth"] =
   init: (element, valueAccessor) ->
      onResize = ->
         setElementWidth(element, valueAccessor)
      $(window).on("resize", onResize)
      ko.utils.domNodeDisposal.addDisposeCallback element, ->
         $(window).off("resize", onResize)

   update: (element, valueAccessor) ->
      setElementWidth(element, valueAccessor)

setElementWidth = (element, valueAccessor) ->      
   accessor = ko.unwrap(valueAccessor())
   auto = Boolean(ko.unwrap(accessor.auto))
   minimumWidth = ko.unwrap(accessor.minWidth)
   combinedColumnWidth = ko.unwrap(accessor.combinedColumnWidth)
   numberOfResizableColumns = ko.unwrap(accessor.numberOfResizableColumns)
   containerClass = ko.unwrap(accessor.container)

   container = findContainer(element, containerClass)
   throw new Error("Unable to find container: #{containerClass}") if !container

   additionalWidth = (container.clientWidth - combinedColumnWidth) / numberOfResizableColumns
   element.style.width = if !auto then "#{minimumWidth}px" else "#{minimumWidth + Math.max(0, additionalWidth)}px"

findContainer = (element, containerClass) ->
   parent = element.parentElement
   while parent and !parent.classList.contains(containerClass)
      parent = parent.parentElement
   return parent