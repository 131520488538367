ko.bindingHandlers["foreachprop"] =
   # transformObject: (obj) ->
   #    properties = []
   #    for key, val of obj
   #       properties.push({ key: key, value: obj[key] }) if obj.hasOwnProperty(key)
   #    return properties

   "init": (element, valueAccessor, allBindingsAccessor, viewModel, bindingContext) ->
      obj = ko.utils.unwrapObservable(valueAccessor())
      # properties = ko.bindingHandlers.foreachprop.transformObject(value)
      properties = []
      for key, val of obj
         properties.push val
      ko.applyBindingsToNode(element, { foreach: properties }, bindingContext)
      return { controlsDescendantBindings: true }
