# The type declaration for TemplateSource is challenging to actually
# implement. We'll just fake it instead.
export class PugTemplateSource
   constructor: (template) ->
      @template = template
      @storedData = {}

   data: (key, value) ->
      return @storedData[key] if arguments.length == 1
      @storedData[key] = value
      return value

   text: () ->
      # Unable to set template text, just return if attempting to set.
      return if arguments.length == 1
      return @template(@storedData) if @template instanceof Function
      return @template