import { DateUtils } from "@/lib/utils/date"
import { defaultStore } from "@/stores/default-store"
import ko from "knockout";
import $ from "jquery";
import * as d3 from "d3";

drawGantt = (element, rowData, rangeData, appending, callbacks) ->
   # Get chart area width
   barPadding = 2
   barHeight = 15
   frameWidth = element.offsetWidth
   frameHeight = $("#gantt-chart-wrapper").height()
   infoSecWidth = 240
   profilePicDiameter = 36
   positionColorDiameter = 12
   infoSecCountWidth = 40

   if rangeData.disableInfoSecion
      infoSecWidth = 0
      chartWidth = frameWidth
   else
      chartWidth = frameWidth - infoSecWidth

   rangeStart = rangeData.startDate

   rangeEnd = new Date(rangeData.startDate.getTime())
   rangeEnd.setDate(rangeStart.getDate() + rangeData.days)

   # Setup X Axis
   xScale = d3.scaleTime()
   # This needs to be be 1 tick longer so that the labels can be
   # centered and it will still display properly.
   .domain([rangeStart, rangeEnd])
   .range([0, chartWidth])
   .clamp(true)

   # Gets valid temporal values.
   if rangeData.days == 1
      ticks = xScale.ticks(d3.timeHour.every(1))
   else if rangeData.days < 91
      ticks = xScale.ticks(d3.timeDay.every(1))
   else if rangeData.days < 182
      ticks = xScale.ticks(d3.timeWeek.every(1))
   else
      ticks = xScale.ticks(d3.timeMonth.every(1))

   hoursToPixels = (hours) ->
      d1 = new Date(rangeData.startDate.getTime())
      return xScale(d3.timeHour.offset(d1, hours)) - xScale(d1)

   daysToPixels = (days) ->
      d1 = new Date(rangeData.startDate.getTime())
      return xScale(d3.timeDay.offset(d1, days)) - xScale(d1)

   monthsToPixels = (months) ->
      d1 = new Date(rangeData.startDate.getTime())
      return xScale(d3.timeMonth.offset(d1, months)) - xScale(d1)

   unless appending
      resetChart()

      svg = d3.select("#gantt-chart")
         .append("svg")
         .attr("class", "wrapper")
         .attr("width", frameWidth)
         .attr("height", frameHeight)
         .attr("overflow", "visible")

      # Add profile pic clip path
      circleClip = svg.append("clipPath")
         .attr("id", "circleClip")
         .attr("clipPathUnits", "objectBoundingBox")

      circleClip.append("circle")
         .attr("r", 0.5)
         .attr("cx", 0.5)
         .attr("cy", 0.5)

      # Add short person name clip path
      shortPersonNameClip = svg.append("clipPath")
         .attr("id", "shortPersonNameClip")

      shortPersonNameClip.append("rect")
         .attr("width", 146)
         .attr("height", 18)
         .attr("x", 0)
         .attr("y", 0)

      # Add short person name clip path
      longPersonNameClip = svg.append("clipPath")
         .attr("id", "longPersonNameClip")

      longPersonNameClip.append("rect")
         .attr("width", 186)
         .attr("height", 18)
         .attr("x", 0)
         .attr("y", 0)

      # Add short position name clip path
      shortPositionNameClip = svg.append("clipPath")
         .attr("id", "shortPositionNameClip")

      shortPositionNameClip.append("rect")
         .attr("width", 130)
         .attr("height", 18)
         .attr("x", 0)
         .attr("y", 0)

      # Add short position name clip path
      longPositionNameClip = svg.append("clipPath")
         .attr("id", "longPositionNameClip")

      longPositionNameClip.append("rect")
         .attr("width", 170)
         .attr("height", 18)
         .attr("x", 0)
         .attr("y", 0)

      colorWeekends = (selection) ->
         if 1 < rangeData.days < 91
            selection.selectAll('.tick').each (data) ->
               day = data.getDay()
               if day == 0 or day == 6
                  d3.select(@).append("rect")
                     .attr("class", "weekend-bg")
                     .attr("height", frameHeight)
                     .attr("width", daysToPixels(1))
                     .attr("fill", "black")
                     .attr("opacity", "0.2")

      # Grid lines
      gridlines = d3.axisTop()
        .tickFormat("")
        .tickSize(-frameHeight)
        .scale(xScale)

      gridlines.tickValues(ticks)

      svg.append("g")
        .attr("class", "grid")
         .attr("transform", "translate(#{infoSecWidth},0)")
        .call(gridlines)
        .call(colorWeekends)


      chart = svg.append("g")
         .attr("class", "chart")
         .attr("transform", "translate(0,0)")

   if appending
      chart = d3.select('.chart')
      svg = d3.select('.wrapper')
      chart = d3.select('.chart')

      # This class will never be used but lets D3 append to a parent w/ existing children.
      row = chart.selectAll(".none-existant-rows")
      .data(rowData)
      .enter().append("svg")
         .attr("class", "row")
         .attr("x", 0)
         # Mult bar + padding for every teir before and add the total row buffer (1 barHeight)
         # for each total row before.
         .attr("y", (d) -> 
            return (d.tiersBefore * (barHeight + barPadding)) + (d.rowsBefore * barHeight)
         )
         .attr("width", frameWidth)
         .attr("height", (d) -> return (d.rowTiers * (barHeight + barPadding)) + barHeight)
   else
      row = chart.selectAll("svg")
      .data(rowData)
      .enter().append("svg")
         .attr("class", "row")
         .attr("x", 0)
         # Mult bar + padding for every teir before and add the total row buffer (1 barHeight)
         # for each total row before.
         .attr("y", (d, i) -> return (d.tiersBefore * (barHeight + barPadding)) + (i * barHeight))
         .attr("width", frameWidth)
         .attr("height", (d) -> return (d.rowTiers * (barHeight + barPadding)) + barHeight)

   row.append("rect")
      .attr("class", "row-bg")
      .attr("x", infoSecWidth)
      .attr("width", chartWidth)
      .attr("height", "100%")

   unless rangeData.disableInfoSecion
      infoSection = row.append("g")
         .attr("transform", "translate(0,0)")

      infoSection.append("rect")
         .attr("class", "row-info-section")
         .attr("width", infoSecWidth)
         .attr("height", (d) -> return (d.rowTiers * (barHeight + barPadding)) + barHeight)

      rowCount = infoSection.append("g")
         .attr("width", infoSecCountWidth)
         .attr("height", (d) -> return (d.rowTiers * (barHeight + barPadding)) + barHeight)

      rowCount.append("rect")
         .attr("class", "row-info-section__count")
         .attr("width", infoSecCountWidth)
         .attr("height", (d) -> return (d.rowTiers * (barHeight + barPadding)) + barHeight)
         .attr("fill", "#e1e1e1")
         
      rowCount.append("text")
         .text((d) -> return d.rowsBefore + 1)
         .attr("font-family", "OpenSans")
         .attr("font-size", "12px")
         .attr("fill", "black")
         .attr("dominant-baseline", "hanging")
         .attr("text-anchor", "middle")
         .attr("x", (infoSecCountWidth / 2))
         .attr("transform", () ->
            "translate(0,8)"
         )

      personNameGroup = infoSection.append("g")
         .attr("clip-path", (d) ->
            if d.profilePicUrl?
               return "url(#shortPersonNameClip)"
            else
               return "url(#longPersonNameClip)"
         )
         .attr("transform", (d) ->
            if d.profilePicUrl?
               xDelta = (18 + profilePicDiameter + infoSecCountWidth)
            else
               xDelta = 14 + infoSecCountWidth
            "translate(#{xDelta},8)"
         )

      personNameGroup.append("text")
         .text((d) -> return d.personName)
         .attr("class", "row-info-section__name")
         .attr("font-family", "OpenSans")
         .attr("font-size", "12px")
         .attr("fill", "black")
         .attr("dominant-baseline", "hanging")

      infoSection.append("rect")
         .attr("class", "row-info-section__position-color")
         .attr("fill", (d) -> return d.positionColor)
         .attr("width", (d) -> return if d.positionColor then positionColorDiameter else 0)
         .attr("height", (d) -> return if d.positionColor then positionColorDiameter else 0)
         .attr("x", (d) ->
            if d.profilePicUrl?
               return (18 + profilePicDiameter + infoSecCountWidth)
            else
               return 14 + infoSecCountWidth
         )
         .attr("y", 28)
         .attr("clip-path", "url(#circleClip)")

      positionNameGroup = infoSection.append("g")
         .attr("clip-path", (d) ->
            if d.profilePicUrl?
               return "url(#shortPositionNameClip)"
            else
               return "url(#longPositionNameClip)"
         )
         .attr("transform", (d) ->
            if d.profilePicUrl?
               xDelta = 22 + profilePicDiameter + positionColorDiameter + infoSecCountWidth
            else
               xDelta = positionColorDiameter + 18 + infoSecCountWidth
            "translate(#{xDelta},28)"
         )


      positionNameGroup.append("text")
         .text((d) ->
            return d.positionName or ''
         )
         .attr("class", "row-info-section__position")
         .attr("font-family", "OpenSans")
         .attr("font-size", "10px")
         .attr("fill", "black")
         .attr("dominant-baseline", "hanging")

      infoSection.append("image")
         .attr("class", "row-info-section__image")
         .attr("width", profilePicDiameter)
         .attr("height", profilePicDiameter)
         .attr("href", (d) -> 
            if d.profilePicUrl?
               urlChunks = d.profilePicUrl.split("upload/")
               return "#{urlChunks[0]}upload/fl_ignore_aspect_ratio/#{urlChunks[1]}"
            else
               return ''
         )
         # For Safari
         .attr("xlink:href", (d) -> 
            if d.profilePicUrl?
               urlChunks = d.profilePicUrl.split("upload/")
               return "#{urlChunks[0]}upload/fl_ignore_aspect_ratio/#{urlChunks[1]}"
            else
               return ''
         )
         .attr("x", 10 + infoSecCountWidth)
         .attr("y", 6)
         .attr("clip-path", "url(#circleClip)")
         .attr("preserveAspectRatio", "xMinYMin slice")

      # For the puspose of consistent clicks & hovers.
      infoSection.append("rect")
         .attr("class", "row-info-section__overlay")
         .attr("opacity", "0")
         .attr("width", infoSecWidth)
         .attr("height", (d) -> return (d.rowTiers * (barHeight + barPadding)) + barHeight)
         .on "click", (d) ->
            callbacks.infoSectionClicked(d, d3.event)    

   getElementWidth = (d) ->
      # Need Times for single day.
      if rangeData.days == 1
         d.start = new Date(rangeData.startDate.getTime())
         d.end = new Date(rangeData.startDate.getTime())

         startTimeChunks = String(d.startTime).split(".")
         d.start.setHours(startTimeChunks[0])
         if startTimeChunks[1]?
            # Catches "5"
            startTimeChunks[1] = "#{startTimeChunks[1]}0" if startTimeChunks[1].length == 1
            d.start.setMinutes(60 * (Number(startTimeChunks[1]) / 100))

         if d.endTime > d.startTime
            endTimeChunks = String(d.endTime).split(".")
            d.end.setHours(endTimeChunks[0])
            if endTimeChunks[1]?
               endTimeChunks[1] = "#{endTimeChunks[1]}0" if endTimeChunks[1].length == 1
               d.end.setMinutes(60 * (Number(endTimeChunks[1]) / 100))
         else
            # Overnight
            # Night Before
            if d.singleDay? and d.singleDay < DateUtils.getDetachedDay(rangeData.startDate)
               d.start.setHours(0)
               d.start.setMinutes(0)
               endTimeChunks = String(d.endTime).split(".")
               d.end.setHours(endTimeChunks[0])
               if endTimeChunks[1]?
                  endTimeChunks[1] = "#{endTimeChunks[1]}0" if endTimeChunks[1].length == 1
                  d.end.setMinutes(60 * (Number(endTimeChunks[1]) / 100))
            else
               d.end.setHours(39)
               d.end.setMinutes(59)

      else
         # Set start/end back
         d.start = DateUtils.getAttachedDate(d.startDay)
         d.end = DateUtils.getAttachedDate(d.endDay + 1)

      return xScale(d.end) - xScale(d.start)

   getXDisplacement = (d) ->
      return xScale(d.start)

   # TimeOff Bars
   timeOffBar = row.selectAll(".row").data((d) -> d.timeoff)
   .enter().append("rect")
      .attr("class", "to-bar")
      .attr("rx", 2)
      .attr("ry", 2)
      # Get the temporal width and subject padding to make gaps between assignments.
      .attr("width", (d) -> return getElementWidth(d, true) - (barPadding * 2))
      .attr("height", barHeight)
      # Get horizonal displacement and move over by 1 padding to center.
      .attr("x", (d) -> return getXDisplacement(d) + barPadding + infoSecWidth)
      # Position based on tier, give it vertical padding and move up half bar height for
      # total row padding.
      .attr("y", (d) -> return (d.tier * (barHeight + barPadding)) + (barHeight * .5))
      .style("fill", "url(#crosshatch)")

   timeOffBar.on "click", (d) ->
      callbacks.timeOffClicked(d, d3.event)

   if callbacks.assignmentMouseOver
      timeOffBar.on "mouseover", (d) ->
         callbacks.assignmentMouseOver(d)

      timeOffBar.on "mouseout", (d) ->
         callbacks.assignmentMouseOut(d)

   # Assignment Bars
   assignmentBar = row.selectAll(".row").data((d) -> d.assignments)
   .enter().append("rect")
      .attr "class", (d) ->
         return if d.isPending then "bar bar--pending" else "bar"
      .attr("rx", 2)
      .attr("ry", 2)
      # Get the temporal width and subject padding to make gaps between assignments.
      .attr("width", (d) ->
         if rangeData.days == 182
            return getElementWidth(d) - 2
         else if rangeData.days > 182
            return getElementWidth(d)
         else
            return getElementWidth(d) - (barPadding * 2)
      )
      .attr("height", barHeight)
      # Get horizonal displacement and move over by 1 padding to center.
      .attr("x", (d) -> return getXDisplacement(d) + barPadding + infoSecWidth)
      # Position based on tier, give it vertical padding and move up half bar height for
      # total row padding.
      .attr("y", (d) ->
         # TODO: This may be able to be optimized if the teir calculations were combined.
         toTiers = d3.select(@.parentNode).datum().toTiers
         return (toTiers * (barHeight + barPadding)) + (d.tier * (barHeight + barPadding)) + (barHeight * .5))
      .attr "style", (d) ->
         return if d.isPending then "fill:url(#diagonal-stripe-3)" else "fill:#{d.color};"
      .on "click", (d) ->
         callbacks.assignmentClicked(d, d3.event)

      .on "mouseover", () ->
         # Add classes to element.
         thisBar = d3.select(@)
         thisBar.attr("class", "#{thisBar.attr("class")} gantt-bar-hover")

      .on "mouseout", () ->
         # Reset classes on element.
         thisBar = d3.select(@)
         thisBar.attr("class", thisBar.attr("class").replace("gantt-bar-hover", ""))

   if callbacks.assignmentMouseOver
      assignmentBar.on "mouseover", (d) ->
         callbacks.assignmentMouseOver(d)

      assignmentBar.on "mouseout", (d) ->
         callbacks.assignmentMouseOut(d)

   adjustTextLabels = (selection) ->
      selection.selectAll('.tick').each (data) ->
         if 1 < rangeData.days < 91
            day = data.getDay()
            if day == 0 or day == 6
               d3.select(@).append("rect")
                  .attr("class", "axis-weekend-bg")
                  .attr("height", 20)
                  .attr("width", daysToPixels(1))
                  .attr("fill", "black")
                  .attr("opacity", "0.2")

         if rangeData.days == 1
            xDelta = hoursToPixels(1)
         else if rangeData.days < 91
            xDelta = daysToPixels(1)
         else if rangeData.days < 182
            # TODO: evaluate per tick calculation to handle non-whole segmeents (like secondary axis).
            xDelta = daysToPixels(7)
         else
            # TODO: evaluate per tick calculation to handle non-whole segmeents (like secondary axis).
            xDelta = monthsToPixels(1)

         d3.select(@).selectAll('text')
            .attr('transform', 'translate(' + xDelta / 2 + ',-14)')

   adjustSecondaryTextLabels = (selection) ->
      selection.selectAll('.tick').each (data) ->
         sectionStart = xScale(data)
         if rangeData.days < 182
            nextTickDate = new Date(data.getTime())
            nextTickDate.setMonth(nextTickDate.getMonth() + 1)
            nextTickDate.setDate(1)
            sectionEnd = xScale(nextTickDate)
         else
            nextTickDate = new Date(data.getTime())
            nextTickDate.setYear(nextTickDate.getFullYear() + 1)
            nextTickDate.setMonth(0)
            nextTickDate.setDate(1)
            sectionEnd = xScale(nextTickDate)

         offset = (sectionEnd - sectionStart) / 2
         d3.select(@).selectAll('text')
            .attr('transform', "translate(#{offset},-14)")

   unless appending
      if rangeData.days == 1
         tickFormat = d3.timeFormat("%I:%M %p")
      else if rangeData.days < 28
         tickFormat = d3.timeFormat("%a %e")
      else if rangeData.days < 56
         if frameWidth <= 1200
            tickFormat = d3.timeFormat("%e")
         else
            tickFormat = d3.timeFormat("%a %e")
      else if rangeData.days < 91
         tickFormat = d3.timeFormat("%e")
      else if rangeData.days < 182
         tickFormat = if DateUtils.hasDayBeforeMonth(defaultStore.getDateFormat()) then d3.timeFormat("%e %b") else d3.timeFormat("%b %e")
      else
         tickFormat = d3.timeFormat("%b")

      if rangeData.days == 1
         secondaryTickFormat = (date) -> DateUtils.formatDate(date, defaultStore.getDateFormat(), DateUtils.LONG_FORM_OPTIONS)
      else if rangeData.days < 182
         secondaryTickFormat = d3.timeFormat("%B")
      else
         secondaryTickFormat = d3.timeFormat("%Y")

      xAxis = d3.axisBottom(xScale)
      .tickFormat(tickFormat)
      .tickSize(18.6)

      xAxis.tickValues(ticks)

      # For secondary axis
      secondaryStart = new Date(rangeStart.getTime())
      unless rangeData.days == 1
         secondaryStart.setDate(1)

      unless rangeData.days < 182
         secondaryStart.setMonth(0)

      if rangeData.days == 1
         secondaryEnd = new Date(rangeStart.getTime())
      else
         secondaryEnd = new Date(rangeEnd.getTime())
         secondaryEnd.setDate(1)

      secondaryXScale = d3.scaleTime()
      .domain([secondaryStart, secondaryEnd])
      .range([0, chartWidth])
      .clamp(true)

      if rangeData.days == 1
         secondaryTicks = secondaryXScale.ticks(d3.timeDay.every(1))
      else if rangeData.days >= 182
         secondaryTicks = secondaryXScale.ticks(d3.timeYear.every(1))
      else 
         secondaryTicks = secondaryXScale.ticks(d3.timeMonth.every(1))

      secondaryXAxis = d3.axisBottom(xScale)
      .tickFormat(secondaryTickFormat)
      .tickSize(19.6)

      secondaryXAxis.tickValues(secondaryTicks)

      xAxisBar = d3.select("#gantt-x-axis")
      .append("svg")
         .attr("class", "x-axis-wrapper")
         .attr("width", frameWidth)
         .attr("height", 40)

      xAxisBar.append("g")
         .attr("class", "x-axis-group")
         .attr("transform", "translate(#{infoSecWidth},20)")
         .style('font', -> if rangeData.days == 1 and frameWidth < 1200 then "8px OpenSans" else "10px OpenSans")
         .call(xAxis)
         .call(adjustTextLabels)

      xAxisBar.append("g")
         .attr("class", "x-axis-group--secondary")
         .attr("transform", "translate(#{infoSecWidth},-1)")
         .style('font', "11px OpenSans")
         .call(secondaryXAxis)
         .call(adjustSecondaryTextLabels)

   # Update heights after draw.
   chartHeight = d3.select(".chart").node().getBBox().height + 46
   svg.attr("height", chartHeight) if chartHeight > frameHeight
   grid = d3.select(".grid")

   grid.selectAll('.tick line')
      .attr("y2", if chartHeight < frameHeight then frameHeight else chartHeight)

   grid.selectAll('.tick .weekend-bg')
      .attr("height", if chartHeight < frameHeight then frameHeight else chartHeight)

resetChart = () ->
   $("#gantt-chart").empty()
   $("#gantt-x-axis").empty()

ko.bindingHandlers["ganttChart"] =
   init: (element, valueAccessor) ->
      accessor = ko.unwrap(valueAccessor())
      callbacks = accessor.callbacks
      rowData = accessor.rows()
      rangeData = accessor.rangeData
      isDisposed = false

      drawGantt(element, rowData, rangeData, accessor.callbacks) unless accessor.rows().length == 0
      redraw = (newRowData, newRangeData) =>
         return if isDisposed
         rowData = newRowData
         rangeData = newRangeData
         drawGantt(element, rowData, rangeData, false, callbacks)

      appendData = (newData, newRangeData) =>
         return if isDisposed
         rangeData = newRangeData
         drawGantt(element, newData, rangeData, true, callbacks)

      resizeHandler = () => redraw(rowData, rangeData, false)

      $(window).on("resize", resizeHandler)
      ko.utils.domNodeDisposal.addDisposeCallback element, () ->
         isDisposed = true
         resetChart()
         $(window).off("resize", resizeHandler)

      accessor.mediator.initialize({redraw: redraw, appendData: appendData})