ko.bindingHandlers["slideUpDown"] =
   "init": (element, valueAccessor) ->
      accessor = ko.unwrap(valueAccessor())
      show = ko.unwrap(accessor.show)
      callback = ko.unwrap(accessor.callback)
      unless show
         $(element).hide()
      if callback instanceof Function 
         callback(show)

   "update": (element, valueAccessor) ->
      accessor = ko.utils.unwrapObservable(valueAccessor())
      show = ko.unwrap(accessor.show)
      callback = ko.unwrap(accessor.callback)
      $el = $(element)

      if show
         $el.slideDown 300, ->
            callback(show) if callback instanceof Function 
            
      else
         $el.slideUp 300, ->
            callback(show) if callback instanceof Function 