ko.bindingHandlers["axisDrag"] =
   "init": (element, valueAccessor) ->
      accessor = ko.unwrap(valueAccessor())
      vertical = ko.unwrap(accessor.vertical)
      isDragging = accessor.isDragging

      topBuffer = if accessor.topBuffer? then ko.unwrap(accessor.topBuffer) else 10
      bottomBuffer = if accessor.bottomBuffer? then ko.unwrap(accessor.bottomBuffer) else 10
      scrollPercentHandler = ko.unwrap(accessor.scrollPercentHandler)
      if vertical
         axis = 'Y'
         direction = 'top'
      else
         axis = 'X'
         direction = 'left'

      startDragging = (e) ->
         e = e or window.event
         e.stopPropagation()
         e.preventDefault()
         isDragging(true)
         start = 0
         diff = 0
         newDisplacement = 0
         offset = if vertical then element.offsetTop else element.offsetLeft

         if e["page#{axis}"]?
            start = e["page#{axis}"]
         else if e["client#{axis}"]?
            start = e["client#{axis}"]

         moveHandle = (e) ->
            hitBounds = false
            e = e  or window.event
            e.stopPropagation()
            e.preventDefault()
            end = 0
            if e["page#{axis}"]?
               end = e["page#{axis}"]
            else if e["client#{axis}"]?
               end = e["client#{axis}"]

            diff = end - start
            displacement = offset + diff
            $element = $(element)
            if vertical
               parentHeight = $element.parent().height()
               railHeight = parentHeight - (topBuffer + bottomBuffer)
               elementHeight = $element.height()
            else
               parentWidth = $element.parent().width()
               railWidth = parentWidth - (topBuffer + bottomBuffer)
               elementWidth = $element.width()
            newDisplacement = if displacement > 0 then displacement else 0
            if newDisplacement < topBuffer
               newDisplacement = topBuffer
               hitBounds = true
            else
               if vertical
                  if newDisplacement > (parentHeight - elementHeight - bottomBuffer)
                     newDisplacement = (parentHeight - elementHeight - bottomBuffer)
                     hitBounds = true
               else
                  # TODO: what wizardry is happening here. clean this up.
                  if newDisplacement > (parentWidth - elementWidth - bottomBuffer + 1)
                     newDisplacement = (parentWidth - elementWidth - bottomBuffer)
                     hitBounds = true
            element.style[direction] = "#{newDisplacement}px"
            if vertical
               scrollPercentHandler(Math.round(((newDisplacement - topBuffer) / (railHeight - elementHeight)) * 100))
            else
               scrollPercentHandler(Math.round(((newDisplacement - topBuffer) / (railWidth - elementWidth)) * 100))
            finishDragging() if hitBounds

         document.body.onmousemove = moveHandle
         element.addEventListener("touchmove", moveHandle)

         finishDragging = (e) ->
            e = e  or window.event
            e.stopPropagation()
            e.preventDefault()
            isDragging(false)
            document.body.onmousemove = null
            document.body.onmouseup = null
            document.body.onmouseout = null
            element.removeEventListener("touchmove", moveHandle)
            element.removeEventListener("touchend", finishDragging)

         document.body.onmouseup = finishDragging

         document.body.onmouseout = (e) ->
            from = e.toElement or e.relatedTarget
            finishDragging(e) if !from

         element.addEventListener("touchend", finishDragging)

      element.onmousedown = startDragging
      element.addEventListener("touchstart", startDragging)