import type { MaybeObservable, Observable } from "knockout";
import ko, { unwrap } from "knockout";
import { requestAnimationFrames } from "@/lib/utils/animation";

const TRANSITION_CONTEXT = "transition-size-context";

export interface TransitionSizeContext {
   ignoreHeight: boolean;
   ignoreWidth: boolean;
   properties: Set<string>;
}

export interface TransitionSizeParams {
   trigger: Observable<any>;
   selector?: string | null;
   forceAbsolutePositioning?: boolean | null;
   ignoreWidth: MaybeObservable<boolean>;
   ignoreHeight: MaybeObservable<boolean>;
   onTransitionEnd?: () => void;
}

ko.bindingHandlers.transitionSize = {
   init(element, valueAccessor) {
      const params = ko.unwrap(valueAccessor()) as TransitionSizeParams;
      const subscription = params.trigger.subscribe(() => {
         transitionSize({
            element,
            selector: ko.unwrap(params.selector || null),
            forceAbsolutePositioning: Boolean(ko.unwrap(params.forceAbsolutePositioning)),
            ignoreHeight: unwrap(params.ignoreHeight || false),
            ignoreWidth: unwrap(params.ignoreWidth || false),
            onTransitionEnd: params.onTransitionEnd,
         });
      });

      const transitionRunHandler = (event: TransitionEvent) => {
         const context = getContext(element);
         if (event.target != element || !context) return;

         if (event.propertyName == "height" && !context.ignoreHeight) {
            context.properties.add("height");
         }
         if (event.propertyName == "width" && !context.ignoreWidth) {
            context.properties.add("width");
         }
      };

      const transitionEndOrCancelHandler = (event: TransitionEvent) => {
         // Ignore transition events occurring within the element.
         const context = getContext(element);
         if (event.target != element || !context) return;

         if (context.properties.has(event.propertyName)) {
            context.properties.delete(event.propertyName);
            element.style[event.propertyName] = "";
         }

         if (!context.properties.size) {
            setContext(element, null);
            if (params.onTransitionEnd) params.onTransitionEnd();
         }
      };

      element.addEventListener("transitionrun", transitionRunHandler);
      element.addEventListener("transitionend", transitionEndOrCancelHandler);
      element.addEventListener("transitioncancel", transitionEndOrCancelHandler);
      ko.utils.domNodeDisposal.addDisposeCallback(element, () => {
         subscription.dispose();
         element.addEventListener("transitionrun", transitionRunHandler);
         element.removeEventListener("transitionend", transitionEndOrCancelHandler);
         element.removeEventListener("transitioncancel", transitionEndOrCancelHandler);
      });
   },
};

function transitionSize({
   element,
   selector,
   forceAbsolutePositioning,
   ignoreWidth,
   ignoreHeight,
   onTransitionEnd,
}: {
   element: HTMLElement;
   selector: string | null;
   forceAbsolutePositioning: boolean;
   ignoreWidth: boolean;
   ignoreHeight: boolean;
   onTransitionEnd?: () => void;
}) {
   const measuringElement = selector ? element.querySelector(selector) : element.firstElementChild;

   if (!measuringElement) {
      return console.warn("transition-height: Measuring element not found.");
   }

   // Immediately update the element if a transition is already in progress.
   if (getContext(element)) {
      if (!ignoreHeight) element.style.height = `${measuringElement.clientHeight}px`;
      if (!ignoreWidth) element.style.width = `${measuringElement.clientWidth}px`;
      return;
   }

   // Freeze the size of the element.
   if (!ignoreHeight) element.style.height = `${element.clientHeight}px`;
   if (!ignoreWidth) element.style.width = `${element.clientWidth}px`;

   if (forceAbsolutePositioning) {
      (measuringElement as HTMLElement).style.position = "absolute";
   }

   // Wait for the measuring element to change size.
   setContext(element, { properties: new Set(), ignoreHeight, ignoreWidth });
   requestAnimationFrames(4, () => {
      if (!ignoreHeight) element.style.height = `${measuringElement.clientHeight}px`;
      if (!ignoreWidth) element.style.width = `${measuringElement.clientWidth}px`;
      requestAnimationFrames(2, () => {
         // Clean up if animations never started.
         const context = getContext(element);
         if (context && context.properties.size == 0) {
            if (!ignoreHeight) element.style.height = "";
            if (!ignoreWidth) element.style.width = "";
            setContext(element, null);
            if (onTransitionEnd) onTransitionEnd;
         }
      });
   });
}

function getContext(element: Element): TransitionSizeContext | null {
   return ko.utils.domData.get(element, TRANSITION_CONTEXT) as TransitionSizeContext;
}

function setContext(element: Element, context: TransitionSizeContext | null) {
   ko.utils.domData.set(element, TRANSITION_CONTEXT, context);
}
