 import { requestContext } from "@/stores/common/request-context";
 import ko from "knockout";
 import $ from "jquery";


 ko.bindingHandlers["timezoneMap"] =
     "init": (element, valueAccessor) ->
         selectedCallback = ko.unwrap(valueAccessor()).selectedCallback or ->
            console.log "Default timezone callback fired."
         zoneName = ko.unwrap(valueAccessor().zoneName) or 'America/Chicago'

         $("#zonepicker").timezonePicker({
            initialLat: 29.718,
            initialLng: -39.955,
            initialZoom: 2,
            jsonRootUrl: requestContext.baseUrl + "/timezone-data/",
            onSelected: selectedCallback
         onReady: () ->
            $("#zonepicker").timezonePicker('selectZone', zoneName)
         , onHover: (utcOffset, tzNames) ->
            $('#label').text(tzNames.join(',') + ': ' + utcOffset + ' minutes')
         , mapOptions: {
            maxZoom: 6,
            minZoom: 2,
            disableDefaultUI: true
            }
         })