import { Dom as DomUtils } from "@/lib/utils/dom"

ko.bindingHandlers["centerElement"] =
   update: (element, valueAccessor) ->
      if ko.unwrap(valueAccessor())
         if DomUtils.elementHasTransition(element)
            $el = $(element)
            scroll = ->
               # Give the DOM a chance to update before scrolling.
               setTimeout ->
                  if element.scrollIntoViewIfNeeded
                     element.scrollIntoViewIfNeeded()
                  else
                     element.scrollIntoView()
               , 0

            transitionEnded = (event) ->
               $el.off("transitionend", transitionEnded)
               scroll()
            $el.on("transitionend", transitionEnded)
         else
            scroll()