DATA_DISPLAY = "opacity-display"

ko.bindingHandlers["opacity"] =
   init: (element, valueAccessor) ->
      ko.utils.domData.set(element, DATA_DISPLAY, $(element).css("display"))
      ko.utils.registerEventHandler element, "transitionend", ->
         value = ko.unwrap(valueAccessor())
         $(element).css("display", "none") unless value

   update: (element, valueAccessor) ->
      value = ko.unwrap(valueAccessor())
      element.style.opacity = 0 if element.style.display == "none"
      if typeof value == "number"
         $(element).show()
         element.style.opacity = value
         return
      isCurrentlyVisible = element.style.opacity != 0
      if value and isCurrentlyVisible
         display = ko.utils.domData.get(element, DATA_DISPLAY)
         $(element).css("display", display)
         # This needs to be here for any animation to actually show.
         setTimeout ->
            element.style.opacity = 1
         , 0
      else if !value and isCurrentlyVisible
         element.style.opacity = 0