import $ from "jquery"
import ko from "knockout"
import { Dom as DomUtils } from "@/lib/utils/dom"

ko.bindingHandlers["popup"] =
   ### eslint-disable no-unused-vars ###
   init: (element, valueAccessor, allBindingsAccessor, viewModel, bindingContext) ->
   ### eslint-enable no-unused-vars ###
      $popupTarget = $(element)
      popupBuilder = ko.unwrap(valueAccessor()).popupBuilder
      popupVm = ko.observable(null)
      options = ko.observable(ko.unwrap(valueAccessor()).options)
      isHoverTriggered = ko.unwrap(valueAccessor()).isHoverTriggered or false
      dynamicPositioning = ko.unwrap(valueAccessor()).dynamicPositioning or false
      positioningContainerClass = ko.unwrap(valueAccessor()).positioningContainerClass or null
      $popup = ko.observable(null)
      showingPopup = ko.observable(false)
      showPopup = (data, dynamicClasses) =>
         popup = popupBuilder(data)
         return unless popup?
         if dynamicClasses?
            popup.frameType(dynamicClasses.frameType) if dynamicClasses.frameType?
            if dynamicClasses.arrowLocation?
               popup.arrowLocationClass("popup-arrow-container--#{dynamicClasses.arrowLocation}")

            if dynamicClasses.popupClass?
               popup.popupClasses("#{popup.popupClasses()} popup-dynamic-pos__#{dynamicClasses.popupClass}")
         popupVm(popup)
         popupVm().initialize(togglePopup)
         showingPopup(true)
         content = DomUtils.getTemplateString(popupVm().template())
         ### eslint-disable no-unused-vars ###
         appending = $popupTarget.append(content)
         ### eslint-enable no-unused-vars ###
         $popup($popupTarget.children(".popup"))
         popupVm().isActive(true)
         ko.applyBindings(popupVm, $popup()[0])

      hidePopup = =>
         return unless $popup()?
         popupVm().isActive(false)
         $popup().remove()
         popupVm().callback(null, 'canceled') if popupVm().callback?
         $popup(null)
         showingPopup(false)

      togglePopup = (data, dynamicClasses) =>
         if !showingPopup() and popupBuilder?
            showPopup(data, dynamicClasses)
         else
            hidePopup()

      if !isHoverTriggered
         $popupTarget.keyup (event) =>
            if event.key == " "
               event.preventDefault()

         $popupTarget.click (event) =>
            dynamicClasses = null
            unless showingPopup()
               event.stopImmediatePropagation()

               if dynamicPositioning
                  if positioningContainerClass
                     $containingElement = $(".#{positioningContainerClass}")
                     containingWidth = $containingElement.width()
                     containingHeight = $containingElement.height()
                     $target = $(event.target)
                     targetTop = $target.position().top
                     targetLeft = $target.position().left
                     top = targetTop <= (containingHeight / 2)
                     left = targetLeft <= (containingWidth / 2)
                     window.tar = event.target
                  else
                     $containingElement = $('.page-container')
                     containingWidth = $containingElement.width()
                     containingHeight = $containingElement.height()
                     top = event.pageY <= (containingHeight / 2)
                     left = event.pageX <= (containingWidth / 2)

                  if top and left
                     frameType = "below"
                     arrowLocation = "top-left"
                     popupClass = "bottom-left"
                  else if top and !left
                     frameType = "below"
                     arrowLocation = "top-left"
                     popupClass = "bottom-right"
                  else if !top and left
                     frameType = "above"
                     arrowLocation = "bottom-left"
                     popupClass = "top-left"
                  else if !top and !left
                     frameType = "above"
                     arrowLocation = "bottom-left"
                     popupClass = "top-right"

                  dynamicClasses = {
                     frameType: frameType
                     arrowLocation: arrowLocation
                     popupClass: popupClass
                  }

            allowClick = (event.target == $popupTarget[0])
            allowClick = $.contains($popupTarget[0], event.target) unless allowClick or showingPopup()

            if !allowClick and options()?.allowClickDefaultClasses?
               for name in event.target.classList
                  return if options().allowClickDefaultClasses.indexOf(name) != -1

            unless allowClick or !options().triggerClasses?
               for name in event.target.classList
                  if (options().triggerClasses.indexOf(name) != -1)
                     allowClick = true
                     break
            return unless allowClick

            data = ko.dataFor(event.target)
            data = if data? then data else null
            togglePopup(data, dynamicClasses)
      else
         $popupTarget.hover (event) =>
            dynamicClasses = null
            if dynamicPositioning
               $pageContainer = $('.page-container')
               pageWidth = $pageContainer.width()
               pageHeight = $pageContainer.height()
               top = event.pageY <= (pageHeight / 2)
               left = event.pageX <= (pageWidth / 2)
               if top and left
                     frameType = "below"
                     arrowLocation = "top-left"
                     popupClass = "bottom-left"
                  else if top and !left
                     frameType = "below"
                     arrowLocation = "top-right"
                     popupClass = "bottom-right"
                  else if !top and left
                     frameType = "above"
                     arrowLocation = "bottom-left"
                     popupClass = "top-left"
                  else if !top and !left
                     frameType = "above"
                     arrowLocation = "bottom-right"
                     popupClass = "top-right"

                  dynamicClasses = {
                     frameType: frameType
                     arrowLocation: arrowLocation
                     popupClass: popupClass
                  }

            hoveringElement = event.target
            data = ko.dataFor(hoveringElement)
            return showPopup(data, dynamicClasses)
         ### eslint-disable no-unused-vars ###
         , (event) =>
         ### eslint-enable no-unused-vars ###
            return hidePopup()

