ko.bindingHandlers["firstVisibleElement"] =
   init: (element, valueAccessor) ->
      $element = $(element)
      elementContainerSelector = ko.unwrap(valueAccessor().elementContainer) || null
      callback = ko.unwrap(valueAccessor().callback)
      offsetPercentage = ko.unwrap(valueAccessor().offsetPercentage) || 0

      handler = () ->
         children = getChildren($element, elementContainerSelector)
         return unless children.length

         for index in [0..children.length - 1]
            child = $(children[index])
            { top, } = child.position()
            if top + child.height() >= $element.height() * offsetPercentage
               callback(index, child)
               return

         callback(children.length - 1, children[children.length - 1])

      $element.on('scroll', handler)
      $element.on('wheel', handler)
      ko.utils.domNodeDisposal.addDisposeCallback(element, -> $element.off("scroll", handler))
      ko.utils.domNodeDisposal.addDisposeCallback(element, -> $element.off("wheel", handler))

getChildren = ($element, selector) ->
   return if selector then $element.find(selector).children() else $element.children()