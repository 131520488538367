import { authManager } from "@/lib/managers/auth-manager"
import { DateUtils } from "@/lib/utils/date"
import { defaultStore } from "@/stores/default-store"
import { Color as ColorUtils } from "@/lib/utils/color"
import { PermissionLevel } from "@/models/permission-level"
import { Format } from "@/lib/utils/format";
import { Guid as GUID } from "@/lib/utils/guid"
import { getDetachedDay, getAttachedDate, incrementDate } from "@laborchart-modules/common/dist/datetime";

import * as d3 from "d3"
import $ from "jquery";
import ko from "knockout"
import LaunchDarklyBrowser from "@laborchart-modules/launch-darkly-browser"

barPadding = 2
barHeight = 15
projectBarHeight = 35
metaBarHeight = 20
metabarPaddingWidth = 10
tagWidth = 28
minimumBarHeight = projectBarHeight

addPadding = (nodeHeight, isPaddable) -> barHeight + nodeHeight + (if isPaddable then barPadding else 0)
updateYAxisSupplier = (yAxis) -> 
   position = yAxis

   return (node) ->
      { height: nodeHeight} = node.getBBox()
      node.setAttribute('transform', "translate(0, #{position})")
      # The y-axis attribute does not affect the location of the element 
      # They are reference only, for popups 
      # and other elements not relative to the location of the page
      node.setAttribute('data-page', position)
      # If the last row is ever considered 'empty' then the row height is the minimumBarHeight
      # If we add padding in this scenario it will cause extra whitespace between projects
      # The code below prevents this from occuring. 
      [lastChild] = Array.from(node.children).slice(-1)
      if lastChild
         { height: lastChildHeight, } = lastChild.getBBox()
         if lastChildHeight == minimumBarHeight
            position += addPadding(nodeHeight, false)
         else
            position += addPadding(nodeHeight, true)
      else
         position += addPadding(nodeHeight, true)

updateAllPagesYaxis = () -> 
   yAxisUpdate = updateYAxisSupplier(0)
   d3.selectAll('.chart > .page').nodes().forEach(yAxisUpdate)

drawGantt = (element, pages, rangeData, appending, callbacks, options) ->
   ENABLE_SHOW_JOB_TITLE_NAME_OPTION = LaunchDarklyBrowser.getFlagValue("gantt-show-job-title-name")

   # Get chart area width
   frameWidth = element.offsetWidth
   frameHeight = $("#project-gantt-chart-wrapper").height()
   infoSecWidth = 200

   projectMorePopupWidth = 180

   allocatedGreen = "029e5d"
   allocatedRed = "b5020e"
   # gs-1-color
   allocationGray = "6A6A6A"

   categoryBarHeight = 28
   projectColorCircleDiameter = 10

   todayDetachedDay = getDetachedDay(new Date())

   chartWidth = frameWidth - infoSecWidth

   rangeStart = rangeData.startDate

   rangeEnd = new Date(rangeData.startDate.getTime())
   rangeEnd.setDate(rangeStart.getDate() + rangeData.days)

   allowExportingData = authManager.checkAuthAction(PermissionLevel.Action.ALLOW_EXPORTING_DATA)
   canViewProjectTags = authManager.checkAuthAction(PermissionLevel.Action.VIEW_PROJECT_TAGS)
   canViewProjectFinancials = authManager.checkAuthAction(PermissionLevel.Action.VIEW_PROJECT_FINANCIALS)
   canViewPeopleTimeoff = authManager.checkAuthAction(PermissionLevel.Action.VIEW_PEOPLE_TIMEOFF)

   # Setup X Axis
   xScale = d3.scaleTime()
   # This needs to be be 1 tick longer so that the labels can be
   # centered and it will still display properly.
   .domain([rangeStart, rangeEnd])
   .range([0, chartWidth])
   .clamp(true)

   # Gets valid temporal values.
   if rangeData.days == 1
      ticks = xScale.ticks(d3.timeHour.every(1))
      gridTicks = xScale.ticks(d3.timeHour.every(1))
   else if rangeData.days < 91
      ticks = xScale.ticks(d3.timeDay.every(1))
      gridTicks = xScale.ticks(d3.timeDay.every(1))
   else if rangeData.days < 182
      ticks = xScale.ticks(d3.timeWeek.every(1))
      gridTicks = xScale.ticks(d3.timeWeek.every(1))
   else if rangeData.days < 730
      ticks = xScale.ticks(d3.timeMonth.every(1))
      gridTicks = xScale.ticks(d3.timeMonth.every(1))
   else
      ticks = xScale.ticks(d3.timeMonth.every(3))
      gridTicks = xScale.ticks(d3.timeMonth.every(1))

   hoursToPixels = (hours) ->
      d1 = new Date(rangeData.startDate.getTime())
      return xScale(d3.timeHour.offset(d1, hours)) - xScale(d1)

   daysToPixels = (days) ->
      d1 = new Date(rangeData.startDate.getTime())
      return xScale(d3.timeDay.offset(d1, days)) - xScale(d1)

   monthsToPixels = (months) ->
      d1 = new Date(rangeData.startDate.getTime())
      return xScale(d3.timeMonth.offset(d1, months)) - xScale(d1)

   unless appending
      resetChart()

      svg = d3.select("#project-gantt-chart")
         .append("svg")
         .attr("class", "wrapper")
         .attr("width", frameWidth)
         .attr("height", frameHeight)
         .attr("overflow", "visible")

      # Add profile pic clip path
      circleClip = svg.append("clipPath")
         .attr("id", "circleClip")
         .attr("clipPathUnits", "objectBoundingBox")

      circleClip.append("circle")
         .attr("r", 0.5)
         .attr("cx", 0.5)
         .attr("cy", 0.5)

      # Add short person name clip path
      shortPersonNameClip = svg.append("clipPath")
         .attr("id", "shortPersonNameClip")

      shortPersonNameClip.append("rect")
         .attr("width", infoSecWidth - 20)
         .attr("height", 18)
         .attr("x", 0)
         .attr("y", 0)

      # Add short person name clip path
      longPersonNameClip = svg.append("clipPath")
         .attr("id", "longPersonNameClip")

      longPersonNameClip.append("rect")
         .attr("width", infoSecWidth - 10)
         .attr("height", 18)
         .attr("x", 0)
         .attr("y", 0)

      # Add short position name clip path
      shortPositionNameClip = svg.append("clipPath")
         .attr("id", "shortPositionNameClip")

      shortPositionNameClip.append("rect")
         .attr("width", 130)
         .attr("height", 18)
         .attr("x", 0)
         .attr("y", 0)

      # Add short position name clip path
      longPositionNameClip = svg.append("clipPath")
         .attr("id", "longPositionNameClip")

      longPositionNameClip.append("rect")
         .attr("width", 170)
         .attr("height", 18)
         .attr("x", 0)
         .attr("y", 0)

      defs = svg.append("defs")
      filter = defs.append("filter")
         .attr("id", "drop-shadow")
         .attr("height", "130%")

      filter.append("feGaussianBlur")
         .attr("in", "SourceAlpha")
         .attr("stdDeviation", 5)

      filter.append("feOffset")
         .attr("in", "blur")
         .attr("dx", 0)
         .attr("dy", 0)

      componentTransfer = filter.append("feComponentTransfer")

      componentTransfer.append("feFuncA")
         .attr("type", "linear")
         .attr("slope","0.6")

      feMerge = filter.append("feMerge")

      feMerge.append("feMergeNode")
         .attr("in", "offsetBlur")
      
      feMerge.append("feMergeNode")
         .attr("in", "SourceGraphic")

      colorWeekends = (selection) ->
         if 1 < rangeData.days < 91
            # Daily
            selection.selectAll('.tick').each (data) ->
               detachedDay = getDetachedDay(data)
               day = data.getDay()
               if day == 0 or day == 6
                  d3.select(@).append("rect")
                     .attr("class", "weekend-bg")
                     .attr("height", frameHeight)
                     .attr("width", daysToPixels(1))
                     .attr("fill", "black")
                     .attr("opacity", "0.2")

               if detachedDay == todayDetachedDay
                  d3.select(@).append("rect")
                     .attr("class", "today-bg")
                     .attr("height", 20)
                     .attr("width", daysToPixels(1))
                     .attr("fill", "blue")
                     .attr("opacity", "0.3")
         else if 91 <= rangeData.days < 182
            # Weekly
            thisWeekStartDate = getAttachedDate(todayDetachedDay)
            thisWeekStartMs = thisWeekStartDate.setDate(thisWeekStartDate.getDate() - thisWeekStartDate.getDay())
            thisWeeksStartingDay = getDetachedDay(new Date(thisWeekStartMs))

            selection.selectAll('.tick').each (data) ->
               # Decouple
               weekStartDate = new Date(data.getTime())
               weekStartMs = weekStartDate.setDate(weekStartDate.getDate() - weekStartDate.getDay())
               weeksStartingDay = getDetachedDay(new Date(weekStartMs))

               if weeksStartingDay == thisWeeksStartingDay
                  d3.select(@).append("rect")
                     .attr("class", "today-bg")
                     .attr("height", 20)
                     .attr("width", daysToPixels(7))
                     .attr("fill", "blue")
                     .attr("opacity", "0.3")
         else if 182 <= rangeData.days
            # Monthly
            thisMonthChunk = String(todayDetachedDay).slice(0, -2)

            selection.selectAll('.tick').each (data) ->
               detachedDay = getDetachedDay(data)
               monthChunk = String(detachedDay).slice(0, -2)

               if monthChunk == thisMonthChunk
                  sectionStart = xScale(data)
               
                  nextTickDate = new Date(data.getTime())
                  nextTickDate.setMonth(nextTickDate.getMonth() + 1)
                  nextTickDate.setDate(1)
                  sectionEnd = xScale(nextTickDate)

                  offset = (sectionEnd - sectionStart)

                  d3.select(@).append("rect")
                     .attr("class", "today-bg")
                     .attr("height", 20)
                     .attr("width", offset)
                     .attr("fill", "orange")
                     .attr("opacity", "0.3")

      # Grid lines
      gridlines = d3.axisTop()
        .tickFormat("")
        .tickSize(-frameHeight)
        .scale(xScale)

      gridlines.tickValues(gridTicks)

      svg.append("g")
        .attr("class", "grid")
         .attr("transform", "translate(#{infoSecWidth},0)")
        .call(gridlines)
        .call(colorWeekends)


      chart = svg.append("g")
         .attr("class", "chart")
         .attr("transform", "translate(0,0)")

   if appending
      # When appending svg & chart have not been instantiated
      svg = d3.select('.wrapper')
      chart = d3.select('.chart')
   
   pagesSelection = chart.selectAll(".page")
   .data pages, (d) -> 
      return d.id

   pagesSelection.exit().remove()

   pagesEnter = pagesSelection.enter()
      .append("g")
      .attr "class", "page"

   pagesEnter.order()

   row = pagesEnter.selectAll(".row")
      .data (d) ->
         return d.rows
      .enter()
      .append("svg")
      .attr("class","row")
      .attr("x", 0)
      # Mult bar + padding for every teir before and add the total row buffer (1 barHeight)
      # for each total row before.
      .attr "y", (d) ->
         # With 1 bar height padding between projects.
         yPadding = d.leadingProjects * ((projectBarHeight + metaBarHeight + barPadding) + barHeight)
         yPadding += d.leadingTotalsBars * (categoryBarHeight + barPadding)
         yPadding += d.leadingCats * (categoryBarHeight + barPadding)
         yPadding += d.leadingSubcats * (categoryBarHeight + barPadding)
         yPadding += d.leadingAssBars * (barHeight + barPadding)
         yPadding += d.leadingRequestsHeaders * (categoryBarHeight + barPadding)
         yPadding += d.leadingRequestCats * (categoryBarHeight + barPadding)
         yPadding += d.leadingRequestSubcats * (categoryBarHeight + barPadding)
         yPadding += d.leadingRequestBars * (barHeight + barPadding)
         return yPadding
      .attr("width", frameWidth)
      .attr "height", (d) -> 
         projectRowHeight = (projectBarHeight + metaBarHeight + barPadding)
         if d.groupedAssignments.length > 0
            projectRowHeight += categoryBarHeight + barPadding

         # TODO: Can't I just use the contained values instead of recounting? 
         for catSet in d.groupedAssignments
            projectRowHeight += (categoryBarHeight + barPadding) if catSet.categoryName?
            for subcatSet in catSet.subcategories
               projectRowHeight += (categoryBarHeight + barPadding) if subcatSet.subcategoryName?
               projectRowHeight += subcatSet.containedAssBars * (barHeight + barPadding)
         if d.groupedRequests.length > 0
            # Requests header & total bar
            projectRowHeight += categoryBarHeight + barPadding

         projectRowHeight += d.containedRequestCats * (categoryBarHeight + barPadding)
         projectRowHeight += d.containedRequestSubcats * (categoryBarHeight + barPadding)
         projectRowHeight += d.containedRequestBars * (barHeight + barPadding)

         return projectRowHeight

   projectBar = row.append("g")
      .attr("transform", "translate(0, 0)")

   projectBar.append("rect")
      .attr("class", "project-gantt__project-title-bar")
      .attr("width", "100%")
      .attr("height", projectBarHeight)

   projectBar.append("circle")
      .attr("class", "project-gantt__project-title-bar-color")
      .attr("fill", (d) -> return d.color)
      .attr("width", (d) -> return if d.color then projectColorCircleDiameter else 0)
      .attr("height", (d) -> return if d.color then projectColorCircleDiameter else 0)
      .attr("cx", 16)
      .attr("cy", projectBarHeight / 2)
      .attr("r", 5)
   
   projectBar.append("text")
      .attr("class", "project-gantt__project-bar-title")
      .text (d) ->
         title = ""
         if rangeData.projectViewConfig.showJobNumbers and d.jobNumber?
            title += "[ #{d.jobNumber} ] "
         title += d.projectName
         if rangeData.projectViewConfig.showStatus
            status = d.projectStatus
            title += " (#{status.charAt(0).toUpperCase()}#{status.slice(1, status.length)})"
         return title
      .attr("font-family", "OpenSans")
      .attr("font-size", "16px")
      .attr("fill", "white")
      .attr "x", (d) ->
         if d.color?
            return 10 + projectColorCircleDiameter + 10
         return 10
      .attr("y", 14 + ((projectBarHeight - 16) / 2))
      .on "click", (d) ->
            callbacks.infoSectionClicked(d, d3.event)

   if options.permissions.canViewProject or options.permissions.canManageRequests or options.permissions.canManageAssignments
      projectBarMoreBtn = projectBar.append("g")
         .attr("transform", "translate(#{frameWidth - 39},0)")
         .attr("class", "project-gantt__project-bar__more-btn")

      drawProjectMorePopup = (d, parentPage) ->
         projectHasStarted = new Date().getTime() >= new Date(d.startDate).getTime()
         canShiftProject = (d.projectStatus == "active" or d.projectStatus == "pending") and
            !projectHasStarted and
            options.permissions.canEditProjectDetails and
            options.permissions.canManageAssignments and
            options.permissions.canManageRequests
         canCreateLaborPlan = options.permissions.canManageRequests
         height = 0
         height += 26 if options.permissions.canViewProject
         height += 26 if canShiftProject
         if d.projectStatus == "active"
            height += 26 if options.permissions.canManageAssignments
            height += 26 if options.permissions.canManageRequests
            height += 26 if options.permissions.canCreateMessages
            height += 26 if options.permissions.canManageAlerts
            height += 26 if allowExportingData
            height += 26 if canCreateLaborPlan
         else if d.projectStatus == "pending"
            height += 26 if options.permissions.canManageRequests
         # else if d.projectStatus == "inactive"
            # height = 26

         moreOptRows = 0
         
         openAbove = false
         popup = chart.append("g")
            .attr("class", "project-gantt__more-popup")
            .attr("width", projectMorePopupWidth)
            .attr("height", height)
            .attr("fill", "black")
            .attr "transform", ->
               pageYAxis = parseInt(parentPage.getAttribute('data-page'))
               yPadding = pageYAxis
               yPadding += d.leadingProjects * ((projectBarHeight + metaBarHeight + barPadding) + barHeight)
               yPadding += d.leadingTotalsBars * (categoryBarHeight + barPadding)
               yPadding += d.leadingCats * (categoryBarHeight + barPadding)
               yPadding += d.leadingSubcats * (categoryBarHeight + barPadding)
               yPadding += d.leadingAssBars * (barHeight + barPadding)
               yPadding += d.leadingRequestsHeaders * (categoryBarHeight + barPadding)
               yPadding += d.leadingRequestCats * (categoryBarHeight + barPadding)
               yPadding += d.leadingRequestSubcats * (categoryBarHeight + barPadding)
               yPadding += d.leadingRequestBars * (barHeight + barPadding)
               yPadding += projectBarHeight
               console.info({
                  yPadding,
                  height,
                  chartHeight,
                  parentPageChildren: parentPage.children.length
               })
               # Adding +200 of extra padding to make sure nobody's menus are getting cut off at the bottom due to weird edge-cases
               openAbove = true if (yPadding + height + 200 > chartHeight) and parentPage.children.length > 1
               return "translate(#{frameWidth - (projectMorePopupWidth + 18)}, #{yPadding - height - projectBarHeight})" if openAbove
               return "translate(#{frameWidth - (projectMorePopupWidth + 18)}, #{yPadding})"
         
         if openAbove
            popup.append("polygon")
            .attr("class", "project-gantt__more-popup-bg")
               .attr("points", "0,0 #{projectMorePopupWidth},0 #{projectMorePopupWidth},#{height} #{projectMorePopupWidth - 10},#{height + 10} #{projectMorePopupWidth - 20},#{height} 0,#{height}")
               .attr("stroke", "black")
               .style("filter", "url(#drop-shadow)")
         else
            popup.append("polygon")
            .attr("class", "project-gantt__more-popup-bg")
               .attr("points", "0,0 #{projectMorePopupWidth - 20},0 #{projectMorePopupWidth - 10},-10, #{projectMorePopupWidth},0 #{projectMorePopupWidth},#{height} 0,#{height}")
               .attr("fill", "lightgray")
               .attr("stroke", "black")
               .style("filter", "url(#drop-shadow)")

         if options.permissions.canViewProject
            # Prioject Details
            popup.append("text")
               .attr("class", "project-gantt__more-popup__row-text")
               .text("Project Details")
               .attr("font-family", "OpenSans")
               .attr("font-size", "12px")
               .attr("transform", "translate( 15,#{18 + (moreOptRows * 26)})")

            popup.append("rect")
               .attr("class", "project-gantt__more-popup__row")
               .attr("width", projectMorePopupWidth)
               .attr("height", "26")
               .attr("transform", "translate(0,#{moreOptRows * 26})")
               .attr("fill", "lightgray")
               .on "click", ->
                  callbacks.navigateToProject(d.projectId)
            moreOptRows++

            popup.append("line")
               .attr("class", "project-gantt__more-popup__row-delimiter")
               .attr("x1", "0")
               .attr("y1", "#{moreOptRows * 26}")
               .attr("x2", projectMorePopupWidth)
               .attr("y2", "#{moreOptRows * 26}")
               .attr("stroke", "black")

         if canCreateLaborPlan
            # Labor Plans
            popup.append("text")
               .attr("class", "project-gantt__more-popup__row-text")
               .text("Create Labor Plan")
               .attr("font-family", "OpenSans")
               .attr("font-size", "12px")
               .attr("transform", "translate(15,#{18 + (moreOptRows * 26)})")

            popup.append("rect")
               .attr("class", "project-gantt__more-popup__row")
               .attr("width", projectMorePopupWidth)
               .attr("height", "26")
               .attr("transform", "translate(0,#{moreOptRows * 26})")
               .attr("fill", "lightgray")
               .on "click", ->
                  callbacks.openLaborPlansPage(d)
            moreOptRows++

            popup.append("line")
               .attr("class", "project-gantt__more-popup__row-delimiter")
               .attr("x1", "0")
               .attr("y1", "#{moreOptRows * 26}")
               .attr("x2", projectMorePopupWidth)
               .attr("y2", "#{moreOptRows * 26}")
               .attr("stroke", "black")
         if canShiftProject
            # Shift Project
            popup.append("text")
               .attr("class", "project-gantt__more-popup__row-text")
               .text("Shift Project")
               .attr("font-family", "OpenSans")
               .attr("font-size", "12px")
               .attr("transform", "translate(15,#{18 + (moreOptRows * 26)})")

            popup.append("rect")
               .attr("class", "project-gantt__more-popup__row")
               .attr("width", projectMorePopupWidth)
               .attr("height", "26")
               .attr("transform", "translate(0,#{moreOptRows * 26})")
               .attr("fill", "lightgray")
               .on "click", ->
                  callbacks.showShiftProjectModal(d.projectId, d.startDate, d.projectName)
            moreOptRows++

            popup.append("line")
               .attr("class", "project-gantt__more-popup__row-delimiter")
               .attr("x1", "0")
               .attr("y1", "#{moreOptRows * 26}")
               .attr("x2", projectMorePopupWidth)
               .attr("y2", "#{moreOptRows * 26}")
               .attr("stroke", "black")

         if (d.projectStatus == "active" or d.projectStatus == "pending") and options.permissions.canManageRequests
            # New Requests
            popup.append("text")
               .attr("class", "project-gantt__more-popup__row-text")
               .text("New Request")
               .attr("font-family", "OpenSans")
               .attr("font-size", "12px")
               .attr("transform", "translate(15,#{18 + (moreOptRows * 26)})")

            popup.append("rect")
               .attr("class", "project-gantt__more-popup__row")
               .attr("width", projectMorePopupWidth)
               .attr("height", "26")
               .attr("transform", "translate(0,#{moreOptRows * 26})")
               .attr("fill", "lightgray")
               .on "click", ->
                  callbacks.showNewPlaceholderModal(d.projectId)
            moreOptRows++

            popup.append("line")
               .attr("class", "project-gantt__more-popup__row-delimiter")
               .attr("x1", "0")
               .attr("y1", "#{moreOptRows * 26}")
               .attr("x2", projectMorePopupWidth)
               .attr("y2", "#{moreOptRows * 26}")
               .attr("stroke", "black")


         if d.projectStatus == "active" and options.permissions.canManageAssignments
            # New Assignment
            popup.append("text")
               .attr("class", "project-gantt__more-popup__row-text")
               .text("New Assignment")
               .attr("font-family", "OpenSans")
               .attr("font-size", "12px")
               .attr("transform", "translate(15,#{18 + (moreOptRows * 26)})")

            popup.append("rect")
               .attr("class", "project-gantt__more-popup__row")
               .attr("width", projectMorePopupWidth)
               .attr("height", "26")
               .attr("transform", "translate(0,#{moreOptRows * 26})")
               .attr("fill", "lightgray")
               .on "click", ->
                  callbacks.showNewAssignmentModal(d.projectId)
            moreOptRows++

            popup.append("line")
               .attr("class", "project-gantt__more-popup__row-delimiter")
               .attr("x1", "0")
               .attr("y1", "#{moreOptRows * 26}")
               .attr("x2", projectMorePopupWidth)
               .attr("y2", "#{moreOptRows * 26}")
               .attr("stroke", "black")

         if d.projectStatus == "active" and options.permissions.canManageAlerts
            # Sned Assignment Alerts
            popup.append("text")
               .attr("class", "project-gantt__more-popup__row-text")
               .text("Send Assignment Alerts")
               .attr("font-family", "OpenSans")
               .attr("font-size", "12px")
               .attr("transform", "translate(15,#{18 + (moreOptRows * 26)})")

            popup.append("rect")
               .attr("class", "project-gantt__more-popup__row")
               .attr("width", projectMorePopupWidth)
               .attr("height", "26")
               .attr("transform", "translate(0,#{moreOptRows * 26})")
               .attr("fill", "lightgray")
               .on "click", ->
                  callbacks.sendAssignmentAlerts(d.projectId)
            moreOptRows++

            popup.append("line")
               .attr("class", "project-gantt__more-popup__row-delimiter")
               .attr("x1", "0")
               .attr("y1", "#{moreOptRows * 26}")
               .attr("x2", projectMorePopupWidth)
               .attr("y2", "#{moreOptRows * 26}")
               .attr("stroke", "black")

         if d.projectStatus == "active" and options.permissions.canCreateMessages
            # Message People
            popup.append("text")
               .attr("class", "project-gantt__more-popup__row-text")
               .text("Message People")
               .attr("font-family", "OpenSans")
               .attr("font-size", "12px")
               .attr("transform", "translate(15,#{18 + (moreOptRows * 26)})")

            popup.append("rect")
               .attr("class", "project-gantt__more-popup__row")
               .attr("width", projectMorePopupWidth)
               .attr("height", "26")
               .attr("transform", "translate(0,#{moreOptRows * 26})")
               .attr("fill", "lightgray")
               .on "click", ->
                  callbacks.messagePeople(d.projectId)
            if allowExportingData
               moreOptRows++
               popup.append("line")
                  .attr("class", "project-gantt__more-popup__row-delimiter")
                  .attr("x1", "0")
                  .attr("y1", "#{moreOptRows * 26}")
                  .attr("x2", projectMorePopupWidth)
                  .attr("y2", "#{moreOptRows * 26}")
                  .attr("stroke", "black")

               # Generate Report for this Project
               popup.append("text")
                  .attr("class", "project-gantt__more-popup__row-text")
                  .text("Export Report")
                  .attr("font-family", "OpenSans")
                  .attr("font-size", "12px")
                  .attr("transform", "translate(15,#{18 + (moreOptRows * 26)})")

               popup.append("rect")
                  .attr("class", "project-gantt__more-popup__row")
                  .attr("width", projectMorePopupWidth)
                  .attr("height", "26")
                  .attr("transform", "translate(0,#{moreOptRows * 26})")
                  .attr("fill", "lightgray")
                  .on "click", ->
                     callbacks.showGenerateProjectReportModal(d)
         
         allowedPopupClasses = [
            'project-gantt__project-bar__more-btn'
            'project-gantt__project-bar__more-btn-bg'
            'project-gantt__project-bar__more-btn-dot'
            'project-gantt__more-popup__row-delimiter'
         ]

         clickHandler = (data) ->
            if data.toElement?
               clickedElement = data.toElement
            else if data.target?
               clickedElement = data.target

            foundMatch = false
            for name in clickedElement.classList
               foundMatch = true if allowedPopupClasses.indexOf(name) != -1

            unless foundMatch
               document.removeEventListener("click", clickHandler)
               svg.selectAll(".project-gantt__more-popup").remove()

         document.addEventListener("click", clickHandler)

      projectBarMoreBtn.append("rect")
         .attr("class", "project-gantt__project-bar__more-btn-bg")
         .attr("width", "24")
         .attr("height", projectBarHeight)
         .on "click", (d) ->
            drawProjectMorePopup(d, row.node().parentNode, d3.event)
      
      # draw 3 circles
      moreCirclePosition = 3
      incrementPopupBy = 9
      [0...3].forEach () ->
         projectBarMoreBtn.append("circle")
            .attr("class", "project-gantt__project-bar__more-btn-dot")
            .attr("cx", moreCirclePosition)
            .attr("cy", projectBarHeight / 2)
            .attr("r", 3)
            .attr("fill", "white")
            .on "click", (d) ->
               drawProjectMorePopup(d, row.node().parentNode, d3.event)
         moreCirclePosition += incrementPopupBy

   getElementWidth = (d) ->
      # Need Times for single day.
      if rangeData.days == 1
         d.start = new Date(rangeData.startDate.getTime())
         d.end = new Date(rangeData.startDate.getTime())

         startTimeChunks = String(d.startTime).split(".")
         d.start.setHours(startTimeChunks[0])
         if startTimeChunks[1]?
            # Catches "5"
            startTimeChunks[1] = "#{startTimeChunks[1]}0" if startTimeChunks[1].length == 1
            d.start.setMinutes(60 * (Number(startTimeChunks[1]) / 100))

         if d.endTime > d.startTime
            endTimeChunks = String(d.endTime).split(".")
            d.end.setHours(endTimeChunks[0])
            if endTimeChunks[1]?
               endTimeChunks[1] = "#{endTimeChunks[1]}0" if endTimeChunks[1].length == 1
               d.end.setMinutes(60 * (Number(endTimeChunks[1]) / 100))
         else
            # Overnight
            # Night Before
            if d.singleDay? and d.singleDay < getDetachedDay(rangeData.startDate)
               d.start.setHours(0)
               d.start.setMinutes(0)
               endTimeChunks = String(d.endTime).split(".")
               d.end.setHours(endTimeChunks[0])
               if endTimeChunks[1]?
                  endTimeChunks[1] = "#{endTimeChunks[1]}0" if endTimeChunks[1].length == 1
                  d.end.setMinutes(60 * (Number(endTimeChunks[1]) / 100))
            else
               d.end.setHours(39)
               d.end.setMinutes(59)

      else
         # Set start/end back
         d.start = getAttachedDate(d.startDay)
         d.end = getAttachedDate(d.endDay + 1)

      return xScale(d.end) - xScale(d.start)

   getXDisplacement = (d) ->
      return xScale(d.start)

   # Calculate the starting X position for a metabar cell (previous delimiter + padding)
   calculateTextXPosition = (parent) ->
      x = Array.from(parent.selectAll('.project-gantt__metabar-project-row-delimiter').nodes()).slice(-1)[0].x1.baseVal.value
      return x + metabarPaddingWidth

   # Calculate the ending X position for a metabar cell (previous delimiter + padding + cell text width + padding)
   calculateDelimiterXPosition = (parent, cellClass) ->
      children = parent.select(cellClass)
      textWidth = children.node().getComputedTextLength()
      x = Array.from(parent.selectAll('.project-gantt__metabar-project-row-delimiter').nodes()).slice(-2)[0].x1.baseVal.value
      return x + textWidth + (metabarPaddingWidth * 2)

   mapTagInstancesWithPosition = (tagInstances) ->
      position = 0
      return tagInstances.map (tagInstance) ->
         tagInstance.position = position
         position += 1
         tagInstance

   # Add a row with project metadata
   projectMetaBar = row.append("g")
      .attr("class", "project-gantt__metabar")
      .attr "transform", () ->
         return "translate(0,#{projectBarHeight})"

   projectMetaBar.append("rect")
      .attr("class", "project-gantt_metabar-line")
      .attr("width", "100%")
      .attr("height", metaBarHeight)
      .attr("fill", "white")
      .attr("x", 0)
      .attr("y", 0)

   # Add metadata cell delimiter
   projectMetaBar.append("line")
      .attr("class", "project-gantt__metabar-project-row-delimiter")
      .attr("x1", 0)
      .attr("x2", 0)
      .attr("y1", 0)
      .attr("y2", 20)

   # Add project number text cell to metadata
   projectMetaBar.append("text")
      .filter (d) -> return d.jobNumber?
      .attr("class", "project-gantt__metabar-project-number-cell")
      .text (d) ->
         return  "Project Number: #{d.jobNumber}"
      .attr("font-family", "OpenSans")
      .attr("font-size", "12px")
      .attr("height", metaBarHeight)
      .attr("x", 10)
      .attr("y", 15)

   # Add metadata cell delimiter
   projectMetaBar.append("line")
      .filter (d) -> return d.jobNumber?
      .attr("class", "project-gantt__metabar-project-row-delimiter")
      .attr "x1", () ->
         calculateDelimiterXPosition(d3.select(@.parentNode), ".project-gantt__metabar-project-number-cell")
      .attr "x2", () ->
         calculateDelimiterXPosition(d3.select(@.parentNode), ".project-gantt__metabar-project-number-cell")
      .attr("y1", 0)
      .attr("y2", 20)

   # Add project tags cell to metadata
   tagCell = projectMetaBar.selectAll("project-gantt_metabar-line")
      .data((d) -> mapTagInstancesWithPosition(d.tagInstances))
      .enter().append("g")
      .attr("class", "project-gantt__metabar-tag")
      .attr("transform", "translate(0,0)")
      .attr "x", (d) ->
         parent = d3.select(@.parentNode)
         x = Array.from(parent.selectAll('.project-gantt__metabar-project-row-delimiter').nodes()).slice(-1)[0].x1.baseVal.value
         return (x + metabarPaddingWidth + ((5 + tagWidth) * d.position))
      .attr("y", 0)

   # Add project tags background color rectangle to metadata
   tagCell.insert("rect")
      .attr("class", "project-gantt__tag-bg")
      .attr("height", 10)
      .attr "width", () ->
         return tagWidth
      .attr "fill", (d) ->
         return d.color
      .attr("stroke", "black")
      .attr "x", () ->
         parent = d3.select(@.parentNode)
         x = parent.node().getAttribute("x")
         return x
      .attr("y", 5)

   # Add project tags text to metadata
   tagCell.append("text")
      .attr("class", "project-gantt__metabar-tag-title")
      .text (d) ->
         return d.abbreviation
      .attr("font-family", "OpenSans")
      .attr("font-size", "8px")
      .attr("line-height", "10px")
      .attr("height", metaBarHeight)
      .attr "fill", (d) ->
         return ColorUtils.getVisibleTextColor(d.color)
      .attr "x", () ->
         parent = d3.select(@.parentNode)
         x = parseInt(parent.node().getAttribute("x"))
         textWidth = d3.select(@).node().getComputedTextLength()
         return x + ((tagWidth - textWidth)/2)
      .attr("y", 13)

   # Add metadata cell delimiter
   projectMetaBar.append("line")
      .filter (d) -> return canViewProjectTags && d.tagInstances.length != 0
      .attr("class", "project-gantt__metabar-project-row-delimiter")
      .attr "x1", () ->
         parent = d3.select(@.parentNode)
         x = parseInt(Array.from(parent.selectAll('.project-gantt__metabar-tag').nodes()).slice(-1)[0].getAttribute("x"), 10)
         return x + tagWidth + metabarPaddingWidth
      .attr "x2", () ->
         parent = d3.select(@.parentNode)
         x = parseInt(Array.from(parent.selectAll('.project-gantt__metabar-tag').nodes()).slice(-1)[0].getAttribute("x"), 10)
         return x + tagWidth + metabarPaddingWidth
      .attr("y1", 0)
      .attr("y2", 20)

   # Add project times text cell to metadata
   projectMetaBar.append("text")
      .attr("class", "project-gantt__metabar-project-times-cell")
      .text (d) ->
         # if truthy, this indicates that it came from postgres and is in the format "HH:MM:SS"
         if String(d.dailyStartTime).includes(":")
            parseTime = d3.timeParse("%H:%M:%S")
            postgresTimeFormat = d3.timeFormat("%_I:%M %p")
            formattedStartTime = postgresTimeFormat(parseTime(d.dailyStartTime)).toLowerCase() # using toLowerCase so AM/PM -> am/pm to be consistent with previous version
            formattedEndTime = postgresTimeFormat(parseTime(d.dailyEndTime)).toLowerCase()
            return "#{formattedStartTime} - #{formattedEndTime}"
         else
            formattedStartTime = DateUtils.formatTimeVal(d.dailyStartTime)
            formattedEndTime = DateUtils.formatTimeVal(d.dailyEndTime)
            return "#{formattedStartTime} - #{formattedEndTime}"
      .attr("font-family", "OpenSans")
      .attr("font-size", "12px")
      .attr("height", metaBarHeight)
      .attr "x", () ->
         calculateTextXPosition(d3.select(@.parentNode))
      .attr("y", 15)

   # Add metadata cell delimiter
   projectMetaBar.append("line")
      .attr("class", "project-gantt__metabar-project-row-delimiter")
      .attr "x1", () ->
         calculateDelimiterXPosition(d3.select(@.parentNode), ".project-gantt__metabar-project-times-cell")
      .attr "x2", () ->
         calculateDelimiterXPosition(d3.select(@.parentNode), ".project-gantt__metabar-project-times-cell")
      .attr("y1", 0)
      .attr("y2", 20)

   # Add project bid rate text cell to metadata
    projectMetaBar.append("text")
      .filter (d) -> return canViewProjectFinancials && d.bidRate?
      .attr("class", "project-gantt__metabar-project-bid-rate-cell")
      .text (d) ->
         return "Estimated Avg. Rate: #{Format.formatCurrency(d.bidRate)}/hr"
      .attr("font-family", "OpenSans")
      .attr("font-size", "12px")
      .attr("height", metaBarHeight)
      .attr "x", () ->
         calculateTextXPosition(d3.select(@.parentNode))
      .attr("y", 15)

   # Add metadata cell delimiter
   projectMetaBar.append("line")
      .filter (d) -> return canViewProjectFinancials && d.bidRate?
      .attr("class", "project-gantt__metabar-project-row-delimiter")
      .attr "x1", () ->
         calculateDelimiterXPosition(d3.select(@.parentNode), ".project-gantt__metabar-project-bid-rate-cell")
      .attr "x2", () ->
         calculateDelimiterXPosition(d3.select(@.parentNode), ".project-gantt__metabar-project-bid-rate-cell")
      .attr("y1", 0)
      .attr("y2", 20)

   # Add project wage override text cell to metadata
   projectMetaBar.append("text")
      .filter (d) -> return canViewProjectFinancials && d.wageOverrides.length != 0
      .attr("class", "project-gantt__metabar-project-wage-override-cell")
      .text () ->
         return "Wage Override On"
      .attr("font-family", "OpenSans")
      .attr("font-size", "12px")
      .attr("height", metaBarHeight)
      .attr "x", () ->
         calculateTextXPosition(d3.select(@.parentNode))
      .attr("y", 15)

   # Add metadata cell delimiter
   projectMetaBar.append("line")
      .filter (d) -> return canViewProjectFinancials && d.wageOverrides.length != 0
      .attr("class", "project-gantt__metabar-project-row-delimiter")
      .attr "x1", () ->
         calculateDelimiterXPosition(d3.select(@.parentNode), ".project-gantt__metabar-project-wage-override-cell")
      .attr "x2", () ->
         calculateDelimiterXPosition(d3.select(@.parentNode), ".project-gantt__metabar-project-wage-override-cell")
      .attr("y1", 0)
      .attr("y2", 20)

   # Add bottom border to metadata row
   metaBorder = row.append("g")
      .attr("class", "project-gantt__metabar")
      .attr "transform", () ->
         return "translate(0,#{projectBarHeight + metaBarHeight})"
   
   metaBorder.append("rect")
      .attr("class", "project-gantt_metabar-border")
      .attr("width", "100%")
      .attr("height", 1)
      .attr("x", 0)
      .attr("y", 0)

   getAssignmentChunks = (inputAssignments) ->
      viewEndDate = incrementDate(rangeData.startDate, rangeData.days)
      assignments = inputAssignments.filter((assignment) ->
         assignmentStart = if typeof assignment.start == "string" then new Date(assignment.start) else assignment.start
         assignmentEnd = if typeof assignment.end == "string" then new Date(assignment.end) else assignment.end
         return assignmentStart < viewEndDate && assignmentEnd >= rangeData.startDate
      )
      return [] unless assignments? and assignments.length > 0

      if rangeData.projectViewConfig.barSplit == "split"
         if rangeData.days == 1
            chunks = []
            singleDay = getDetachedDay(rangeStart)
            singleWeekDay = rangeStart.getDay()

            for ass in assignments
               workDays = ass.workDays
               startDay = Number(ass.startDay)
               endDay = Number(ass.endDay)
               chunkStart = getAttachedDate(singleDay)
               chunkEnd = getAttachedDate(singleDay + 1)

               continue unless chunkStart < viewEndDate && chunkEnd >= rangeData.startDate
               continue unless workDays[singleWeekDay]
               continue unless startDay <= singleDay <= endDay

               chunk = {
                  # TODO: Update the startDay & endDay to be actual.
                  startDay: singleDay
                  start: chunkStart
                  endDay: singleDay
                  # Adding for gannt calcualtion
                  end: chunkEnd

                  projectColor: ass.projectColor
                  batchStartDay: ass.batchStartDay
                  batchEndDay: ass.batchEndDay
                  singleDay: ass.singleDay or null
                  groupIds: ass.groupIds
                  personGroupIds: ass.personGroupIds
                  personAssignableGroupIds: ass.personAssignableGroupIds
                  projectName: ass.projectName
                  jobNumber: ass.jobNumber
                  projectId: ass.projectId
                  projectStatus: ass.projectStatus
                  costCodeName: ass.costCodeName
                  costCodeId: ass.costCodeId
                  labelName: ass.labelName
                  labelId: ass.labelId
                  startTime: ass.startTime
                  endTime: ass.endTime
                  workDays: ass.workDays
                  resourceId: ass.resourceId
                  instanceId: null
                  batchId: ass.batchId
                  positionColor: ass.positionColor
                  positionName: ass.positionName
                  overtime: ass.overtime
                  paySplit: ass.paySplit or null
                  overtimeRates: ass.overtimeRates or null
                  percentAllocated: ass.percentAllocated or null
                  positionRate: ass.positionRate or null
                  leadingAssBars: ass.leadingAssBars
                  leadingRequestsBars: ass.leadingRequestsBars
                  status: ass.status
                  statusId: ass.statusId
                  statusName: ass.statusName
               }
               
               if ass.instructionText?
                  chunk['instructionText'] = ass.instructionText or null
               if ass.workScopeText?
                  chunk['workScopeText'] = ass.workScopeText or null

               chunks.push(chunk)

            return chunks

         else
            chunks = []
            
            processingIndex = 0

            processAssignmentsInstances = ->
               workingAssignment = assignments[processingIndex]

               unless workingAssignment?
                  if processingIndex >= assignments.length
                     return chunks
                  else
                     processingIndex++
                     return processAssignmentsInstances()

               workDays = workingAssignment.workDays
               startDay = Number(workingAssignment.startDay)
               endDay = Number(workingAssignment.endDay)

               rangeStartDay = getDetachedDay(rangeStart)
               rangeEndDay = getDetachedDay(rangeEnd)

               if startDay > rangeEndDay
               #    return chunks
                  if processingIndex >= assignments.length
                     return chunks
                  else
                     processingIndex++
                     return processAssignmentsInstances()

               # Don't start pointlessly early
               if startDay < rangeStartDay
                  startDay = rangeStartDay

               processInstance = (processingDate) ->
                  validDetachedDays = []
                  foundInstanceStart = false
                  processingInstance = true
                  processingDay = null

                  while processingInstance
                     processingDay = getDetachedDay(processingDate)

                     working = workDays[processingDate.getDay()]

                     if !working and foundInstanceStart
                        processingInstance = false
                        break
                     else if working and !foundInstanceStart
                        foundInstanceStart = true

                     validDetachedDays.push(processingDay) if working

                     processingDate = incrementDate(processingDate, 1)
                     if processingDay >= endDay
                        processingInstance = false
                        break

                  if (validDetachedDays.length > 0 &&
                     getAttachedDate(validDetachedDays[0]) < viewEndDate &&
                     getAttachedDate(validDetachedDays[validDetachedDays.length - 1]) >= rangeData.startDate)
                        chunk = {
                           startDay: validDetachedDays[0]
                           start: chunkStart
                           endDay: validDetachedDays[validDetachedDays.length - 1]
                           end: chunkEnd

                           projectColor: workingAssignment.projectColor
                           batchStartDay: workingAssignment.batchStartDay
                           batchEndDay: workingAssignment.batchEndDay
                           singleDay: workingAssignment.singleDay or null
                           groupIds: workingAssignment.groupIds
                           personGroupIds: workingAssignment.personGroupIds
                           personAssignableGroupIds: workingAssignment.personAssignableGroupIds
                           projectName: workingAssignment.projectName
                           jobNumber: workingAssignment.jobNumber
                           projectId: workingAssignment.projectId
                           projectStatus: workingAssignment.projectStatus
                           costCodeName: workingAssignment.costCodeName
                           costCodeId: workingAssignment.costCodeId
                           labelName: workingAssignment.labelName
                           labelId: workingAssignment.labelId
                           startTime: workingAssignment.startTime
                           endTime: workingAssignment.endTime
                           workDays: workingAssignment.workDays
                           resourceId: workingAssignment.resourceId
                           instanceId: null
                           batchId: workingAssignment.batchId
                           positionColor: workingAssignment.positionColor
                           positionName: workingAssignment.positionName
                           overtime: workingAssignment.overtime
                           paySplit: workingAssignment.paySplit or null
                           overtimeRates: workingAssignment.overtimeRates or null
                           percentAllocated: workingAssignment.percentAllocated or null
                           positionRate: workingAssignment.positionRate or null
                           leadingAssBars: workingAssignment.leadingAssBars
                           leadingRequestsBars: workingAssignment.leadingRequestsBars
                           status: workingAssignment.status
                           statusId: workingAssignment.statusId
                           statusName: workingAssignment.statusName
                        }
                        
                        if workingAssignment.instructionText?
                           chunk['instructionText'] = workingAssignment.instructionText or null
                        if workingAssignment.workScopeText?
                           chunk['workScopeText'] = workingAssignment.workScopeText or null

                        chunks.push(chunk)

                  detachedProcessingDay = getDetachedDay(processingDate)

                  # if detachedProcessingDay > rangeEndDay
                  #    return chunks
                  # else
                  if detachedProcessingDay > endDay or detachedProcessingDay > rangeEndDay
                  # if detachedProcessingDay > endDay
                     if processingIndex >= assignments.length
                        return chunks
                     else
                        processingIndex++
                        return processAssignmentsInstances()

                  else
                     # May need to put a setTimeout here to prevent stack opverflow on long runs.
                     return processInstance(processingDate)

               processInstance(getAttachedDate(startDay))
               
            processAssignmentsInstances()
      else
         for workingAssignment in assignments
            workingAssignment['start'] = getAttachedDate(Number(workingAssignment.startDay))
            workingAssignment['end'] = getAttachedDate(Number(workingAssignment.endDay))
            workingAssignment['singleDay'] = workingAssignment.singleDay or null
            workingAssignment['instanceId'] = null
            workingAssignment['paySplit'] = workingAssignment.paySplit or null
            workingAssignment['overtimeRates'] = workingAssignment.overtimeRates or null
            workingAssignment['percentAllocated'] = workingAssignment.percentAllocated or null
            workingAssignment['positionRate'] = workingAssignment.positionRate or null
         return assignments

    # Assignments header
   assignmentsHeader = row.append("g")
      .attr("class", "project-gantt__assignments-header")
      .attr "transform", () ->
         return "translate(0,#{projectBarHeight + metaBarHeight})"

   assignmentsHeader.append("rect")
      .attr("class", "project-gantt_assignments-line")
      .attr("width", "100%")
      .attr("height", (d) -> return if d.groupedAssignments.length > 0 then 20 else 0 )
      .attr("fill", "black")
      .attr("x", 0)
      .attr("y", 0)

   assignmentsHeader.append("text")
      .filter (d) -> d.groupedAssignments.length > 0 
      .attr("class", "project-gantt__assignments-title")
      .text () ->
         parentData = d3.select(@.parentNode).datum()
         if 91 <= rangeData.days < 182
            # Weekly
            if rangeData.projectViewConfig.totalsUnit == "people"
               peopleIds = []

               for key, val of parentData.projectsDailyHeads
                  for personId in val.peopleIds
                     peopleIds.push(personId) if peopleIds.indexOf(personId) == -1

               totalsValue = peopleIds.length
            else if rangeData.projectViewConfig.totalsUnit == "cost"
               totalsValue = 0
               for key, val of parentData.projectsDailyCost
                  totalsValue += val.count
            else
               totalsValue = 0
               for key, val of parentData.projectsDailyHours
                  totalsValue += val.count

         else
            # Daily & Months
            if rangeData.projectViewConfig.totalsUnit == "people"
               allPeopleIds = []
               for key, val of parentData.projectsDailyHeads
                  for id in val.peopleIds
                     if allPeopleIds.indexOf(id) == -1
                        allPeopleIds.push(id)
               
               totalsValue = allPeopleIds.length

            else if rangeData.projectViewConfig.totalsUnit == "cost"
               totalsValue = 0
               for key, val of parentData.projectsDailyCost
                  totalsValue += val
            else
               totalsValue = 0
               for key, val of parentData.projectsDailyHours
                  totalsValue += val

         totalsValue = '' if totalsValue == 0

         if rangeData.projectViewConfig.totalsUnit == "man-days" and totalsValue != ''
            # TODO: Update if we change what a man-day means to be configurable.
            # Forcing max 1 decimal.
            totalsValue = Math.round( (totalsValue / 8) * 10) / 10

         if totalsValue != '' and totalsValue != 0
            if totalsValue < 999
               totalsValue = Math.round(totalsValue)
            else if 999 < totalsValue < 9999
               totalsValue = "#{Math.round((totalsValue / 1000) * 10) / 10}K"
            else if 9999 < totalsValue < 99999
               totalsValue = "#{Math.round((totalsValue / 1000) * 10) / 10}K"
            else if 99999 < totalsValue < 999999
               totalsValue = "#{Math.round(((totalsValue / 1000) * 10) / 10)}K"
            else if 999999 < totalsValue
               totalsValue = "#{Math.round((totalsValue / 1000000) * 10) / 10}M"

            if rangeData.projectViewConfig.totalsUnit == "cost"
               totalsValue = "$#{totalsValue}"

            formattedRollupValue = if totalsValue != '' then "(#{totalsValue})" else ''
         else
            formattedRollupValue = ''

         return switch rangeData.projectViewConfig.totalsUnit
            when "people" then "Assignments - People" 
            when "man-days" then "Assignments - Man Days #{formattedRollupValue}"
            when "hours" then "Assignments - Hours #{formattedRollupValue}"
            when "cost" then "Assignments - Cost #{formattedRollupValue}"
         
      .attr("font-family", "OpenSans")
      .attr("font-size", "12px")
      .attr("fill", "black")
      .attr("x", 10)
      .attr("y", 15)

   # Assignment Totals Bar)
   row.append("g")
      .attr("class", "assignment-total-bar")
      .attr("width", chartWidth)
      .attr("height", (d) -> if d.containedAssBars > 0 then barHeight else 0)
      .attr("transform", "translate(0,#{projectBarHeight + metaBarHeight + 1})")

   categorySection = row.selectAll(".row").data((d) -> return d.groupedAssignments)
   .enter().append("g")
   .attr "transform", (d) ->
      # Project bar and total bar since we have assignments.
      yPadding = (projectBarHeight + metaBarHeight + barPadding) + (categoryBarHeight + barPadding)
      yPadding += d.leadingCats * (categoryBarHeight + barPadding)
      yPadding += d.leadingSubcats * (categoryBarHeight + barPadding)
      yPadding += d.leadingAssBars * (barHeight + barPadding)

      return "translate(0,#{yPadding})"

   categorySection.append("text")
      .attr("class", "project-gantt__categry-section-title")
      .text (d) -> 
         return d.categoryName
      .attr("font-family", "OpenSans")
      .attr("font-size", "14px")
      .attr("fill", "black")
      .attr("x", 10)
      .attr("y", categoryBarHeight - 6)

   categorySection.append("rect")
      .attr("class", "project-gantt_category-title-line")
      .attr("rx", 2)
      .attr("ry", 2)
      .attr("width", "100%")
      .attr("height", (d) -> return if d.categoryName? then 2 else 0 )
      .attr("fill", "black")
      .attr("x", 10)
      .attr("y", categoryBarHeight - 2)

   labelSection = categorySection.selectAll(".project-gantt_category-section").data((d) -> 
      return d.subcategories
   ).enter().append("g")
   .attr("class", "project-gantt_subcategory-wrapper")
   .attr "transform", (d) ->
      yPadding = 0
      if d3.select(@.parentNode).datum().categoryName?
         yPadding += (categoryBarHeight + barPadding)

      yPadding += d.leadingSubcats * (categoryBarHeight + barPadding)
      yPadding += d.leadingAssBars * (barHeight + barPadding)

      return "translate(0,#{yPadding})"

   labelTag = labelSection.append("g")
      .attr("class", "project-gantt__subcategory-tag")
      .attr("transform", "translate(0,0)")

   labelTag.append("text")
      .filter (d) -> return d.subcategoryName?
      .attr("class", "project-gantt__subcategory-tag-title")
      .text (d) -> 
         return d.subcategoryName
      .attr("font-family", "OpenSans")
      .attr("font-size", "11px")
      .attr("fill", "black")
      .attr("x", 30)
      .attr("y", categoryBarHeight - 9)

   labelTag.insert("rect", ":first-child")
      .filter (d) -> return d.subcategoryName?
      .attr("class", "project-gantt__subcategory-tag-bg")
      .attr("height", "18")
      .attr "width", () ->
         parent = d3.select(@.parentNode)
         children = parent.select(".project-gantt__subcategory-tag-title")
         textWidth = children.node().getComputedTextLength()
         return textWidth + 20
      .attr("fill", "white")
      .attr("stroke", "black")
      .attr("rx", 4)
      .attr("ry", 4)
      .attr("x", 20)
      .attr("y", 6)

   # People Rows
   personRow = labelSection.selectAll(".project-gantt_subcategory-wrapper")
   .data((d) -> d.peopleAssignments)
   .enter().append("g")
      .attr("class", "project-gantt__person-row-wrapper")
      .attr "transform", (d) ->
         yPadding = 0
         if d3.select(@.parentNode).datum().subcategoryName?
            yPadding += (categoryBarHeight + barPadding)

         yPadding += d.leadingAssBars * (barHeight + barPadding)
         
         return "translate(0,#{yPadding})"

   personRow.each(() ->
      g = d3.select(this) # Select the current group

      tooltip = g.append("g")
         .attr("class", "tooltip")
         .style("display", "none")

      g.on("mouseover", () ->
         tooltip.style("display", "block")
      )
      g.on("mouseout", () ->
         tooltip.style("display", "none")
      )

      tooltipText = tooltip.append("text")
         .text (d) ->
            text = ''
            if options.lastNamesFirst
               text = d.personName.last + ', ' + d.personName.first
            else
               text = d.personName.first + ' ' + d.personName.last
            if ENABLE_SHOW_JOB_TITLE_NAME_OPTION and rangeData.projectViewConfig.showPositionName and d.positionName?
               text += " - #{d.positionName}"
            return text
         .attr("font-family", "OpenSans")
         .attr("font-size", "12px")
         .attr("fill", "white")
         .attr("x", 32)
         .attr("y", -10)
         .on "click", (d) ->
            return unless options.permissions.canViewPeople
            callbacks.navigateToPerson(d.personId)

      tooltip.insert("rect", "text")
         .attr("x", 18)
         .attr("y", -26)
         .attr("width", tooltipText.node().getBBox().width + 28)
         .attr("height", 24)
         .attr("rx", 5)
         .attr("ry", 5)
         .style("fill", "rgb(75, 75, 75)")
   )

   personRow.append("rect")
      .attr("class", "project-gantt_person-row")
      .attr("rx", 2)
      .attr("ry", 2)
      .attr("width", "100%")
      .attr "height", (d) ->
         return d.containedAssBars * (barHeight + barPadding)
      .attr("opacity", 0)
      .attr("x", 0)

   personRow.append("circle")
      .filter (d) -> return d.positionColor?
      .attr("class", "project-gantt__person-row__color-dot")
      .attr("cx", 24)
      .attr("cy", (barHeight / 2) + 1)
      .attr("r", 4)
      .attr "fill", (d) ->
         return d.positionColor

   personRow.append("text")
      .attr("class", "project-gantt__person-row-title")
      .text (d) -> 
         text = ''
         if options.lastNamesFirst
            text = d.personName.last + ', ' + d.personName.first
         else
            text = d.personName.first + ' ' + d.personName.last
         if ENABLE_SHOW_JOB_TITLE_NAME_OPTION and rangeData.projectViewConfig.showPositionName and d.positionName?
            text += " - #{d.positionName}"
         return text
      .attr("font-family", "OpenSans")
      .attr("font-size", "12px")
      .attr("fill", "black")
      .attr "x", (d) -> if d.positionColor? then 32 else 20
      .attr("y", 13)
      .on "click", (d) ->
         return unless options.permissions.canViewPeople
         callbacks.navigateToPerson(d.personId)
      .attr("clip-path", (d) ->
         if d.positionColor?
            return "url(#shortPersonNameClip)"
         else
            return "url(#longPersonNameClip)"
      )

   # Assignment Bars
   assignmentBar = personRow.selectAll("project-gantt__person-row-wrapper").data((d) -> getAssignmentChunks(d.assignments))
   .enter().append("rect")
      .attr "class", (d) ->
         return if d.isPending then "bar bar--pending" else "bar"
      .attr("rx", 2)
      .attr("ry", 2)
      # Get the temporal width and subject padding to make gaps between assignments.
      .attr("width", (d) ->
         if rangeData.days == 182
            return getElementWidth(d) - 2
         else if rangeData.days > 182
            return getElementWidth(d)
         else
            ewidth = getElementWidth(d)
            return ewidth - (barPadding * 2)
      )
      .attr("height", barHeight)
      # Get horizonal displacement and move over by 1 padding to center.
      .attr("x", (d) -> return getXDisplacement(d) + barPadding + infoSecWidth)
      .attr "y", (d) ->
         return d.leadingAssBars * (barHeight + barPadding)
         
      .attr "fill", (d) ->
         if rangeData.projectViewConfig.barColor == "project-color"
            return d.projectColor or "#40464b"
         else if rangeData.projectViewConfig.barColor == "job-titles"
            return d.positionColor or "#40464b"
         else if rangeData.projectViewConfig.barColor == "overtime"
            return if d.overtime then "#ed5858" else "#40cc91"
         else if rangeData.projectViewConfig.barColor == "allocation"
            if d.percentAllocated?
               allocation = d.percentAllocated
            else
               assignmentHours = DateUtils.getDurationHours(d.startTime, d.endTime)
               if assignmentHours >= rangeData.paidShiftHours and !d.overtime
                  allocation = 100
               else
                  allocation = (assignmentHours / rangeData.paidShiftHours) * 100

            if Number(allocation) == 0
               assBarColor = allocationGray
            else if Number(allocation) == 100
               assBarColor = allocatedGreen
            else if Number(allocation) >= 200
               assBarColor = allocatedRed
            else if allocation < 100
               assBarColor = ColorUtils.blendColors("ffffff", allocatedGreen, allocation)
            else
               # between 101 - 199%
               assBarColor = ColorUtils.blendColors("fab9bd", allocatedRed, (allocation % 100))

            return "##{assBarColor}"

         else if rangeData.projectViewConfig.barColor == "status"
            if d.status?
               return d.status.color or "black"
            else  
               return "black"

      .on "click", (d) ->
         d3.select(@)
         callbacks.assignmentClicked(d, d3.event)

      .on "mouseover", () ->
         # Add classes to element.
         thisBar = d3.select(@)
         thisBar.attr("class", "#{thisBar.attr("class")} gantt-bar-hover")

      .on "mouseout", () ->
         # Reset classes on element.
         thisBar = d3.select(@)
         thisBar.attr("class", thisBar.attr("class").replace("gantt-bar-hover", ""))

   if callbacks.assignmentMouseOver
      assignmentBar.on "mouseover", (d) ->
         callbacks.assignmentMouseOver(d)

      assignmentBar.on "mouseout", (d) ->
         callbacks.assignmentMouseOut(d)

   # TimeOff Bars
   if canViewPeopleTimeoff
      startDate = rangeData.startDate
      endDate = new Date(rangeData.startDate);
      endDate.setHours(24 * rangeData.days);
      timeOffBar = personRow.selectAll("project-gantt__person-row-wrapper").data((d) ->
         return d.timeoff
            .map((t) => ({
               ...t,
               start: new Date(t.start),
               end: new Date(t.end),
               heightFactor: d.containedAssBars,
            }))
            # we didn't filter these on backend for efficiency's sake, but filtering here so we don't create more
            # new rect nodes than we need to
            .filter((t) =>
               # Add logic to catch if we're in one-day view. If we are, then we will need to decrement
               # both the end date of our range and the end date of our time-off. This is just for a
               # more accurate check here though. We don't want to actually mutate these values because
               # it would mess with their width when rendered as bars on the gantt.
               isSingleDayView = endDate.getTime() - startDate.getTime() == 1000 * 60 * 60 * 24

               if (isSingleDayView)
                  adjustedEndDate = new Date(endDate.getFullYear(), endDate.getMonth(), endDate.getDate() - 1);
                  adjustedTimeOffEndDate = new Date(t.end.getFullYear(), t.end.getMonth(), t.end.getDate() - 1);

                  return (t.start <= startDate and adjustedTimeOffEndDate >= startDate) or \
                     (t.start <= adjustedEndDate and adjustedTimeOffEndDate >= adjustedEndDate) or \
                     (t.start >= startDate and adjustedTimeOffEndDate <= adjustedEndDate)
               else
                  return (t.start <= startDate and t.end >= startDate) or \
                        (t.start <= endDate and t.end >= endDate) or \
                        (t.start >= startDate and t.end <= endDate)
            );
      )
         .enter().append("rect")
         .attr("class", "to-bar")
         .attr("rx", 2)
         .attr("ry", 2)
         # Get the temporal width and subject padding to make gaps between assignments.
         .attr("width", (d) -> return getElementWidth(d, true) - (barPadding * 2))
         .attr("height", (d) -> (barHeight + barPadding) * d.heightFactor - barPadding)
         # Get horizonal displacement and move over by 1 padding to center.
         .attr("x", (d) -> return getXDisplacement(d) + barPadding + infoSecWidth)
         # Position based on tier, give it vertical padding and move up half bar height for
         # total row padding.
         .attr("y", (d) -> return (d.tier * (barHeight + barPadding)) + (barHeight * .5))
         .style("fill", "url(#crosshatch)")

      timeOffBar.on "click", (d) ->
         callbacks.timeOffClicked(d, d3.event) if callbacks.timeOffClicked

      if callbacks.assignmentMouseOver
         timeOffBar.on "mouseover", (d) ->
            callbacks.assignmentMouseOver(d)

         timeOffBar.on "mouseout", (d) ->
            callbacks.assignmentMouseOut(d)


   # Requests header
   requestHeader = row.append("g")
      .attr("class", "project-gantt__request-header")
      .attr "transform", (d) ->
         yPadding = projectBarHeight + metaBarHeight + barPadding
         # Assignment total bar
         if d.containedAssBars > 0
            yPadding += (categoryBarHeight + barPadding)

         yPadding += d.containedCats * (categoryBarHeight + barPadding)
         yPadding += d.containedSubcats * (categoryBarHeight + barPadding)
         yPadding += d.containedAssBars * (barHeight + barPadding)
         
         return "translate(0,#{yPadding})"

   requestHeader.append("rect")
      .attr("class", "project-gantt_request-title-line")
      .attr("width", "100%")
      .attr("height", (d) -> return if d.groupedRequests.length > 0 then 20 else 0 )
      .attr("fill", "black")
      .attr("x", 0)
      .attr("y", 6)

   requestHeader.append("text")
      .filter (d) -> return d.groupedRequests.length > 0
      .attr("class", "project-gantt__request-title")
      .text () ->
         parentData = d3.select(@.parentNode).datum()

         if 91 <= rangeData.days < 182
            # Weekly
            if rangeData.projectViewConfig.totalsUnit == "people"
               requestIds = []

               for key, val of parentData.projectsRequestDailyHeads
                  for personId in val.requestIds
                     requestIds.push(personId) if requestIds.indexOf(personId) == -1

               totalsValue = requestIds.length
            else if rangeData.projectViewConfig.totalsUnit == "cost"
               totalsValue = 0
               for key, val of parentData.projectsRequestDailyCost
                  totalsValue += val.count
            else
               totalsValue = 0
               for key, val of parentData.projectsRequestDailyHours
                  totalsValue += val.count

         else
            # Daily & Months
            if rangeData.projectViewConfig.totalsUnit == "people"
               allRequestIds = []
               for key, val of parentData.projectsRequestDailyHeads
                  for id in val.requestIds
                     if allRequestIds.indexOf(id) == -1
                        allRequestIds.push(id)
               
               totalsValue = allRequestIds.length

            else if rangeData.projectViewConfig.totalsUnit == "cost"
               totalsValue = 0
               for key, val of parentData.projectsRequestDailyCost
                  totalsValue += val
            else
               totalsValue = 0
               for key, val of parentData.projectsRequestDailyHours
                  totalsValue += val

         totalsValue = '' if totalsValue == 0

         if rangeData.projectViewConfig.totalsUnit == "man-days" and totalsValue != ''
            # TODO: Update if we change what a man-day means to be configurable.
            # Forcing max 1 decimal.
            totalsValue = Math.round( (totalsValue / 8) * 10) / 10

         if totalsValue != '' and totalsValue != 0
            if totalsValue < 999
               totalsValue = Math.round(totalsValue)
            else if 999 < totalsValue < 9999
               totalsValue = "#{Math.round((totalsValue / 1000) * 10) / 10}K"
            else if 9999 < totalsValue < 99999
               totalsValue = "#{Math.round((totalsValue / 1000) * 10) / 10}K"
            else if 99999 < totalsValue < 999999
               totalsValue = "#{Math.round(((totalsValue / 1000) * 10) / 10)}K"
            else if 999999 < totalsValue
               totalsValue = "#{Math.round((totalsValue / 1000000) * 10) / 10}M"

            if rangeData.projectViewConfig.totalsUnit == "cost"
               totalsValue = "$#{totalsValue}"

            formattedRollupValue = if totalsValue != '' then "(#{totalsValue})" else ''
         else
            formattedRollupValue = ''

         return switch rangeData.projectViewConfig.totalsUnit
            when "people" then "Requests - People #{formattedRollupValue}" 
            when "man-days" then "Requests - Man Days #{formattedRollupValue}"
            when "hours" then "Requests - Hours #{formattedRollupValue}"
            when "cost" then "Requests - Cost #{formattedRollupValue}"

      .attr("font-family", "OpenSans")
      .attr("font-size", "12px")
      .attr("fill", "black")
      .attr("x", 10)
      .attr("y", categoryBarHeight - 6)

   # Requests totol bar
   row.append("g")
      .attr("class", "request-total-bar")
      # + 1 to show border
      .attr("width", chartWidth)
      .attr("height", (d) -> if d.groupedRequests.length > 0 then barHeight else 0)
      .attr("fill", "white")
      .attr "transform", (d) ->
         yPadding = projectBarHeight + metaBarHeight + barPadding
         # Assignment total bar
         if d.containedAssBars > 0
            yPadding += (categoryBarHeight + barPadding)

         yPadding += d.containedCats * (categoryBarHeight + barPadding)
         yPadding += d.containedSubcats * (categoryBarHeight + barPadding)
         yPadding += d.containedAssBars * (barHeight + barPadding)

         # Add the difference between the this bars height and the title row. 
         yPadding += (categoryBarHeight - barHeight) - 6

         "translate(0,#{yPadding})"

   requestCategorySection = row.selectAll(".row")
   .filter (d) -> return d.groupedRequests.length > 0
   .data((d) -> return d.groupedRequests)
   .enter().append("g")
   .attr "transform", (d) ->
      projectData = d3.select(@.parentNode).datum()

      yPadding = projectBarHeight + metaBarHeight + barPadding
      # Assignment total bar
      if projectData.containedAssBars > 0
         yPadding += (categoryBarHeight + barPadding)

      yPadding += projectData.containedCats * (categoryBarHeight + barPadding)
      yPadding += projectData.containedSubcats * (categoryBarHeight + barPadding)
      yPadding += projectData.containedAssBars * (barHeight + barPadding)

      # Request total bar
      yPadding += (categoryBarHeight + barPadding)

      # Request Data
      yPadding += d.leadingCats * (categoryBarHeight + barPadding)
      yPadding += d.leadingSubcats * (categoryBarHeight + barPadding)
      yPadding += d.leadingAssBars * (barHeight + barPadding)

      return "translate(0,#{yPadding})"

   requestCategorySection.append("text")
      .attr("class", "project-gantt__categry-section-title")
      .text (d) -> 
         return d.categoryName
      .attr("font-family", "OpenSans")
      .attr("font-size", "14px")
      .attr("fill", "black")
      .attr("x", 10)
      .attr("y", categoryBarHeight - 6)

   requestCategorySection.append("rect")
      .attr("class", "project-gantt_category-title-line")
      .attr("rx", 2)
      .attr("ry", 2)
      .attr("width", "100%")
      .attr("height", (d) -> return if d.categoryName? then 2 else 0 )
      .attr("fill", "black")
      .attr("x", 10)
      .attr("y", categoryBarHeight - 2)

   requestLabelSection = requestCategorySection.selectAll(".project-gantt_request-category-section").data((d) -> 
      return d.subcategories
   ).enter().append("g")
   .attr("class", "project-gantt_request-subcategory-wrapper")
   .attr "transform", (d) ->
      yPadding = 0
      if d3.select(@.parentNode).datum().categoryName?
         yPadding += (categoryBarHeight + barPadding)

      yPadding += d.leadingSubcats * (categoryBarHeight + barPadding)
      yPadding += d.leadingAssBars * (barHeight + barPadding)

      return "translate(0,#{yPadding})"

   requestLabelTag = requestLabelSection.append("g")
      .attr("class", "project-gantt__subcategory-tag")
      .attr("transform", "translate(0,0)")

   requestLabelTag.append("text")
      .filter (d) -> return d.subcategoryName?
      .attr("class", "project-gantt__subcategory-tag-title")
      .text (d) -> 
         return d.subcategoryName
      .attr("font-family", "OpenSans")
      .attr("font-size", "11px")
      .attr("fill", "black")
      .attr("x", 30)
      .attr("y", categoryBarHeight - 9)

   requestLabelTag.insert("rect", ":first-child")
      .filter (d) -> return d.subcategoryName?
      .attr("class", "project-gantt__subcategory-tag-bg")
      .attr("height", "18")
      .attr "width", () ->
         parent = d3.select(@.parentNode)
         children = parent.select(".project-gantt__subcategory-tag-title")
         textWidth = children.node().getComputedTextLength()
         return textWidth + 20
      .attr("fill", "white")
      .attr("stroke", "black")
      .attr("rx", 4)
      .attr("ry", 4)
      .attr("x", 20)
      .attr("y", 6)

   labelSection.selectAll(".project-gantt_subcategory-wrapper")

   # Requests Rows
   requestRow = requestLabelSection.selectAll(".project-gantt_request-subcategory-wrapper")
   .data((d) -> d.requests)
   .enter().append("g")
      .attr("class", "project-gantt__request-row-wrapper")
      .attr "transform", (d) ->
         yPadding = 0
         if d3.select(@.parentNode).datum().subcategoryName?
            yPadding += (categoryBarHeight + barPadding)

         yPadding += d.leadingRequestsBars * (barHeight + barPadding)
         
         return "translate(0,#{yPadding})"

   requestRow.append("rect")
      .attr("class", "project-gantt_request-row-bg")
      .attr("rx", 2)
      .attr("ry", 2)
      .attr("width", "100%")
      .attr("height", (barHeight + barPadding))
      .attr("opacity", 0)
      .attr("x", 0)

   requestRow.append("text")
      .attr "class", (d) ->
         if d.positionName?
            "project-gantt__request-row-title"
         else
            "project-gantt__request-row-title project-gantt__request-row-title--italic"
            
      .text (d) -> 
         return if d.positionName? then d.positionName else 'Not Specified'
      .attr("font-family", "OpenSans")
      .attr("font-size", "12px")
      .attr("fill", "black")
      .attr("x", 20)
      .attr("y", 13)

   # Request Bars
   requestRow.selectAll(".project-gantt__request-row-wrapper")
   .data((d) -> return getAssignmentChunks([d]))
   .enter().append("rect")
      .attr("class", "bar")
      .attr("rx", 2)
      .attr("ry", 2)
      # Get the temporal width and subject padding to make gaps between assignments.
      .attr("width", (d) -> return getElementWidth(d, true) - (barPadding * 2))
      .attr("height", barHeight)
      .style "fill", (d) ->
         if rangeData.projectViewConfig.barColor == "project-color"
            return d.projectColor or "#40464b"
         else if rangeData.projectViewConfig.barColor == "job-titles"
            return d.positionColor or "#40464b"
         else if rangeData.projectViewConfig.barColor == "overtime"
            return "#57a5ff"
         else if rangeData.projectViewConfig.barColor == "allocation"
            if d.percentAllocated?
               allocation = d.percentAllocated
            else
               assignmentHours = DateUtils.getDurationHours(d.startTime, d.endTime)
               if assignmentHours >= rangeData.paidShiftHours and !d.overtime
                  allocation = 100
               else
                  allocation = (assignmentHours / rangeData.paidShiftHours) * 100

            if Number(allocation) == 0
               assBarColor = allocationGray
            else if Number(allocation) == 100
               assBarColor = allocatedGreen
            else if Number(allocation) >= 200
               assBarColor = allocatedRed
            else if allocation < 100
               assBarColor = ColorUtils.blendColors("ffffff", allocatedGreen, allocation)
            else
               # between 101 - 199%
               assBarColor = ColorUtils.blendColors("fab9bd", allocatedRed, (allocation % 100))

            return "##{assBarColor}"

         else if rangeData.projectViewConfig.barColor == "status"
            if d.status?
               return d.status.color or "black"
            else
               return "black"

      # Get horizonal displacement and move over by 1 padding to center.
      .attr("x", (d) -> return getXDisplacement(d) + barPadding + infoSecWidth)

      .on "click", (d) ->
         d3.select(@)
         callbacks.requestClicked(d, d3.event)

      .on "mouseover", () ->
         # Add classes to element.
         thisBar = d3.select(@)
         thisBar.attr("class", "#{thisBar.attr("class")} gantt-bar-hover")

      .on "mouseout", () ->
         # Reset classes on element.
         thisBar = d3.select(@)
         thisBar.attr("class", thisBar.attr("class").replace("gantt-bar-hover", ""))

   adjustTextLabels = (selection) ->
      if 91 <= rangeData.days < 182
         thisWeekStartDate = getAttachedDate(todayDetachedDay)
         thisWeekStartMs = thisWeekStartDate.setDate(thisWeekStartDate.getDate() - thisWeekStartDate.getDay())
         thisWeeksStartingDay = getDetachedDay(new Date(thisWeekStartMs))
      else if 182 <= rangeData.days
         thisMonthChunk = String(todayDetachedDay).slice(0, -2)

      selection.selectAll('.tick').each (data) ->
         if 1 < rangeData.days < 91
            detachedDay = getDetachedDay(data)
            day = data.getDay()
            if day == 0 or day == 6
               d3.select(@).append("rect")
                  .attr("class", "axis-weekend-bg")
                  .attr("height", 20)
                  .attr("width", daysToPixels(1))
                  .attr("fill", "black")
                  .attr("opacity", "0.2")

            if detachedDay == todayDetachedDay
               d3.select(@).append("rect")
                  .attr("class", "axis-today-bg")
                  .attr("height", 20)
                  .attr("width", daysToPixels(1))
                  .attr("fill", "orange")
                  .attr("opacity", "0.3")

         else if 91 <= rangeData.days < 182
            # Weekly
            # Decouple
            weekStartDate = new Date(data.getTime())
            weekStartMs = weekStartDate.setDate(weekStartDate.getDate() - weekStartDate.getDay())
            weeksStartingDay = getDetachedDay(new Date(weekStartMs))

            if weeksStartingDay == thisWeeksStartingDay
               d3.select(@).append("rect")
                  .attr("class", "axis-today-bg")
                  .attr("height", 20)
                  .attr("width", daysToPixels(7))
                  .attr("fill", "orange")
                  .attr("opacity", "0.3")

         else if 182 <= rangeData.days
            detachedDay = getDetachedDay(data)
            monthChunk = String(detachedDay).slice(0, -2)

            if monthChunk == thisMonthChunk
               sectionStart = xScale(data)
               
               nextTickDate = new Date(data.getTime())
               nextTickDate.setMonth(nextTickDate.getMonth() + 1)
               nextTickDate.setDate(1)
               sectionEnd = xScale(nextTickDate)

               offset = (sectionEnd - sectionStart)

               d3.select(@).append("rect")
                  .attr("class", "axis-today-bg")
                  .attr("height", 20)
                  .attr("width", offset)
                  .attr("fill", "orange")
                  .attr("opacity", "0.3")

         if rangeData.days == 1
            xDelta = hoursToPixels(1)
         else if rangeData.days < 91
            xDelta = daysToPixels(1)
         else if rangeData.days < 182
            # TODO: evaluate per tick calculation to handle non-whole segmeents (like secondary axis).
            xDelta = daysToPixels(7)
         else if rangeData.days < 730
            # TODO: evaluate per tick calculation to handle non-whole segmeents (like secondary axis).
            xDelta = monthsToPixels(1)
         else
            xDelta = monthsToPixels(3)

         d3.select(@).selectAll('text')
            .attr('transform', 'translate(' + xDelta / 2 + ',-14)')

   adjustSecondaryTextLabels = (selection) ->
      selection.selectAll('.tick').each (data) ->
         sectionStart = xScale(data)
         if rangeData.days < 182
            nextTickDate = new Date(data.getTime())
            nextTickDate.setMonth(nextTickDate.getMonth() + 1)
            nextTickDate.setDate(1)
            sectionEnd = xScale(nextTickDate)

         else
            nextTickDate = new Date(data.getTime())
            nextTickDate.setYear(nextTickDate.getFullYear() + 1)
            nextTickDate.setMonth(0)
            nextTickDate.setDate(1)
            sectionEnd = xScale(nextTickDate)

         if 1 < rangeData.days <= 91
            # Hide months that don't fit
            offset = (sectionEnd - sectionStart) / 2
            d3.select(@).selectAll('text')
               .each () ->
                  width = @.getComputedTextLength()
                  if width >= offset
                     @.remove()
               .attr('transform', "translate(#{offset},-14)")
         else
            offset = (sectionEnd - sectionStart) / 2
            d3.select(@).selectAll('text')
               .attr('transform', "translate(#{offset},-14)")

   adjustTotalsLabels = (selection) ->
      selection.selectAll('.tick').each (data) ->
         if 1 < rangeData.days < 91
            day = data.getDay()
            if day == 0 or day == 6
               d3.select(@).append("rect")
                  .attr("class", "axis-weekend-bg")
                  .attr("height", 20)
                  .attr("width", daysToPixels(1))
                  .attr("fill", "black")
                  .attr("opacity", "0.2")

         if rangeData.days < 91
            xDelta = daysToPixels(1)
         else if rangeData.days < 182
            # TODO: evaluate per tick calculation to handle non-whole segmeents (like secondary axis).
            xDelta = daysToPixels(7)
         else if rangeData.days < 730
            xDelta = monthsToPixels(1)
         else
            # TODO: evaluate per tick calculation to handle non-whole segmeents (like secondary axis).
            xDelta = monthsToPixels(3)

         d3.select(@).insert("rect", ':first-child')
            .attr("class", "axis-assignment-bg")
            .attr("height", 20)
            .attr("width", xDelta)
            # gs-1-color
            .attr("fill", "#6A6A6A")

         parentData = d3.select(@.parentNode).datum()
         day = getDetachedDay(data)

         totalsValue = 0

         if rangeData.days < 91
            # Daily
            if rangeData.projectViewConfig.totalsUnit == "people"
               totalsData = parentData.projectsDailyHeads
               if totalsData[day]?
                  totalsValue = totalsData[day].peopleIds.length
               else
                  totalsValue = 0
            else if rangeData.projectViewConfig.totalsUnit == "cost"
               totalsData = parentData.projectsDailyCost
               totalsValue = totalsData[day] or 0
            else
               totalsData = parentData.projectsDailyHours
               totalsValue = totalsData[day] or 0

         else if rangeData.days < 182
            # Weekly
            if rangeData.projectViewConfig.totalsUnit == "people"
               totalsData = parentData.projectsDailyHeads
               peopleIds = []

               for key, val of totalsData
                  if val.weekStart == day
                     for personId in val.peopleIds
                        peopleIds.push(personId) if peopleIds.indexOf(personId) == -1

               totalsValue = peopleIds.length
            else if rangeData.projectViewConfig.totalsUnit == "cost"
               totalsData = parentData.projectsDailyCost
               totalsValue = 0
               for key, val of totalsData
                  if val.weekStart == day
                     totalsValue += val.count
            else
               totalsData = parentData.projectsDailyHours
               totalsValue = 0
               for key, val of totalsData
                  if val.weekStart == day
                     totalsValue += val.count

         else if rangeData.days < 730
            # Months
            monthChunk = String(day).slice(0, -2)

            if rangeData.projectViewConfig.totalsUnit == "people"
               totalsData = parentData.projectsDailyHeads
            
               peopleIds = []

               for key, val of totalsData
                  if String(key).slice(0, -2) == monthChunk
                     for personId in val.peopleIds
                        peopleIds.push(personId) if peopleIds.indexOf(personId) == -1

               totalsValue = peopleIds.length
            else if rangeData.projectViewConfig.totalsUnit == "cost"
               totalsData = parentData.projectsDailyCost
               totalsValue = 0
               for key, val of totalsData
                  if String(key).slice(0, -2) == monthChunk
                     totalsValue += val
            else
               totalsData = parentData.projectsDailyHours
               totalsValue = 0
               for key, val of totalsData
                  if String(key).slice(0, -2) == monthChunk
                     totalsValue += val

         else
            # Quarterly
            processingQuarter = Math.ceil((Number(String(day).slice(4, 6)) + 1) / 3)
            yearQuarter = "#{String(day).slice(0, 4)}-#{processingQuarter}"

            if rangeData.projectViewConfig.totalsUnit == "people"
               totalsData = parentData.projectsDailyHeads
            
               peopleIds = []

               for key, val of totalsData
                  if "#{String(key).slice(0, 4)}-#{Math.ceil((Number(String(key).slice(4, 6)) + 1) / 3)}" == yearQuarter
                     for personId in val.peopleIds
                        peopleIds.push(personId) if peopleIds.indexOf(personId) == -1

               totalsValue = peopleIds.length
            else if rangeData.projectViewConfig.totalsUnit == "cost"
               totalsData = parentData.projectsDailyCost
               totalsValue = 0
               for key, val of totalsData
                  if "#{String(key).slice(0, 4)}-#{Math.ceil((Number(String(key).slice(4, 6)) + 1) / 3)}" == yearQuarter
                     totalsValue += val
            else
               totalsData = parentData.projectsDailyHours
               totalsValue = 0
               for key, val of totalsData
                  if "#{String(key).slice(0, 4)}-#{Math.ceil((Number(String(key).slice(4, 6)) + 1) / 3)}" == yearQuarter
                     totalsValue += val

         totalsValue = '' if totalsValue == 0
         if rangeData.projectViewConfig.totalsUnit == "man-days" and totalsValue != ''
            # TODO: Update if we change what a man-day means to be configurable.
            # Forcing max 1 decimal.
            totalsValue = Math.round( (totalsValue / 8) * 10) / 10

         # TODO: handle a condensed format like 1.3k when number is more than 3 digits and viewing 8 weeks.
         tickFont = "11px OpenSans"

         if rangeData.days < 91
            if xDelta < 30
               # Short format.
               tickFont = "8px OpenSans"
               if totalsValue != ''
                  if totalsValue < 999
                     totalsValue = Math.round(totalsValue)
                  else if totalsValue < 9999
                     totalsValue = "#{Math.round((totalsValue / 1000) * 10) / 10}K"
                  else if totalsValue < 99999
                     totalsValue = "#{Math.round((totalsValue / 1000) * 10) / 10}K"
                  else if totalsValue < 999999
                     totalsValue = "#{Math.round((totalsValue / 1000000) * 10) / 10}M"
            else
               # Long format.
               totalsValue = String(Math.round(totalsValue)).replace(/\B(?=(\d{3})+(?!\d))/g, ",")
         else
            if xDelta < 50
               # Long format.
               tickFont = "9px OpenSans"
               if totalsValue != ''
                  if totalsValue < 999
                     totalsValue = Math.round(totalsValue)
                  else if totalsValue < 9999
                     totalsValue = "#{Math.round((totalsValue / 1000) * 10) / 10}K"
                  else if totalsValue < 99999
                     totalsValue = "#{Math.round((totalsValue / 1000) * 10) / 10}K"
                  else if totalsValue < 999999
                     totalsValue = "#{Math.round((totalsValue / 1000000) * 10) / 10}M"
            else
               # Long format.
               totalsValue = String(Math.round(totalsValue)).replace(/\B(?=(\d{3})+(?!\d))/g, ",")

         d3.select(@).selectAll('text')
            .text(totalsValue)
            .attr('transform', 'translate(' + xDelta / 2 + ',-14)')
            .attr("fill", "white")
            .style('font', tickFont)

   adjustRequestsTotalsLabels = (selection) ->
      selection.selectAll('.tick').each (data) ->
         if 1 < rangeData.days < 91
            day = data.getDay()
            if day == 0 or day == 6
               d3.select(@).append("rect")
                  .attr("class", "axis-weekend-bg")
                  .attr("height", 20)
                  .attr("width", daysToPixels(1))
                  .attr("fill", "black")
                  .attr("opacity", "0.2")

         if rangeData.days < 91
            xDelta = daysToPixels(1)
         else if rangeData.days < 182
            # TODO: evaluate per tick calculation to handle non-whole segmeents (like secondary axis).
            xDelta = daysToPixels(7)
         else if rangeData.days < 730
            # TODO: evaluate per tick calculation to handle non-whole segmeents (like secondary axis).
            xDelta = monthsToPixels(1)
         else
            xDelta = monthsToPixels(3)

         d3.select(@).insert("rect", ':first-child')
            .attr("class", "axis-request-bg")
            .attr("height", 20)
            .attr("width", xDelta)
            .attr("fill", "black")

         parentData = d3.select(@.parentNode).datum()
         day = getDetachedDay(data)

         totalsValue = 0

         if rangeData.days < 91
            # Daily
            if rangeData.projectViewConfig.totalsUnit == "people"
            
               totalsData = parentData.projectsRequestDailyHeads
               if totalsData[day]?
                  totalsValue = totalsData[day].requestIds.length
               else
                  totalsValue = 0
            else if rangeData.projectViewConfig.totalsUnit == "cost"
               totalsData = parentData.projectsRequestDailyCost
               totalsValue = totalsData[day] or 0
            else
               totalsData = parentData.projectsRequestDailyHours
               totalsValue = totalsData[day] or 0

         else if rangeData.days < 182
            # Weekly
            if rangeData.projectViewConfig.totalsUnit == "people"
               totalsData = parentData.projectsRequestDailyHeads
               requestIds = []

               for key, val of totalsData
                  if val.weekStart == day
                     for personId in val.requestIds
                        requestIds.push(personId) if requestIds.indexOf(personId) == -1

               totalsValue = requestIds.length
            else if rangeData.projectViewConfig.totalsUnit == "cost"
               totalsData = parentData.projectsRequestDailyCost
               totalsValue = 0
               for key, val of totalsData
                  if val.weekStart == day
                     totalsValue += val.count
            else
               totalsData = parentData.projectsRequestDailyHours
               totalsValue = 0
               for key, val of totalsData
                  if val.weekStart == day
                     totalsValue += val.count
         else
            # Months
            monthChunk = String(day).slice(0, -2)

            if rangeData.projectViewConfig.totalsUnit == "people"
               totalsData = parentData.projectsRequestDailyHeads
            
               requestIds = []

               for key, val of totalsData
                  if String(key).slice(0, -2) == monthChunk
                     for personId in val.requestIds
                        requestIds.push(personId) if requestIds.indexOf(personId) == -1

               totalsValue = requestIds.length
            else if rangeData.projectViewConfig.totalsUnit == "cost"
               totalsData = parentData.projectsRequestDailyCost
               totalsValue = 0
               for key, val of totalsData
                  if String(key).slice(0, -2) == monthChunk
                     totalsValue += val
            else
               totalsData = parentData.projectsRequestDailyHours
               totalsValue = 0
               for key, val of totalsData
                  if String(key).slice(0, -2) == monthChunk
                     totalsValue += val

         totalsValue = '' if totalsValue == 0

         if rangeData.projectViewConfig.totalsUnit == "man-days" and totalsValue != ''
            # TODO: Update if we change what a man-day means to be configurable.
            # Forcing max 1 decimal.
            totalsValue = Math.round( (totalsValue / 8) * 10) / 10

         tickFont = "11px OpenSans"

         if rangeData.days < 91
            if xDelta < 30
               # Short format.
               tickFont = "8px OpenSans"
               if totalsValue != ''
                  if totalsValue < 999
                     totalsValue = Math.round(totalsValue)
                  else if totalsValue < 9999
                     totalsValue = "#{Math.round((totalsValue / 1000) * 10) / 10}K"
                  else if totalsValue < 99999
                     totalsValue = "#{Math.round((totalsValue / 1000) * 10) / 10}K"
                  else if totalsValue < 999999
                     totalsValue = "#{Math.round((totalsValue / 1000000) * 10) / 10}M"
            else
               # Long format.
               totalsValue = String(Math.round(totalsValue)).replace(/\B(?=(\d{3})+(?!\d))/g, ",")
         else
            if xDelta < 50
               # Long format.
               tickFont = "9px OpenSans"
               if totalsValue != ''
                  if totalsValue < 999
                     totalsValue = Math.round(totalsValue)
                  else if totalsValue < 9999
                     totalsValue = "#{Math.round((totalsValue / 1000) * 10) / 10}K"
                  else if totalsValue < 99999
                     totalsValue = "#{Math.round((totalsValue / 1000) * 10) / 10}K"
                  else if totalsValue < 999999
                     totalsValue = "#{Math.round((totalsValue / 1000000) * 10) / 10}M"
            else
               # Long format.
               totalsValue = String(Math.round(totalsValue)).replace(/\B(?=(\d{3})+(?!\d))/g, ",")

         d3.select(@).selectAll('text')
            .text(totalsValue)
            .attr('transform', 'translate(' + xDelta / 2 + ',-14)')
            .attr("fill", "white")
            .style('font', tickFont)

   # unless appending
   if rangeData.days == 1
      tickFormat = d3.timeFormat("%I:%M %p")
   else if rangeData.days < 28
      tickFormat = d3.timeFormat("%a %e")
   else if rangeData.days < 56
      if frameWidth <= 1200
         tickFormat = d3.timeFormat("%e")
      else
         tickFormat = d3.timeFormat("%a %e")
   else if rangeData.days < 91
      tickFormat = d3.timeFormat("%e")
   else if rangeData.days < 182
      tickFormat = if DateUtils.hasDayBeforeMonth(defaultStore.getDateFormat()) then d3.timeFormat("%e %b") else d3.timeFormat("%b %e")
   else if rangeData.days < 730
      tickFormat = d3.timeFormat("%b")
   else
      tickFormat = (date) ->
         month = date.getMonth() + 1
         return "Q#{Math.ceil(month / 3)}"
      

   if rangeData.days == 1
      secondaryTickFormat = (date) -> DateUtils.formatDate(date, defaultStore.getDateFormat(), DateUtils.LONG_FORM_OPTIONS)
   else if rangeData.days < 182
      secondaryTickFormat = d3.timeFormat("%B")
   else
      secondaryTickFormat = d3.timeFormat("%Y")

   xAxis = d3.axisBottom(xScale)
   .tickFormat(tickFormat)
   .tickSize(18.6)

   xAxis.tickValues(ticks)

   # For secondary axis
   secondaryStart = new Date(rangeStart.getTime())
   unless rangeData.days == 1
      secondaryStart.setDate(1)

   unless rangeData.days < 182
      secondaryStart.setMonth(0)

   if rangeData.days == 1
      secondaryEnd = new Date(rangeStart.getTime())
   else
      secondaryEnd = new Date(rangeEnd.getTime())
      secondaryEnd.setDate(1)

   secondaryXScale = d3.scaleTime()
   .domain([secondaryStart, secondaryEnd])
   .range([0, chartWidth])
   .clamp(true)

   unless appending
      if rangeData.days == 1
         secondaryTicks = secondaryXScale.ticks(d3.timeDay.every(1))
      else if rangeData.days >= 182
         secondaryTicks = secondaryXScale.ticks(d3.timeYear.every(1))
      else 
         secondaryTicks = secondaryXScale.ticks(d3.timeMonth.every(1))

      secondaryXAxis = d3.axisBottom(xScale)
      .tickFormat(secondaryTickFormat)
      .tickSize(19.6)

      secondaryXAxis.tickValues(secondaryTicks)

      xAxisBar = d3.select("#gantt-x-axis")
      .append("svg")
         .attr("class", "x-axis-wrapper")
         .attr("width", frameWidth)
         .attr("height", 40)

      xAxisBar.append("g")
         .attr("class", "x-axis-group")
         .attr("transform", "translate(#{infoSecWidth},20)")
         .style('font', -> if rangeData.days == 1 and frameWidth < 1200 then "8px OpenSans" else "10px OpenSans")
         .call(xAxis)
         .call(adjustTextLabels)

      xAxisBar.append("g")
         .attr("class", "x-axis-group--secondary")
         .attr("transform", "translate(#{infoSecWidth},-1)")
         .style('font', "11px OpenSans")
         .call(secondaryXAxis)
         .call(adjustSecondaryTextLabels)

   # Adding assignment totals
   # TODO: Handle single day view differently here.
   d3.selectAll(".assignment-total-bar")
      .filter (d) ->
         return d.containedAssBars > 0
      .append("g")
      # .attr("class", "x-axis-group--secondary")
      .attr("transform", "translate(#{infoSecWidth},-1)")
      # .attr("transform", "translate(0,-1)")
      .style('font', "11px OpenSans")
      .call(
         if rangeData.days == 1
         then secondaryXAxis
         else xAxis
         )
      .call(adjustTotalsLabels)

   # Adding request totals
   # TODO: Handle single day view differently here.
   d3.selectAll(".request-total-bar")
      .filter (d) ->
         return d.containedRequestBars > 0
      .append("g")
      .attr("transform", "translate(#{infoSecWidth},-1)")
      .style('font', "11px OpenSans")
      .call(
         if rangeData.days == 1
         then secondaryXAxis
         else xAxis
         )
      .call(adjustRequestsTotalsLabels) 

   # Update heights after draw.
   updateAllPagesYaxis()
   chartHeight = d3.select(".chart").node().getBBox().height + 46
   svg.attr("height", chartHeight) if chartHeight > frameHeight
   grid = d3.select(".grid")

   grid.selectAll('.tick line')
      .attr("y2", if chartHeight < frameHeight then frameHeight else chartHeight)

   grid.selectAll('.tick .weekend-bg')
      .attr("height", if chartHeight < frameHeight then frameHeight else chartHeight)

   grid.selectAll('.tick .today-bg')
      .attr("height", if chartHeight < frameHeight then frameHeight else chartHeight)

resetChart = () ->
   $("#project-gantt-chart").empty()
   $("#gantt-x-axis").empty()

ko.bindingHandlers["projectsGanttChart"] =
   init: (element, valueAccessor) ->
      accessor = ko.unwrap(valueAccessor())
      callbacks = accessor.callbacks
      pages = []; 

      if accessor.rows().length > 0
         pages.push({
            id: GUID()
            rows: accessor.rows(),
         })


      rangeData = accessor.rangeData
      options = {
         lastNamesFirst: if accessor.lastNamesFirst? then ko.unwrap(accessor.lastNamesFirst) else false
         permissions: if accessor.permissions? then ko.unwrap(accessor.permissions) else false
      }
      isDisposed = false

      drawGantt(element, pages, rangeData, false, callbacks, options) unless pages.length == 0

      redraw = (rowData, newRangeData) =>
         return if isDisposed
         rangeData = newRangeData
         pages = [{
            id: GUID()
            rows: rowData,
         }]

         drawGantt(element, pages, rangeData, false, callbacks, options)

      appendData = (newData, newRangeData) =>            
         return if isDisposed
         rangeData = newRangeData

         pages.push({
            id: GUID()
            rows: newData, 
         })

         drawGantt(element, pages, rangeData, true, callbacks, options)

      prependData = (newData, newRangeData) => 
         return if isDisposed
         rangeData = newRangeData
         pages.unshift({
            id: GUID()
            rows: newData, 
         })
         drawGantt(element, pages, rangeData, true, callbacks, options)

      resizeTimeout = null
      resizeHandler = () =>
         if accessor.showing()
            # This causes it to only fire after resizing stops.
            clearTimeout(resizeTimeout)
            resizeTimeout = setTimeout () ->
               # To make sure it's still showing.
               if accessor.showing()
                  drawGantt(element, pages, rangeData, false, callbacks, options)
            , 250

      $(window).on("resize", resizeHandler)

      ko.utils.domNodeDisposal.addDisposeCallback element, () ->
         isDisposed = true
         resetChart()
         $(window).off("resize", resizeHandler)

      accessor.mediator.initialize({redraw: redraw, appendData: appendData, prependData: prependData })
