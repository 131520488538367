getVisibility = (rect, allowPartial) ->
   # console.log "rect - top: #{rect.top} right: #{rect.right} bottom: #{rect.bottom} left: #{rect.left}"
   unless allowPartial
      return (
         rect.top >= 0 &&
         rect.left >= 0 &&
         rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) && 
         rect.right <= (window.innerWidth || document.documentElement.clientWidth)
      )
   # return (
   # Off top left
   if (rect.right <= (window.innerWidth || document.documentElement.clientWidth) and
   rect.right >= 0 and
   rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) and
   rect.bottom >= 0) 
      console.log "off top left"
      return true
      
   # Off top right
   if (rect.left <= (window.innerWidth || document.documentElement.clientWidth) and
   rect.left >= 0 and
   rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) and
   rect.bottom >= 0) 
      console.log "off top right"
      return true
   
   # Off bottom right
   if (rect.left <= (window.innerWidth || document.documentElement.clientWidth) and
   rect.left >= 0 and
   rect.top <= (window.innerHeight || document.documentElement.clientHeight) and
   rect.top >= 0)
      console.log "off bottom right"
      return true
   # Off bottom left
   if (rect.right <= (window.innerWidth || document.documentElement.clientWidth) and
   rect.right >= 0 and
   rect.top <= (window.innerHeight || document.documentElement.clientHeight) and
   rect.top >= 0)
      console.log "off bottomr left"
      return true
   # )
   return false
   

updateHandler = (visState, rect, data, allowPartial, callback) ->
   # console.log "updateHandler fired"
   visible = getVisibility(rect, allowPartial)
   if visible and !visState()
      # console.log "SCROLL: element: #{element} visible: #{visible}"
      visState(true)
      callback(data) 
   else if !visible and visState()
      # console.log "setting false element: ", element
      visState(false)

ko.bindingHandlers["whenVisible"] =
   "init": (element, valueAccessor) ->
      accessor = ko.unwrap(valueAccessor())
      data = ko.unwrap(accessor.data) or null
      callback = ko.unwrap(accessor.callback)
      scrollContainers = ko.unwrap(accessor.scrollContainers) or []
      allowPartial = ko.unwrap(accessor.allowPartial) or false
      
      rect = element.getBoundingClientRect()
      visState = ko.observable(false)

      visible = getVisibility(rect, allowPartial)
      # console.log "init element: #{element} visible: #{visible}"
      if visible and !visState()
         visState(true)
         callback(data) 

      for className in scrollContainers
         $(className).scroll () ->
            scrollTop = $(className).scrollTop()
            # console.log "scrollTop: #{scrollTop}"
            scrolledRect = {
               top: rect.top - scrollTop
               right: rect.right
               bottom: rect.bottom - scrollTop   
               left: rect.left
            }
            # console.log "scrolledRect: ", scrolledRect
            updateHandler(visState, scrolledRect, data, callback)

      # Removed events: DOMContentLoaded load
      $(window).on 'resize scroll', () ->
         # console.log "window listener firing"
         updateHandler(visState, rect, data, callback)