import type { Observable } from "knockout";
import ko from "knockout";
import { findFirstFocusableElement } from "@/lib/utils/elements";

export type ContainsFocusParams = Observable<boolean>;

/**
 * Two-way binds an observable to the focus state of this element as well as all children
 * elements. Updating the observable to a truthy value will trigger the first focusable
 * child to be focused.
 */
ko.bindingHandlers.containsFocus = {
   init: (element: HTMLElement, valueAccessor) => {
      const hasFocus = valueAccessor() as ContainsFocusParams;

      let isIgnoringChanges = false;
      const handleFocusInOut = () => {
         isIgnoringChanges = true;
         if (document.activeElement == element) {
            hasFocus(true);
         } else {
            hasFocus(element.contains(document.activeElement));
         }
         isIgnoringChanges = false;
      };

      const subscription = hasFocus.subscribe((value) => {
         if (isIgnoringChanges) return;
         if (value) {
            if (element.tabIndex >= 0) {
               element.focus();
               return;
            }
            const focusableElement = findFirstFocusableElement(element);
            if (focusableElement) {
               focusableElement.focus();
            }
         } else if (element.contains(document.activeElement)) {
            (document.activeElement as HTMLElement).blur();
         }
      });

      element.addEventListener("focusin", handleFocusInOut);
      element.addEventListener("focusout", handleFocusInOut);
      ko.utils.domNodeDisposal.addDisposeCallback(element, () => {
         element.addEventListener("focusin", handleFocusInOut);
         element.addEventListener("focusout", handleFocusInOut);
         subscription.dispose();
      });

      handleFocusInOut();
   },
};
