import { findFocusableElements, isElementHidden } from "@/lib/utils/elements";
import type { MaybeObservable } from "knockout";
import ko, { unwrap } from "knockout";

enum FocusLockElement {
   START = "start",
   END = "end",
}

/** Locks focus to cycle to focusable children within the element. */
ko.bindingHandlers.focusLock = {
   init: (element: HTMLElement, valueAccessor) => {
      const type = valueAccessor() as MaybeObservable<FocusLockElement>;
      const parent = element.parentElement!;
      const handleFocus = () => {
         const focusableElements = Array.from(findFocusableElements(parent)).filter(
            (element) => !isElementHidden(element),
         );
         if (focusableElements.length == 0) return;
         const index = unwrap(type) == FocusLockElement.START ? focusableElements.length - 1 : 0;
         focusableElements[index].focus();
      };

      const handleParentFocusInOut = () => {
         if (parent.contains(document.activeElement) && findFocusableElements(parent).length) {
            element.tabIndex = 0;
         } else {
            element.tabIndex = -1;
         }
      };

      element.addEventListener("focus", handleFocus);
      parent.addEventListener("focusin", handleParentFocusInOut);
      parent.addEventListener("focusout", handleParentFocusInOut);
      ko.utils.domNodeDisposal.addDisposeCallback(element, () => {
         element.removeEventListener("focus", handleFocus);
         parent.removeEventListener("focusin", handleParentFocusInOut);
         parent.removeEventListener("focusout", handleParentFocusInOut);
      });
   },
};
