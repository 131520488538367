import { Dom } from "@/lib/utils/dom"

ko.bindingHandlers["photo-upload"] =
   init: (element, valueAccessor) ->
      fileContent = valueAccessor()
      reader = new FileReader()

      reader.onloadend = => fileContent(reader.result)

      handler = =>
         file = element.files[0]
         # Opening the file picker then canceling will trigger a 'change'
         # event without actually picking a file.
         if file == undefined
            fileContent(null)
            return
         reader.readAsDataURL(file)


      ko.utils.registerEventHandler element, 'change', handler