import { Dom } from "@/lib/utils/dom"

ko.bindingHandlers["file"] =
   init: (element, valueAccessor) ->
      callback = ko.unwrap(valueAccessor()).callback
      reader = new FileReader()

      reader.onloadend = ->
         fileContent(reader.result)

      changeHandler = ->
         file = element.files[0]

         # Opening the file picker then canceling will trigger a 'change'
         # event without actually picking a file.
         return if file == undefined
         callback(null, file)


      ko.utils.registerEventHandler element, 'change', changeHandler