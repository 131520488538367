import { dragManager } from "@/lib/managers/drag-manager"
import { Guid as GUID } from "@/lib/utils/guid"

ko.bindingHandlers["draggableContainer"] =
   init: (element, valueAccessor) ->
      options = ko.unwrap(valueAccessor())
      # TODO: Configure these options based on dragula's API & LC assignment logic.
      dragManager.addContainer(element) unless options.disable

      assertOfType(options, [Object, undefined])
      containerData = valueAccessor().data or null

      if options?.callback or options?.onDrag
         containerId = GUID()
         element.classList.add(containerId)
         dragManager.registerContainerCallback(containerId, options, containerData)

ko.bindingHandlers["noDrag"] =
   init: (element, valueAccessor) ->
      dragManager.maybeAddNoDragClass(element.classList)