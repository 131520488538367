import type { Observable } from "knockout";
import ko, { unwrap } from "knockout";

ko.bindingHandlers.scrollLeft = {
   init: (element: Element, valueAccessor) => {
      const scrollLeft: Observable<number> = valueAccessor();
      const updateScroll = () => {
         scrollLeft(element.scrollLeft);
      };
      element.addEventListener("scroll", updateScroll);
      ko.utils.domNodeDisposal.addDisposeCallback(element, () => {
         element.removeEventListener("scroll", updateScroll);
      });

      // Trigger the initial update.
      updateScroll();
   },

   update: (element: Element, valueAccessor) => {
      const value = unwrap(valueAccessor());
      if (element.scrollLeft != value) {
         element.scrollLeft = value;
      }
   },
};
