import { requestContext } from "@/stores/common/request-context";
import ko from "knockout";
import $ from "jquery";
import { MarkerClusterer } from "@/vendor/google-map-marker-clusterer";

ko.bindingHandlers["mapView"] =
   init: (element, valueAccessor) ->
      values = ko.unwrap(valueAccessor())
      markerData = values.data
      projectClicked = values.projectCallback
      personClicked = values.personCallback
      clearProjectMarkerTrigger = values.clearProjectMarkerTrigger
      clearPersonMarkerTrigger = values.clearPersonMarkerTrigger
      selectMarkerTrigger = values.selectMarkerTrigger
      projectRadius = values.projectRadius
      personRadius = values.personRadius
      zoomInTrigger = values.zoomInTrigger
      zoomOutTrigger = values.zoomOutTrigger

      projectMarkers = []
      peopleMarkers = []
      projectClusterer = null
      peopleClusterer = null
      previouslySelectedProjectMarker = null
      indivisibleProjectClusterCenter = null
      previouslySelectedPersonMarker = null
      indivisiblePeopleClusterCenter = null
      visibleProjectCirlce = null
      visiblePersonCirlce = null
      activeInfoWindow = null

      clearProjectMarkerTrigger.subscribe () =>
         maybeDeselectPreviousProjectMarker()

      clearPersonMarkerTrigger.subscribe () =>
         maybeDeselectPreviousPersonMarker()

      selectMarkerTrigger.subscribe (newVal) =>
         return unless newVal?
         for marker in projectMarkers
            if marker.customData.id == newVal
               maybeDeselectPreviousProjectMarker()
               projectClicked(newVal, marker.customData.name)
               marker.setIcon({url: "data:image/svg+xml;charset=UTF-8;base64,  #{btoa(selectedProjectMarkerSVG)}"})
               map.setCenter(marker.position)
               map.setZoom(13)
               return previouslySelectedProjectMarker = marker
         for marker in peopleMarkers
            if marker.customData.id == newVal
               maybeDeselectPreviousPersonMarker()
               personClicked(marker.customData.id, "#{marker.customData.name.first} #{marker.customData.name.last}")
               marker.setIcon({url: "data:image/svg+xml;charset=UTF-8;base64,  #{btoa(selectedPeopleMarkerSVG)}"})
               map.setCenter(marker.position)
               map.setZoom(13)
               return previouslySelectedPersonMarker = marker

      projectRadius.subscribe (newVal) =>
         if !newVal? and visibleProjectCirlce?
            visibleProjectCirlce.setMap(null)
            visibleProjectCirlce = null
         else if !newVal?
            return null
         else
            miles = Number(newVal)
            if miles == 0
               visibleProjectCirlce.setMap(null) if visibleProjectCirlce?
               return visibleProjectCirlce = null
            visibleProjectCirlce.setMap(null) if visibleProjectCirlce?
            center = if previouslySelectedProjectMarker? then previouslySelectedProjectMarker.position else JSON.parse(indivisibleProjectClusterCenter)
            visibleProjectCirlce = new google.maps.Circle({
              map: map
              radius: miles * 1609.344
              fillColor: "#94c0e4"
              strokeColor: "#177bd7"
              strokeWeight: 2
              center: center
            })
            map.setCenter(center)

      personRadius.subscribe (newVal) =>
         if !newVal? and visiblePersonCirlce?
            visiblePersonCirlce.setMap(null)
            visiblePersonCirlce = null
         else if !newVal?
            return null
         else
            miles = Number(newVal)
            if miles == 0
               visiblePersonCirlce.setMap(null) if visiblePersonCirlce?
               return visiblePersonCirlce = null
            visiblePersonCirlce.setMap(null) if visiblePersonCirlce?
            center = if previouslySelectedPersonMarker? then previouslySelectedPersonMarker.position else JSON.parse(indivisiblePeopleClusterCenter)
            visiblePersonCirlce = new google.maps.Circle({
              map: map
              radius: miles * 1609.344
              fillColor: "#ff837b"
              strokeColor: "#af4740"
              strokeWeight: 2
              center: center
            })
            map.setCenter(center)

      peopleMarkerSVG = '<svg width="24px" height="43px" viewBox="0 0 24 43" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"> <defs> <linearGradient x1="50%" y1="13.9171782%" x2="50%" y2="100%" id="linearGradient-1"> <stop stop-color="#FF837B" offset="0%"></stop> <stop stop-color="#B6453E" offset="100%"></stop> </linearGradient> </defs> <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"> <g id="Portrait" transform="translate(-411.000000, -308.000000)"> <g id="Location-Dot" transform="translate(412.000000, 309.000000)"> <path d="M20.219,18.187 C22.094,15.187 22.313,13.405 22.313,11 C22.313,4.925 17.125,0 11.125,0 C5.05,0 0,4.925 0,11 C0,13.405 0.188,15.187 2.063,18.187 C2.898,19.525 11.125,28.25 11.125,41.625 C11.125,28.25 19.383,19.525 20.219,18.187 Z" id="Imported-Layers" stroke="#9D3D37" fill="url(#linearGradient-1)" stroke-linecap="round" stroke-linejoin="round"></path> <circle id="Oval-5" fill="#3E1411" cx="11" cy="11" r="3"></circle> </g> </g> </g> </svg>'
      selectedPeopleMarkerSVG = ' <svg width="24px" height="43px" viewBox="0 0 24 43" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"> <!-- Generator: Sketch 43.1 (39012) - http://www.bohemiancoding.com/sketch --> <desc>Created with Sketch.</desc> <defs> <linearGradient x1="50%" y1="13.9171782%" x2="50%" y2="100%" id="linearGradient-1"> <stop stop-color="#D06B65" offset="0%"></stop> <stop stop-color="#3E110E" offset="100%"></stop> </linearGradient> </defs> <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"> <g id="Portrait" transform="translate(-686.000000, -306.000000)"> <g id="Location-Dot" transform="translate(687.000000, 307.000000)"> <path d="M20.219,18.187 C22.094,15.187 22.313,13.405 22.313,11 C22.313,4.925 17.125,0 11.125,0 C5.05,0 0,4.925 0,11 C0,13.405 0.188,15.187 2.063,18.187 C2.898,19.525 11.125,28.25 11.125,41.625 C11.125,28.25 19.383,19.525 20.219,18.187 Z" id="Imported-Layers" stroke="#5C2320" fill="url(#linearGradient-1)" stroke-linecap="round" stroke-linejoin="round"></path> <ellipse id="Oval-5" fill="#3A0805" cx="11" cy="11" rx="3" ry="3"></ellipse> </g> </g> </g> </svg>'
      projectMarkerSVG = '<svg width="24px" height="43px" viewBox="0 0 24 43" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"> <!-- Generator: Sketch 43.1 (39012) - http://www.bohemiancoding.com/sketch --> <desc>Created with Sketch.</desc> <defs> <linearGradient x1="50%" y1="13.9171782%" x2="50%" y2="100%" id="linearGradient-1"> <stop stop-color="#94C0E4" offset="0%"></stop> <stop stop-color="#188FFC" offset="100%"></stop> </linearGradient> </defs> <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"> <g id="Portrait" transform="translate(-442.000000, -308.000000)"> <g id="project-map-marker" transform="translate(443.000000, 309.000000)"> <path d="M20.219,18.187 C22.094,15.187 22.313,13.405 22.313,11 C22.313,4.925 17.125,0 11.125,0 C5.05,0 0,4.925 0,11 C0,13.405 0.188,15.187 2.063,18.187 C2.898,19.525 11.125,28.25 11.125,41.625 C11.125,28.25 19.383,19.525 20.219,18.187 Z" id="Imported-Layers" stroke="#1579D5" fill="url(#linearGradient-1)" stroke-linecap="round" stroke-linejoin="round"></path> <circle id="Oval-5" fill="#0C253D" cx="11" cy="11" r="3"></circle> </g> </g> </g> </svg>'
      selectedProjectMarkerSVG = '<svg width="24px" height="43px" viewBox="0 0 24 43" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"> <!-- Generator: Sketch 43.1 (39012) - http://www.bohemiancoding.com/sketch --> <desc>Created with Sketch.</desc> <defs> <linearGradient x1="50%" y1="13.9171782%" x2="50%" y2="100%" id="linearGradient-1"> <stop stop-color="#759BBA" offset="0%"></stop> <stop stop-color="#093359" offset="100%"></stop> </linearGradient> </defs> <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"> <g id="Portrait" transform="translate(-742.000000, -306.000000)"> <g id="project-map-marker" transform="translate(743.000000, 307.000000)"> <path d="M20.219,18.187 C22.094,15.187 22.313,13.405 22.313,11 C22.313,4.925 17.125,0 11.125,0 C5.05,0 0,4.925 0,11 C0,13.405 0.188,15.187 2.063,18.187 C2.898,19.525 11.125,28.25 11.125,41.625 C11.125,28.25 19.383,19.525 20.219,18.187 Z" id="Imported-Layers" stroke="#0D518E" fill="url(#linearGradient-1)" stroke-linecap="round" stroke-linejoin="round"></path> <ellipse id="Oval-5" fill="#0C253D" cx="11" cy="11" rx="3" ry="3"></ellipse> </g> </g> </g> </svg>'

      myStyles = [
         {
            featureType: "poi",
            elementType: "labels",
            stylers: [
               { visibility: "off" }
            ]
         }
         {
            featureType: "transit",
            elementType: "labels",
            stylers: [
               { visibility: "off" }
            ]
         }
      ]

      bounds = null
      map = new google.maps.Map(element, {
         zoom: 13,
         minZoom: 2,
         maxZoom: 15,
         mapTypeControl: false
         streetViewControl: false
         zoomControl: false
         styles: myStyles
      })

      # Prevents overzooming on clicking an entity.
      disposableZoomOverrideBlock = false
      google.maps.event.addListener map, 'bounds_changed', ()  ->
         return disposableZoomOverrideBlock = false if disposableZoomOverrideBlock
         @.setZoom(15) if @.getZoom() > 15

      zoomInTrigger.subscribe ->
         newZoom = map.getZoom() + 1
         return if newZoom > 15
         disposableZoomOverrideBlock = true
         map.setZoom(newZoom)

      zoomOutTrigger.subscribe ->
         disposableZoomOverrideBlock = true
         map.setZoom(map.getZoom() - 1)

      maybeDeselectPreviousProjectMarker = ->
         projectRadius(null)
         visibleProjectCirlce.setMap(null) if visibleProjectCirlce?
         visibleProjectCirlce = null
         return unless previouslySelectedProjectMarker?
         previouslySelectedProjectMarker.setIcon({url: "data:image/svg+xml;charset=UTF-8;base64,  #{btoa(projectMarkerSVG)}"})

      maybeDeselectPreviousPersonMarker = ->
         personRadius(null)
         visiblePersonCirlce.setMap(null) if visiblePersonCirlce?
         visiblePersonCirlce = null
         return unless previouslySelectedPersonMarker?
         previouslySelectedPersonMarker.setIcon({url: "data:image/svg+xml;charset=UTF-8;base64,  #{btoa(peopleMarkerSVG)}"})

      handlePersonMarkerCLick = ->
         maybeDeselectPreviousPersonMarker()
         personClicked(@.customData.id, "#{@.customData.name.first} #{@.customData.name.last}")
         @.setIcon({url: "data:image/svg+xml;charset=UTF-8;base64,  #{btoa(selectedPeopleMarkerSVG)}"})
         previouslySelectedPersonMarker = @
         indivisiblePeopleClusterCenter = null

      handleProjectMarkerCLick = ->
         maybeDeselectPreviousProjectMarker()
         projectClicked(@.customData.id, @.customData.name)
         @.setIcon({url: "data:image/svg+xml;charset=UTF-8;base64,  #{btoa(selectedProjectMarkerSVG)}"})
         previouslySelectedProjectMarker = @
         indivisibleProjectClusterCenter = null

      handlePersonMarkerMouseOver = ->
         activeInfoWindow.close() if activeInfoWindow?
         htmlContent = "<div class='lc-custom__info-window-wrapper'>"
         if @.customData.profile_pic_url?
            fragments = @.customData.profile_pic_url.split("upload/")
            url = "#{fragments[0]}upload/g_face,c_thumb,w_70,h_70/#{fragments[1]}"
            htmlContent += "<div class='lc-custom__info-window__pic' style='background-image: url(" + url + ")'></div>"
         htmlContent += "<div>#{@.customData.name.first} #{@.customData.name.last}</div></div>"
         activeInfoWindow = new google.maps.InfoWindow({
            content: htmlContent
         })
         activeInfoWindow.open(map, @)

      handleProjectMarkerMouseOver = ->
         activeInfoWindow.close() if activeInfoWindow?
         htmlContent = "<div class='lc-custom__info-window-wrapper'>"
         if @.customData.profile_pic_url?
            fragments = @.customData.profile_pic_url.split("upload/")
            url = "#{fragments[0]}upload/c_thumb,w_70,h_70/#{fragments[1]}"
            htmlContent += "<div class='lc-custom__info-window__pic' style='background-image: url(" + url + ")'></div>"
         htmlContent += "<div>#{@.customData.name}</div></div>"
         activeInfoWindow = new google.maps.InfoWindow({
            content: htmlContent
         })
         activeInfoWindow.open(map, @)

      handleMarkerMouseOut = ->
         activeInfoWindow.close() if activeInfoWindow?
         activeInfoWindow = null

      handleProjectClusterListClick = (event) ->
         maybeDeselectPreviousProjectMarker()
         projectClicked(event.target.attributes.entityId.nodeValue, event.target.attributes.entityName.nodeValue)
         activeInfoWindow.close() if activeInfoWindow?
         activeInfoWindow = null
         indivisibleProjectClusterCenter = event.target.attributes.center.nodeValue
         previouslySelectedProjectMarker = null

      handlePeopleClusterListClick = (event) ->
         maybeDeselectPreviousPersonMarker()
         personClicked(event.target.attributes.entityId.nodeValue, event.target.attributes.entityName.nodeValue)
         activeInfoWindow.close() if activeInfoWindow?
         activeInfoWindow = null
         indivisiblePeopleClusterCenter = event.target.attributes.center.nodeValue
         previouslySelectedPersonMarker = null

      markerData.subscribe (newVal) ->
         bounds = new google.maps.LatLngBounds()
         previouslySelectedProjectMarker = null
         previouslySelectedPersonMarker = null
         # Projects
         projectClusterer.clearMarkers() if projectClusterer?

         projectMarkers = []
         for val in newVal.projects
            marker = new google.maps.Marker({
               position: {lat: val.lat, lng: val.long}
               icon: {url: "data:image/svg+xml;charset=UTF-8;base64,  #{btoa(projectMarkerSVG)}"}
               map: map
            })
            marker['customData'] = val
            bounds.extend(marker.position)
            marker.addListener('click', handleProjectMarkerCLick)
            marker.addListener('mouseover', handleProjectMarkerMouseOver)
            marker.addListener('mouseout', handleMarkerMouseOut)
            projectMarkers.push(marker)

         projectClusterer = new MarkerClusterer(map, projectMarkers, {
            imagePath: requestContext.baseUrl + '/images/clusters-blue/m',
            zoomOnClick: false
            })

         google.maps.event.addListener projectClusterer, 'clusterclick', (event) ->
            activeInfoWindow.close() if activeInfoWindow?
            if map.getZoom() < 15
               map.fitBounds(event.getBounds());
            else
               containedMarkers = event.getMarkers()
               return unless containedMarkers.length >= 1
               wrapperDiv = document.createElement("div")
               wrapperDiv.classList.add("lc-custom__info-window-wrapper")
               for marker in containedMarkers
                  listItemDiv = document.createElement("div")
                  listItemDiv.classList.add("lc-custom__info-window__list-item")
                  entityName = marker.customData.name
                  listItemDiv.innerHTML = entityName
                  listItemDiv.setAttribute('entityId', marker.customData.id)
                  listItemDiv.setAttribute('entityName', entityName)
                  listItemDiv.setAttribute('center', JSON.stringify({lat: marker.position.lat(), lng: marker.position.lng()}))
                  listItemDiv.addEventListener('click', handleProjectClusterListClick)
                  wrapperDiv.appendChild(listItemDiv)

               activeInfoWindow = new google.maps.InfoWindow({
                  content: wrapperDiv
                  position: event.center_
               })
               google.maps.event.addListener activeInfoWindow, 'domready', () ->
                  $(".gm-style-iw").addClass('lc-custom-show-close-btn')
               activeInfoWindow.open(map)

         peopleClusterer.clearMarkers() if peopleClusterer?

         peopleMarkers = []
         for val in newVal.people
            marker = new google.maps.Marker({
               position: {lat: val.lat, lng: val.long}
               icon: {url: "data:image/svg+xml;charset=UTF-8;base64,  #{btoa(peopleMarkerSVG)}"}
               map: map
            })
            marker['customData'] = val
            bounds.extend(marker.position)
            marker.addListener('click', handlePersonMarkerCLick)
            marker.addListener('mouseover', handlePersonMarkerMouseOver)
            marker.addListener('mouseout', handleMarkerMouseOut)
            peopleMarkers.push(marker)

         peopleClusterer = new MarkerClusterer(map, peopleMarkers,
            {
               imagePath: requestContext.baseUrl + '/images/clusters-red/m',
               zoomOnClick: false
            })

         google.maps.event.addListener peopleClusterer, 'clusterclick', (event) ->
            activeInfoWindow.close() if activeInfoWindow?
            if map.getZoom() < 15
               map.fitBounds(event.getBounds());
            else
               containedMarkers = event.getMarkers()
               return unless containedMarkers.length >= 1
               wrapperDiv = document.createElement("div")
               wrapperDiv.classList.add("lc-custom__info-window-wrapper")
               for marker in containedMarkers
                  listItemDiv = document.createElement("div")
                  listItemDiv.classList.add("lc-custom__info-window__list-item")
                  entityName = "#{marker.customData.name.first} #{marker.customData.name.last}"
                  listItemDiv.innerHTML = entityName
                  listItemDiv.setAttribute('entityId', marker.customData.id)
                  listItemDiv.setAttribute('entityName', entityName)
                  listItemDiv.setAttribute('center', JSON.stringify({lat: marker.position.lat(), lng: marker.position.lng()}))
                  listItemDiv.addEventListener('click', handlePeopleClusterListClick)
                  wrapperDiv.appendChild(listItemDiv)

               activeInfoWindow = new google.maps.InfoWindow({
                  content: wrapperDiv
                  position: event.center_
               })
               google.maps.event.addListener activeInfoWindow, 'domready', () ->
                  $(".gm-style-iw").addClass('lc-custom-show-close-btn')
               activeInfoWindow.open(map)

         return if newVal.people.length == 0 and newVal.projects.length == 0
         map.fitBounds(bounds)