DATA_CLONED_HEADER = "floatingHeader-clonedHeader"
DATA_FLOATING_HEADER = "floatingHeader-floatingHeader"
DATA_PARENT = "floatingHeader-parent"
DATA_HEADER_CLASS = "floatingHeader-headerClass"
DATA_CONTAINER_CLASS = "floatingHeader-containerClass"
DATA_SCROLL_CLASS = "floatingHeader-scrollClass"


maybeGetOrCreateTableHeader = (element) ->
   assertArgs(arguments, Node)
   return header if header = ko.utils.domData.get(element, DATA_FLOATING_HEADER)
   $element = $(element)
   headerClass = ko.utils.domData.get(element, DATA_HEADER_CLASS)
   $theads = $element.children(".#{headerClass}")

   # Return null if there are no headers.
   return null if $theads.length < 1

   # Clone the first header.
   containerClass = ko.utils.domData.get(element, DATA_CONTAINER_CLASS)
   $clonedThead = $theads.first().clone()
   $clonedTable = $("<table class='#{$element.attr("class")}'></table>")
   $floatingHeader = $("<div class='#{containerClass}'></div>")
   $clonedTable.append($clonedThead)
   $floatingHeader.append($clonedTable)

   # Insert after the parent element.
   $parent = ko.utils.domData.get(element, DATA_PARENT)
   $floatingHeader.insertAfter($parent)

   # Hide the first header because the floating header is over top of it.
   $theads.first().css("visibility", "hidden")

   ko.utils.domData.set(element, DATA_CLONED_HEADER, $clonedThead)
   ko.utils.domData.set(element, DATA_FLOATING_HEADER, $floatingHeader)
   return $floatingHeader

updateFloatingHeaderPosition = (element) ->
   assertArgs(arguments, Node)
   $parent = ko.utils.domData.get(element, DATA_PARENT)
   if $floatingHeader = ko.utils.domData.get(element, DATA_FLOATING_HEADER)
      position = $parent.position()
      $floatingHeader.css({
         position: "absolute"
         top: position.top
         left: position.left
         width: $parent.width()
      })

updateTableHeader = (element) ->
   assertArgs(arguments, Node)
   $floatingHeader = maybeGetOrCreateTableHeader(element)

   # Return if there isn't a floating header yet.
   return unless $floatingHeader

   # Check and make sure the floating header has received styling
   unless ($floatingHeader.css("position") == "absolute")
      updateFloatingHeaderPosition(element)

   $element = $(element)
   $clonedThead = ko.utils.domData.get(element, DATA_CLONED_HEADER)
   headerClass = ko.utils.domData.get(element, DATA_HEADER_CLASS)
   containerClass = ko.utils.domData.get(element, DATA_CONTAINER_CLASS)
   $theads = $element.children(".#{headerClass}")
   $clonedHeadings = $floatingHeader.find("th")
   $firstTheadColumns = $theads.first().find("th")

   # Update class on floating header. If there is a scrollClass, we have to handle this
   # differnetly.
   if scrollClass = ko.utils.domData.get(element, DATA_SCROLL_CLASS)
      $scrollingParent = $element.parent()
      if $scrollingParent.position().top < 0
         $floatingHeader.addClass("#{containerClass}--floating")
      else
         $floatingHeader.removeClass("#{containerClass}--floating")
   else
      if $firstTheadColumns.position().top < 0
         $floatingHeader.addClass("#{containerClass}--floating")
      else
         $floatingHeader.removeClass("#{containerClass}--floating")

   # Set the columns to have the text of first header.
   $firstTheadColumns.each (index, heading) ->
      $heading = $(heading)
      $clone = $clonedHeadings.eq(index)
      $clone.width($heading.width())
      $clone.text($heading.text())

   # If there is a scrollClass provided, indicating that the bound element is
   # not scrolling within it's parent, we have to handle this differnetly.
   # The bound element, which should be a table, parent is the class this is
   # scrolling within the provided scrollClass. So we need to use it's position
   # and compare to to stationary positions of the theads inside of the bound element.
   margin = $clonedThead.height() / 2
   if scrollClass = ko.utils.domData.get(element, DATA_SCROLL_CLASS)
      # get the position of the bound element's parent.
      $scrollingParent = $element.parent()
      scrollingTop = Math.abs($scrollingParent.position().top)
      # Find which header is active
      for i in [1...$theads.length]
         break if ($theads.eq(i).position().top > (scrollingTop + margin))
   else
      # Find which header is active.
      for i in [1...$theads.length]
         break if $theads.eq(i).position().top > margin

   # Override any text that is in the headings but don't bother if at zero.
   activeTheadIndex = i - 1
   if activeTheadIndex > 0
      $activeTheadColumns = $theads.eq(activeTheadIndex).find("th")
      $activeTheadColumns.each (index, heading) ->
         text = $(heading).text()
         $clonedHeadings.eq(index).text(text) if text

# Utility to run the update every 30 ms for a specified number of times.
runHeaderUpdateRepeatedly = (element, repeatCount) ->
   assertArgs(arguments, Node, Number)
   runHeaderUpdate = ->
      updateTableHeader(element)
      setTimeout(runHeaderUpdate, 30) if --repeatCount > 0
   runHeaderUpdate()

ko.bindingHandlers["floatingHeader"] =
   init: (element, valueAccessor) ->
      value = ko.utils.unwrapObservable(valueAccessor())
      containerClass = "floating-header"
      scrollClass = null

      if value instanceof Object
         assertOfType(value.headerClass, String)
         headerClass = value.headerClass
         assertOfType(value.containerClass, optional(String))
         containerClass = value.containerClass if value.containerClass
         assertOfType(value.scrollClass, optional(String))
         scrollClass = value.scrollClass if value.scrollClass
         # Unwrap the updateTrigger to make sure the dependancy is set.
         ko.utils.unwrapObservable(value.updateTrigger) if value.updateTrigger
      else
         headerClass = value

      $element = $(element)
      $parent = $element.parent()

      # Find the parent scroll class.
      unless scrollClass == null
         while !($parent.hasClass(scrollClass))
            $parent = $parent.parent()

      # Store all the required variables.
      ko.utils.domData.set(element, DATA_HEADER_CLASS, headerClass)
      ko.utils.domData.set(element, DATA_CONTAINER_CLASS, containerClass)
      ko.utils.domData.set(element, DATA_PARENT, $parent)
      unless scrollClass == null
         # set this variable for us in position checking of theads scrolling container.
         ko.utils.domData.set(element, DATA_SCROLL_CLASS, scrollClass)

      # Try and create the header.
      if maybeGetOrCreateTableHeader(element)
         updateFloatingHeaderPosition(element)

         # Because this relies on the table size, there are times that the table will adjust the
         # column widths but these changes won't be caught and updated until a scroll event
         # happens.
         runHeaderUpdateRepeatedly(element, 6)

      # Add event when the parent is scrolled.
      boundUpdateTableHeader = -> updateTableHeader(element)
      $parent.on("scroll", boundUpdateTableHeader)

      # Add event handling when the browser window is resized.
      onResize = ->
         if maybeGetOrCreateTableHeader(element)
            updateFloatingHeaderPosition(element)
            runHeaderUpdateRepeatedly(element, 6)
      $(window).on("resize", onResize)

      # Add callback for when element is being disposed.
      ko.utils.domNodeDisposal.addDisposeCallback element, ->
         $parent.off("scroll", boundUpdateTableHeader)
         $(window).off("resize", onResize)
         if $floatingHeader = ko.utils.domData.get(element, DATA_FLOATING_HEADER)
            $floatingHeader.remove()

   update: (element, valueAccessor) ->
      value = ko.utils.unwrapObservable(valueAccessor())
      # Unwrap the updateTrigger to make sure the dependancy is set.
      ko.utils.unwrapObservable(value.updateTrigger) if value.updateTrigger

      # Because we are getting the table values straight from the DOM, we need to wait for
      # Knockout to actually update the table header values before we can call update.
      setTimeout ->
         updateTableHeader(element)
      , 0