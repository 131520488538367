ko.bindingHandlers["borderColor"] =
   update: (element, valueAccessor) =>
      initColor = ko.unwrap(valueAccessor())
      $el = $(element)
      return unless initColor?

      colorShade = (color, alpha) ->
         assertArgs(arguments, String, Number)
         alpha = alpha || 0
         # Validate incoming color
         color = String(color).replace(/[^0-9a-f]/gi, '')
         color = color[0]+color[0]+color[1]+color[1]+color[2]+color[2] if color.length < 6
         # Convert to decimal and change luminosity
         rgb = "#"
         for i in [0..2]
            c = parseInt(color.substr(i*2,2), 16)
            c = Math.round(Math.min(Math.max(0, c + (c * alpha)), 255)).toString(16)
            rgb += ("00"+c).substr(c.length)
         return rgb

      borderColor = colorShade(initColor, -0.2)
      $el.css('border', '1px solid ' + borderColor)