import { requestAnimationFrames } from "@/lib/utils/animation";
import type { MaybeObservable } from "knockout";
import ko, { unwrap } from "knockout";

export interface TransitionParams {
   visible: MaybeObservable<any>;
   name: MaybeObservable<string>;
   onStart?: (isVisible: boolean) => void | null;
   onEnd?: (isVisible: boolean) => void | null;
}

interface TransitionContext {
   isVisibleAfterTransition: boolean;
   startClass: string;
   activeClass: string;
   endClass: string;
   properties: Set<string>;
}

const TRANSITION_CONTEXT = "transition-context";

/**
 * Mimics the transition API in Vue.
 *
 * Here's how class names are applied.
 * {name} represents the name parameter.
 *
 * Enter animation:
 *
 * [ opacity: 0 ] -> [ opacity: 1 ]
 *       |                 |
 * {name}--enter      {name}--enter-to
 *
 * |------------------------------|
 *      {name}--enter-active
 *
 * Leave animation:
 *
 * [ opacity: 1 ] -> [ opacity: 0 ]
 *       |                 |
 * {name}--leave      {name}--leave-to
 *
 * |------------------------------|
 *      {name}--leave-active
 */
ko.bindingHandlers.transition = {
   init(element: HTMLElement, valueAccessor) {
      const params = unwrap(valueAccessor()) as TransitionParams;

      setVisibility({ element, isVisible: unwrap(params.visible) });

      const transitionRunHandler = (event: TransitionEvent) => {
         const context = getContext(element);
         if (event.target == element && context) {
            context.properties.add(event.propertyName);
         }
      };

      const transitionEndHandler = (event: TransitionEvent) => {
         // Ignore transition events occurring within the element.
         const context = getContext(element);
         if (event.target != element || !context) {
            return;
         }
         context.properties.delete(event.propertyName);

         if (context.properties.size) {
            // There are still have ongoing transitions, wait until they're finished.
            return;
         }

         // Clear the context and the transition classes.
         setContext(element, null);
         element.classList.remove(context.activeClass, context.endClass);

         // Set the visibility
         setVisibility({ element, isVisible: context.isVisibleAfterTransition });
         const onEnd = unwrap(unwrap(valueAccessor() as TransitionParams).onEnd);
         if (onEnd) {
            onEnd(context.isVisibleAfterTransition);
         }
      };

      const transitionCancelHandler = (event: TransitionEvent) => {
         const context = getContext(element);
         if (event.target == element && context) {
            context.properties.delete(event.propertyName);
         }
      };

      element.addEventListener("transitionrun", transitionRunHandler);
      element.addEventListener("transitionend", transitionEndHandler);
      element.addEventListener("transitioncancel", transitionCancelHandler);
      ko.utils.domNodeDisposal.addDisposeCallback(element, () => {
         element.addEventListener("transitionrun", transitionRunHandler);
         element.removeEventListener("transitionend", transitionEndHandler);
         element.removeEventListener("transitioncancel", transitionCancelHandler);
      });
   },
   update(element: HTMLElement, valueAccessor) {
      const params = unwrap(valueAccessor()) as TransitionParams;
      const isVisibleValue = Boolean(unwrap(params.visible));
      const name = unwrap(params.name);

      // Check if a transition is already occurring. If so, cancel it.
      const currentContext = getContext(element);
      if (currentContext) {
         // Allow the current transition to finish if it's already to the same visibility.
         if (currentContext.isVisibleAfterTransition == isVisibleValue) return;
         element.classList.remove(
            currentContext.activeClass,
            currentContext.startClass,
            currentContext.endClass,
         );
         setContext(element, null);
      }
      triggerTransition(
         element,
         params,
         {
            isVisibleAfterTransition: isVisibleValue,
            startClass: getStartClass(isVisibleValue, name),
            activeClass: getActiveClass(isVisibleValue, name),
            endClass: getEndClass(isVisibleValue, name),
            properties: new Set(),
         },
         Boolean(currentContext),
      );
   },
};

function triggerTransition(
   element: HTMLElement,
   params: TransitionParams,
   context: TransitionContext,
   cancelledPreviousTransition: boolean,
) {
   // Ignore animations if the element is already in the current state.
   if (isVisible(element) == context.isVisibleAfterTransition && !cancelledPreviousTransition) {
      return;
   }

   setContext(element, context);

   // Allow a frame for the visibility value to settle.
   requestAnimationFrame(() => {
      if (getContext(element) != context) return;

      // Add classes to setup the transition.
      element.classList.add(context.startClass, context.activeClass);
      setVisibility({ element, isVisible: true });

      // Allow frames for the DOM to be updated by the setup classes.
      requestAnimationFrames(2, () => {
         if (getContext(element) != context) return;

         if (params.onStart) {
            params.onStart(context.isVisibleAfterTransition);
         }

         // Remove the setup class and add the active class to trigger the animation.
         element.classList.remove(context.startClass);
         element.classList.add(context.endClass);
         requestAnimationFrames(2, () => {
            if (getContext(element) != context) return;

            // Clean up if a transition was never started.
            if (!context.properties.size) {
               setContext(element, null);
               element.classList.remove(context.activeClass, context.endClass);
               setVisibility({
                  element,
                  isVisible: context.isVisibleAfterTransition,
               });
               if (params.onEnd) {
                  params.onEnd(context.isVisibleAfterTransition);
               }
            }
         });
      });
   });
}

function getContext(element: Element): TransitionContext | null {
   return ko.utils.domData.get(element, TRANSITION_CONTEXT) as TransitionContext;
}

function setContext(element: Element, context: TransitionContext | null) {
   ko.utils.domData.set(element, TRANSITION_CONTEXT, context);
}

function getStartClass(isVisible: boolean, name: string) {
   return isVisible ? `${name}--enter` : `${name}--leave`;
}

function getActiveClass(isVisible: boolean, name: string) {
   return isVisible ? `${name}--enter-active` : `${name}--leave-active`;
}

function getEndClass(isVisible: boolean, name: string) {
   return isVisible ? `${name}--enter-to` : `${name}--leave-to`;
}

function isVisible(element: HTMLElement) {
   const style = getComputedStyle(element);
   return style.display != "none" && style.opacity != "0" && style.visibility != "hidden";
}

function setVisibility({ element, isVisible }: { element: HTMLElement; isVisible: boolean }) {
   element.style.display = isVisible ? "" : "none";
}
