import { isContainedElement } from "@/lib/utils/elements";
import type { Observable } from "knockout";
import ko from "knockout";

export type IsHoveredParams = Observable<boolean>;

ko.bindingHandlers.isHovered = {
   init(element: HTMLElement, valueAccessor) {
      const observable = valueAccessor() as IsHoveredParams;

      let isWatchingMouseOut = false;

      // While the hover is active, track the document's mouseout events
      // to solve for a browser bug where removing elements from the DOM
      // does not trigger the 'mouseleave' event properly.
      const onMouseOutWhileActive = (e: MouseEvent) => {
         const targetElement = e.target as HTMLElement;
         if (
            !isContainedElement({
               parent: element,
               child: targetElement,
            })
         ) {
            onMouseLeave();
         }
      };

      const onMouseEnter = () => {
         observable(true);
         if (!isWatchingMouseOut) {
            isWatchingMouseOut = true;
            document.addEventListener("mouseout", onMouseOutWhileActive);
         }
      };

      const onMouseLeave = () => {
         observable(false);
         isWatchingMouseOut = false;
         document.removeEventListener("mouseout", onMouseOutWhileActive);
      };

      element.addEventListener("mouseenter", onMouseEnter);
      element.addEventListener("mouseleave", onMouseLeave);
      ko.utils.domNodeDisposal.addDisposeCallback(element, () => {
         element.removeEventListener("mouseenter", onMouseEnter);
         element.removeEventListener("mouseleave", onMouseLeave);
         document.removeEventListener("mouseout", onMouseOutWhileActive);
      });
   },
};
