import { authManager } from "@/lib/managers/auth-manager"
import { DateUtils } from "@/lib/utils/date"
import { defaultStore } from "@/stores/default-store"
import { Guid as GUID } from "@/lib/utils/guid"
import { Color as ColorUtils } from "@/lib/utils/color"
import { PermissionLevel } from "@/models/permission-level"
import * as d3 from "d3"
import $ from "jquery"
import ko from "knockout"

barPadding = 2 
barHeight = 15
personBarHeight = 35
totalsBarHeight = 28

minimumBarHeight = (barPadding * 2) + totalsBarHeight +  barHeight + personBarHeight;
addPadding = (nodeHeight, isPaddable) ->  nodeHeight +  (if isPaddable then barHeight + barPadding else 0)

updateYAxisSupplier = (yAxis) -> 
   position = yAxis
   return (node) ->
      { height: nodeHeight} = node.getBBox()
      node.setAttribute('transform', "translate(0, #{position})")
      # The y-axis attribute does not affect the location of the element 
      # They are reference only, for popups 
      # and other elements not relative to the location of the page
      node.setAttribute('data-page', position)

      # If the last row is ever considered 'empty' then the row height is the minimumBarHeight
      # If we add padding in this scenario it will cause extra whitespace between people
      # The code below prevents this from occuring. 
      [lastChild] = Array.from(node.children).slice(-1)
      if lastChild
         if lastChild.getBBox().height == minimumBarHeight
            position += addPadding(nodeHeight, false)
         else
            position += addPadding(nodeHeight, true)
      else
         position += addPadding(nodeHeight, true)

updateAllPageYaxis = -> 
   yAxisUpdate = updateYAxisSupplier(0)
   d3.selectAll('.chart > .page').nodes().forEach(yAxisUpdate)

drawGantt = (element, pages, rangeData, appending, options, callbacks) ->
   # Get chart area width
   frameWidth = element.offsetWidth
   frameHeight = $("##{rangeData.graphBaseId}-chart-wrapper").height()
   infoSecWidth = 240

   allocatedGreen = "029e5d"
   allocatedRed = "b5020e"
   # gs-1-color
   allocationGray = "6A6A6A"

   personColorCircleDiameter = 10
   personMorePopupWidth = 180

   todayDetachedDay = DateUtils.getDetachedDay(new Date())

   if rangeData.disableInfoSecion
      infoSecWidth = 0
      chartWidth = frameWidth
   else
      chartWidth = frameWidth - infoSecWidth

   rangeStart = rangeData.startDate

   rangeEnd = new Date(rangeData.startDate.getTime())
   rangeEnd.setDate(rangeStart.getDate() + rangeData.days)

   allowExportingData = authManager.checkAuthAction(PermissionLevel.Action.ALLOW_EXPORTING_DATA)

   # Setup X Axis
   xScale = d3.scaleTime()
   # This needs to be be 1 tick longer so that the labels can be
   # centered and it will still display properly.
   .domain([rangeStart, rangeEnd])
   .range([0, chartWidth])
   .clamp(true)

   # Gets valid temporal values.
   if rangeData.days == 1
      ticks = xScale.ticks(d3.timeHour.every(1))
      gridTicks = xScale.ticks(d3.timeHour.every(1))
   else if rangeData.days < 91
      ticks = xScale.ticks(d3.timeDay.every(1))
      gridTicks = xScale.ticks(d3.timeDay.every(1))
   else if rangeData.days < 182
      ticks = xScale.ticks(d3.timeWeek.every(1))
      gridTicks = xScale.ticks(d3.timeWeek.every(1))
   else if rangeData.days < 730
      ticks = xScale.ticks(d3.timeMonth.every(1))
      gridTicks = xScale.ticks(d3.timeMonth.every(1))
   else
      ticks = xScale.ticks(d3.timeMonth.every(3))
      gridTicks = xScale.ticks(d3.timeMonth.every(1))

   hoursToPixels = (hours) ->
      d1 = new Date(rangeData.startDate.getTime())
      return xScale(d3.timeHour.offset(d1, hours)) - xScale(d1)

   daysToPixels = (days) ->
      d1 = new Date(rangeData.startDate.getTime())
      return xScale(d3.timeDay.offset(d1, days)) - xScale(d1)

   monthsToPixels = (months) ->
      d1 = new Date(rangeData.startDate.getTime())
      return xScale(d3.timeMonth.offset(d1, months)) - xScale(d1)

   unless appending
      resetChart(rangeData.graphBaseId)

      svg = d3.select("##{rangeData.graphBaseId}-chart")
         .append("svg")
         .attr("class", "wrapper")
         .attr("width", frameWidth)
         .attr("height", frameHeight)
         .attr("overflow", "visible")

      # Add profile pic clip path
      circleClip = svg.append("clipPath")
         .attr("id", "circleClip")
         .attr("clipPathUnits", "objectBoundingBox")

      circleClip.append("circle")
         .attr("r", 0.5)
         .attr("cx", 0.5)
         .attr("cy", 0.5)

      # Add short person name clip path
      shortProjectNameClip = svg.append("clipPath")
         .attr("id", "shortProjectNameClip")

      shortProjectNameClip.append("rect")
         .attr("width", infoSecWidth - 22)
         .attr("height", 18)
         .attr("x", 0)
         .attr("y", 0)

      # Add short person name clip path
      longProjectNameClip = svg.append("clipPath")
         .attr("id", "longProjectNameClip")

      longProjectNameClip.append("rect")
         .attr("width", infoSecWidth - 10)
         .attr("height", 18)
         .attr("x", 0)
         .attr("y", 0)

      # # Add short position name clip path
      shortPositionNameClip = svg.append("clipPath")
         .attr("id", "shortPositionNameClip")

      shortPositionNameClip.append("rect")
         .attr("width", 130)
         .attr("height", 18)
         .attr("x", 0)
         .attr("y", 0)

      # Add short position name clip path
      longPositionNameClip = svg.append("clipPath")
         .attr("id", "longPositionNameClip")

      longPositionNameClip.append("rect")
         .attr("width", 170)
         .attr("height", 18)
         .attr("x", 0)
         .attr("y", 0)

      defs = svg.append("defs")
      filter = defs.append("filter")
         .attr("id", "drop-shadow")
         .attr("height", "130%")

      filter.append("feGaussianBlur")
         .attr("in", "SourceAlpha")
         .attr("stdDeviation", 5)

      filter.append("feOffset")
         .attr("in", "blur")
         .attr("dx", 0)
         .attr("dy", 0)

      componentTransfer = filter.append("feComponentTransfer")

      componentTransfer.append("feFuncA")
         .attr("type", "linear")
         .attr("slope","0.6")

      feMerge = filter.append("feMerge")

      feMerge.append("feMergeNode")
         .attr("in", "offsetBlur")

      feMerge.append("feMergeNode")
         .attr("in", "SourceGraphic")

      colorWeekends = (selection) ->
         if 1 < rangeData.days < 91
            selection.selectAll('.tick').each (data) ->
               detachedDay = DateUtils.getDetachedDay(data)
               day = data.getDay()
               if day == 0 or day == 6
                  d3.select(@).append("rect")
                     .attr("class", "weekend-bg")
                     .attr("height", frameHeight)
                     .attr("width", daysToPixels(1))
                     .attr("fill", "black")
                     .attr("opacity", "0.2")

               if detachedDay == todayDetachedDay
                  d3.select(@).append("rect")
                     .attr("class", "today-bg")
                     .attr("height", 20)
                     .attr("width", daysToPixels(1))
                     .attr("fill", "blue")
                     .attr("opacity", "0.3")

         else if 91 <= rangeData.days < 182
            # Weekly
            thisWeekStartDate = DateUtils.getAttachedDate(todayDetachedDay)
            thisWeekStartMs = thisWeekStartDate.setDate(thisWeekStartDate.getDate() - thisWeekStartDate.getDay())
            thisWeeksStartingDay = DateUtils.getDetachedDay(new Date(thisWeekStartMs))

            selection.selectAll('.tick').each (data) ->
               # Decouple
               weekStartDate = new Date(data.getTime())
               weekStartMs = weekStartDate.setDate(weekStartDate.getDate() - weekStartDate.getDay())
               weeksStartingDay = DateUtils.getDetachedDay(new Date(weekStartMs))

               if weeksStartingDay == thisWeeksStartingDay
                  d3.select(@).append("rect")
                     .attr("class", "today-bg")
                     .attr("height", 20)
                     .attr("width", daysToPixels(7))
                     .attr("fill", "blue")
                     .attr("opacity", "0.3")
         else if 182 <= rangeData.days
            # Monthly
            thisMonthChunk = String(todayDetachedDay).slice(0, -2)

            selection.selectAll('.tick').each (data) ->
               detachedDay = DateUtils.getDetachedDay(data)
               monthChunk = String(detachedDay).slice(0, -2)
               if monthChunk == thisMonthChunk
                  sectionStart = xScale(data)
               
                  nextTickDate = new Date(data.getTime())
                  nextTickDate.setMonth(nextTickDate.getMonth() + 1)
                  nextTickDate.setDate(1)
                  sectionEnd = xScale(nextTickDate)

                  offset = (sectionEnd - sectionStart)

                  d3.select(@).append("rect")
                     .attr("class", "today-bg")
                     .attr("height", 20)
                     .attr("width", offset)
                     .attr("fill", "orange")
                     .attr("opacity", "0.3")
         

      # Grid lines
      gridlines = d3.axisTop()
        .tickFormat("")
        .tickSize(-frameHeight)
        .scale(xScale)

      gridlines.tickValues(gridTicks)

      svg.append("g")
         .attr("class", "grid")
         .attr("transform", "translate(#{infoSecWidth},0)")
         .call(gridlines)
         .call(colorWeekends)


      chart = svg.append("g")
         .attr("class", "chart")
         .attr("transform", "translate(0,0)")

   if appending
      # When appending these variables have not been instantiated
      svg = d3.select('.wrapper')
      chart = d3.select('.chart')
      
   pagesSelection = chart.selectAll('.page')
      .data pages, (d) -> d.id
   
   pagesSelection.exit().remove()

   pagesEnter = pagesSelection.enter()
      .append("g")
      .attr "class", "page"

   pagesEnter.order()

   row = pagesEnter.selectAll(".row")
   .data (d) -> 
      return d.rows
   .enter().append("svg")
      .attr("class", "row people-gantt__person-row")
      .attr("x", 0)
      # Mult bar + padding for every teir before and add the total row buffer (1 barHeight)
      # for each total row before.
      .attr("y", (d) ->
         # With 1 bar height padding between projects.
         yPadding = d.leadingPeople * ((personBarHeight + barPadding) + barHeight)
         yPadding += d.leadingPeople * (totalsBarHeight + barPadding)
         yPadding += d.leadingAssBars * (barHeight + barPadding)
         yPadding += d.leadingToBars * (barHeight + barPadding)
         return yPadding
      )
      .attr("width", frameWidth)
      .attr("height", (d) -> 
         personRowHeight = ((personBarHeight + barPadding) + barHeight)
         personRowHeight += (totalsBarHeight + barPadding)
         personRowHeight += d.containedAssBars * (barHeight + barPadding)
         personRowHeight += d.containedToBars * (barHeight + barPadding)
         return personRowHeight
      )

   # Append person name bar
   personBar = row.append("g")
      .attr("transform", "translate(0,0)")

   personBar.append("rect")
      .attr("class", "people-gantt__person-title-bar")
      .attr("width", "100%")
      .attr("height", personBarHeight)

   personBar.append("circle")
      .attr("visibility", (d) => return if d.positionColor? then "visible" else "hidden")
      .attr("class", "people-gantt__person-title-bar-color")
      .attr("fill", (d) -> return d.positionColor)
      .attr("width", (d) -> return if d.positionColor then personColorCircleDiameter else 0)
      .attr("height", (d) -> return if d.positionColor then personColorCircleDiameter else 0)
      .attr("cx", 16)
      .attr("cy", personBarHeight / 2)
      .attr("r", 5)

   personBar.append("text")
      .attr("class", "people-gantt__person-bar-title")
      .text (d) ->
         text = d.personName
         if rangeData.peopleViewConfig.showEmployeeNumber and d.employeeNumber?
            text += " - #{d.employeeNumber}"
         if rangeData.peopleViewConfig.showPositionName and d.positionName?
            text += " - #{d.positionName}"
         return text

      .attr("font-family", "OpenSans")
      .attr("font-size", "16px")
      .attr("fill", "white")
      .attr "x", (d) ->
         if d.positionColor?
            return 10 + personColorCircleDiameter + 10
         return 10
      .attr("y", 14 + ((personBarHeight - 16) / 2))
      .on "click", (d) ->
            callbacks.infoSectionClicked(d, d3.event)
   if allowExportingData
      personBarMoreBtn = personBar.append("g")
         .attr("transform", "translate(#{frameWidth - 39},0)")
         .attr("class", "people-gantt__person-bar__more-btn")

      drawPersonMorePopup = (d, parentPage) ->
         height = 26
         moreOptRows = 0
         popup = chart.append("g")
            .attr("class", "people-gantt__more-popup")
            .attr("width", personMorePopupWidth)
            .attr("height", height)
            .attr("fill", "black")
            .attr "transform", ->
               if d?
                  yAxis = parseInt(parentPage.getAttribute('data-page'))
                  yPadding = yAxis
                  yPadding += d.leadingPeople * ((personBarHeight + barPadding) + barHeight)
                  yPadding += d.leadingPeople * (totalsBarHeight + barPadding)
                  yPadding += d.leadingAssBars * (barHeight + barPadding)
                  yPadding += d.leadingToBars * (barHeight + barPadding)
                  yPadding += personBarHeight
               return "translate(#{frameWidth - (personMorePopupWidth + 18)},#{yPadding})"
         popup.append("polygon")
            .attr("class", "people-gantt__more-popup-bg")
            .attr("points", "0,0 #{personMorePopupWidth - 20},0 #{personMorePopupWidth - 10},-10, #{personMorePopupWidth},0 #{personMorePopupWidth},#{height} 0,#{height}")
            .attr("fill","white")
            .attr("stroke", "black")
            .style("filter", "url(#drop-shadow)")
         popup.append("text")
            .attr("class", "people-gantt__more-popup__row-text")
            .text("Export Report")
            .attr("font-family", "OpenSans")
            .attr("font-size", "12px")
            .attr("transform", "translate( 15,#{18 + (moreOptRows * 26)})")
         popup.append("rect")
            .attr("class", "people-gantt__more-popup__row")
            .attr("width", personMorePopupWidth)
            .attr("height", "26")
            .attr("transform", "translate(0,#{moreOptRows * 26})")
            .on "click", ->
               callbacks.showGeneratePersonReportModal(d)
         moreOptRows++

         popup.append("line")
            .attr("class", "people-gantt__more-popup__row-delimiter")
            .attr("x1", "0")
            .attr("y1", "#{moreOptRows * 26}")
            .attr("x2", personMorePopupWidth)
            .attr("y2", "#{moreOptRows * 26}")
            .attr("stroke", "black")
         allowedPopupClasses = [
            'people-gantt__person-bar__more-btn'
            'people-gantt__person-bar__more-btn-bg'
            'people-gantt__person-bar__more-btn-dot'
            'people-gantt__more-popup__row-delimiter'
         ]
         clickHandler = (data) ->
            if data.toElement?
               clickedElement = data.toElement
            else if data.target?
               clickedElement = data.target

            foundMatch = false
            for name in clickedElement.classList
               foundMatch = true if allowedPopupClasses.indexOf(name) != -1

            unless foundMatch
               document.removeEventListener("click", clickHandler)
               svg.selectAll(".people-gantt__more-popup").remove()

         document.addEventListener("click", clickHandler)

      personBarMoreBtn.append("rect")
         .attr("class", "people-gantt__person-bar__more-btn-bg")
         .attr("width", "24")
         .attr("height", personBarHeight)
         .on "click", (d) ->
            drawPersonMorePopup(d, row.node().parentNode, d3.event)
      
      moreCirclePosition = 3
      incrementPopupBy = 9
      # eslint-disable-next-line no-unused-vars
      for count in [0...3]
         personBarMoreBtn.append("circle")
            .attr("class", "people-gantt__person-bar__more-btn-dot")
            .attr("cx", moreCirclePosition)
            .attr("cy", personBarHeight / 2)
            .attr("r", 3)
            .attr("fill", "white")
            .on "click", (d) ->
               drawPersonMorePopup(d, row.node().parentNode, d3.event)
         moreCirclePosition += incrementPopupBy

   assignmentsHeader = row.append("g")
      .attr("class", "project-gantt__assignments-header")
      .attr "transform", () ->
         return "translate(0,#{personBarHeight})"

   assignmentsHeader.append("rect")
      .attr("class", "project-gantt_assignments-line")
      .attr("width", "100%")
      .attr("height", 20)
      .attr("fill", "black")
      .attr("x", 0)
      .attr("y", 0)

   assignmentsHeader.append("text")
      .filter (d) -> d.projects.length > 0 
      .attr("class", "project-gantt__assignments-title")
      .text () ->
         unless rangeData.peopleViewConfig.totalsUnit == "allocation"
            parentData = d3.select(@.parentNode).datum()
            if rangeData.days < 91
               # Daily
               if rangeData.peopleViewConfig.totalsUnit == "cost"
                  totalsValue = 0
                  for key, val of parentData.personsDailyCost
                     totalsValue += val
               else
                  totalsValue = 0
                  for key, val of parentData.personsDailyHours
                     totalsValue += val
            else if 91 <= rangeData.days < 182
               # Weekly
               if rangeData.peopleViewConfig.totalsUnit == "cost"
                  totalsValue = 0
                  for key, val of parentData.personsDailyCost
                     totalsValue += val.count
               else
                  totalsValue = 0
                  for key, val of parentData.personsDailyHours
                     totalsValue += val.count

            else
               # Months
               if rangeData.peopleViewConfig.totalsUnit == "cost"
                  totalsValue = 0
                  for key, val of parentData.personsDailyCost
                     if typeof val == "object"
                        totalsValue += val.count
                     else
                        totalsValue += val
               else
                  totalsValue = 0
                  for key, val of parentData.personsDailyHours
                     totalsValue += val.count

            totalsValue = '' if totalsValue == 0

            if rangeData.peopleViewConfig.totalsUnit == "man-days" and totalsValue != ''
               # TODO: Update if we change what a man-day means to be configurable.
               # Forcing max 1 decimal.
               totalsValue = Math.round( (totalsValue / 8) * 10) / 10

            if totalsValue != '' and totalsValue != 0
               if totalsValue < 999
                  totalsValue = Math.round(totalsValue)
               else if 999 < totalsValue < 9999
                  totalsValue = "#{Math.round((totalsValue / 1000) * 10) / 10}K"
               else if 9999 < totalsValue < 99999
                  totalsValue = "#{Math.round((totalsValue / 1000) * 10) / 10}K"
               else if 99999 < totalsValue < 999999
                  totalsValue = "#{Math.round(((totalsValue / 1000) * 10) / 10)}K"
               else if 999999 < totalsValue
                  totalsValue = "#{Math.round((totalsValue / 1000000) * 10) / 10}M"

               if rangeData.peopleViewConfig.totalsUnit == "cost"
                  totalsValue = "$#{totalsValue}"

               formattedRollupValue = if totalsValue != '' then "(#{totalsValue})" else ''
            else
               formattedRollupValue = ''

         return switch rangeData.peopleViewConfig.totalsUnit
            when "allocation" then "Assignments - Allocation %" 
            when "man-days" then "Assignments - Man Days #{formattedRollupValue}"
            when "hours" then "Assignments - Hours #{formattedRollupValue}"
            when "cost" then "Assignments - Cost #{formattedRollupValue}"
         
      .attr("font-family", "OpenSans")
      .attr("font-size", "12px")
      .attr("fill", "black")
      .attr("x", 10)
      .attr("y", 15)

   row.append("g")
      .attr("class", "assignment-total-bar")
      .attr("x", 0)
      .attr("width", chartWidth)
      .attr("height", (d) -> if d.containedAssBars > 0 then barHeight else 0)
      .attr("transform", "translate(0,#{personBarHeight + 1})")

   row.append("rect")
      .attr("class", "row-bg")
      .attr("x", infoSecWidth)
      .attr("width", chartWidth)
      .attr("height", "100%")
      .style("pointer-events","none")

   projectSection = row.selectAll(".row")
   .data((d) -> return d.projects)
   .enter().append("g")
   .attr("class", "people-gantt__person-row-wrapper")
   .attr "transform", (d) ->
      # Project bar and total bar since we have assignments.
      yPadding = (personBarHeight + barPadding) + (totalsBarHeight + barPadding)
      yPadding += d.leadingAssBars * (barHeight + barPadding)
      yPadding += d.leadingToBars * (barHeight + barPadding)

      return "translate(0,#{yPadding})"

   projectSection.append("rect")
      .attr("class", "people-gantt_person-row")
      .attr("rx", 2)
      .attr("ry", 2)
      .attr("width", "100%")
      .attr "height", (d) ->
         return d.containedAssBars * (barHeight + barPadding)
      .attr("opacity", 0)
      .attr("x", 0)

   projectSection.append("circle")
      .filter (d) -> 
         return d.projectColor?
      .attr("class", "project-gantt__person-row__color-dot")
      .attr("cx", 14)
      .attr("cy", (barHeight / 2) + 1)
      .attr("r", 4)
      .attr "fill", (d) ->
         return d.projectColor

   projectSection.append("text")
      .attr("class", (d) =>
         hasGroupAccess = authManager.isAdmin() or d.projectGroupIds?.some((groupId) =>
            authManager.getContextAccessibleGroupIds().has(groupId)
         )
         if !options.permissions.canViewProject || d.isRestrictedProject || !hasGroupAccess
            return "people-gantt__restricted-project-title-bar"
         else
            return "people-gantt__project-row-title"
      )
      .text (d) -> 
         text = ''
         if rangeData.peopleViewConfig.showProjectNumbers and d.jobNumber
            text += "[#{d.jobNumber}] - "
         text += d.projectName
         return text
      .attr("font-family", "OpenSans")
      .attr("font-size", "12px")
      .attr("font-style", (d) =>
         if d.isRestrictedProject
            return "italic"
         else
            return "normal"
      )
      .attr("fill", "black")
      .attr "x", (d) -> if d.projectColor? then 22 else 10
      .attr("y", 13)
      .attr("clip-path", (d) ->
         if d.projectColor?
            return "url(#shortProjectNameClip)"
         else
            return "url(#longProjectNameClip)"
      )

      .on("click", (d) =>
         hasGroupAccess = authManager.isAdmin() or d.projectGroupIds?.some((groupId) =>
            authManager.getContextAccessibleGroupIds().has(groupId)
         )
         if !options.permissions.canViewProject || d.isRestrictedProject || !hasGroupAccess
            return
         callbacks.navigateToProject(d.projectId)
      )

   getElementWidth = (d) ->
      # Need Times for single day.
      if rangeData.days == 1
         d.start = new Date(rangeData.startDate.getTime())
         d.end = new Date(rangeData.startDate.getTime())

         startTimeChunks = String(d.startTime).split(".")
         d.start.setHours(startTimeChunks[0])
         if startTimeChunks[1]?
            # Catches "5"
            startTimeChunks[1] = "#{startTimeChunks[1]}0" if startTimeChunks[1].length == 1
            d.start.setMinutes(60 * (Number(startTimeChunks[1]) / 100))

         if d.endTime > d.startTime
            endTimeChunks = String(d.endTime).split(".")
            d.end.setHours(endTimeChunks[0])
            if endTimeChunks[1]?
               endTimeChunks[1] = "#{endTimeChunks[1]}0" if endTimeChunks[1].length == 1
               d.end.setMinutes(60 * (Number(endTimeChunks[1]) / 100))
         else
            # Overnight
            # Night Before
            if d.singleDay? and d.singleDay < DateUtils.getDetachedDay(rangeData.startDate)
               d.start.setHours(0)
               d.start.setMinutes(0)
               endTimeChunks = String(d.endTime).split(".")
               d.end.setHours(endTimeChunks[0])
               if endTimeChunks[1]?
                  endTimeChunks[1] = "#{endTimeChunks[1]}0" if endTimeChunks[1].length == 1
                  d.end.setMinutes(60 * (Number(endTimeChunks[1]) / 100))
            else
               d.end.setHours(39)
               d.end.setMinutes(59)

      else
         # Set start/end back
         d.start = DateUtils.getAttachedDate(d.startDay)
         d.end = DateUtils.getAttachedDate(d.endDay + 1)

      return xScale(d.end) - xScale(d.start)

   getXDisplacement = (d) ->
      return xScale(d.start)

   timeoffSection = row.selectAll(".row")
   .data((d) -> return d.timeoff)
   .enter().append("g")
   .attr("class", "people-gantt__person-row-wrapper")
   .attr "transform", (d) ->
      yPadding = (personBarHeight + barPadding) + (totalsBarHeight + barPadding)
      yPadding += (d.tier * (barHeight + barPadding))
      return "translate(0,#{yPadding})"

   timeoffSection.append("rect")
      .attr("class", "people-gantt_person-row")
      .attr("rx", 2)
      .attr("ry", 2)
      .attr("width", "100%")
      .attr "height", () ->
         return (barHeight + barPadding)
      .attr("opacity", 0)
      .attr("x", 0)

   timeoffSection.append("text")
      .attr("class", "project-gantt__timeoff-row-title")
      .text (d) -> 
         text = "T.O. - #{d.reason.charAt(0).toUpperCase() + d.reason.slice(1);}"
         return text
      .attr("font-family", "OpenSans")
      .attr("font-size", "12px")
      .attr("fill", "black")
      .attr("x", 10)
      .attr("y", 13)
      .attr("clip-path", () ->
         return "url(#longProjectNameClip)"
      )

   timeOffBar = timeoffSection.selectAll("people-gantt__person-row-wrapper")
      .data((d) ->
         viewEndDate = DateUtils.incrementDate(rangeData.startDate, rangeData.days)
         timeOffInstances = d.instances.filter((timeOff) ->
            return timeOff.start < viewEndDate && timeOff.end >= rangeData.startDate
         )
         return timeOffInstances
      )
      .enter().append("rect")
         .attr("class", "to-bar")
         .attr("rx", 2)
         .attr("ry", 2)
         # Get the temporal width and subject padding to make gaps between assignments.
         .attr("width", (d) -> return getElementWidth(d) - (barPadding * 2))
         .attr("height", barHeight)
         # Get horizonal displacement and move over by 1 padding to center.
         .attr("x", (d) -> return getXDisplacement(d) + barPadding + infoSecWidth)
         .attr("y", 0)
         .style("fill", "url(#crosshatch)")

   timeOffBar.on "click", (d) ->
      callbacks.timeOffClicked(d, d3.event)

   if callbacks.assignmentMouseOver
      timeOffBar.on "mouseover", (d) ->
         callbacks.assignmentMouseOver(d)

      timeOffBar.on "mouseout", (d) ->
         callbacks.assignmentMouseOut(d)

   getAssignmentChunks = (inputAssignments) ->
      viewEndDate = DateUtils.incrementDate(rangeData.startDate, rangeData.days)
      assignments = inputAssignments.filter((assignment) ->
         return assignment.start < viewEndDate && assignment.end >= rangeData.startDate
      )
      return [] unless assignments? and assignments.length > 0

      if rangeData.peopleViewConfig.barSplit == "split"
         chunks = []
         
         pendingChunks = []

         processingIndex = 0

         filterAndReturnChunks = ->
            for pendingChunk in pendingChunks
               chunks = chunks.filter (item) ->
                  if item.batchId == pendingChunk.batchId
                     return true if item.isPending
                     if (item.startDay == pendingChunk.originalStartDay and 
                     item.endDay == pendingChunk.originalEndDay)
                        return false
                     else
                        return true
                  else
                     return true

            return chunks

         processAssignmentsInstances = ->
            workingAssignment = assignments[processingIndex]

            unless workingAssignment?
               if processingIndex >= assignments.length
                  return filterAndReturnChunks()
               else
                  processingIndex++
                  processAssignmentsInstances()

            # TODO: This start/end date should be truncated to viewing range to save loops.
            workDays = workingAssignment.workDays
            startDay = Number(workingAssignment.startDay)
            endDay = Number(workingAssignment.endDay)

            processInstance = (processingDate) ->
               validDetachedDays = []
               foundInstanceStart = false
               processingInstance = true
               processingDay = null

               while processingInstance
                  processingDay = DateUtils.getDetachedDay(processingDate)

                  working = workDays[processingDate.getDay()]

                  if !working and foundInstanceStart
                     processingInstance = false
                     break
                  else if working and !foundInstanceStart
                     foundInstanceStart = true

                  validDetachedDays.push(processingDay) if working

                  processingDate = DateUtils.incrementDate(processingDate, 1)
                  if processingDay >= endDay
                     processingInstance = false
                     break

               if validDetachedDays.length > 0
                  chunk = {
                     startDay: validDetachedDays[0]
                     start: DateUtils.getAttachedDate(validDetachedDays[0])
                     endDay: validDetachedDays[validDetachedDays.length - 1]
                     end: DateUtils.getAttachedDate(validDetachedDays[validDetachedDays.length - 1])

                     leadingAssBars: workingAssignment.leadingAssBars

                     color: workingAssignment.color
                     batchStartDay: workingAssignment.batchStartDay
                     batchEndDay: workingAssignment.batchEndDay
                     singleDay: null
                     groupIds: workingAssignment.groupIds
                     projectName: workingAssignment.projectName
                     jobNumber: workingAssignment.jobNumber
                     projectId: workingAssignment.projectId
                     projectStatus: workingAssignment.projectStatus
                     costCodeName: workingAssignment.costCodeName
                     costCodeId: workingAssignment.costCodeId
                     labelName: workingAssignment.labelName
                     labelId: workingAssignment.labelId
                     startTime: workingAssignment.startTime
                     endTime: workingAssignment.endTime
                     workDays: workingAssignment.workDays
                     resourceId: workingAssignment.resourceId
                     instanceId: null
                     batchId: workingAssignment.batchId
                     tier: workingAssignment.tier
                     overtime: workingAssignment.overtime
                     paySplit: workingAssignment.paySplit or null
                     overtimeRates: workingAssignment.overtimeRates or null
                     percentAllocated: workingAssignment.percentAllocated or null
                     isPending: workingAssignment.isPending or false
                     isRestrictedProject: workingAssignment.isRestrictedProject
                     status: workingAssignment.status
                     statusId: workingAssignment.statusId
                     statusName: workingAssignment.statusName
                     # This is just for ADM pending working.
                     originalStartDay: workingAssignment.originalStartDay
                     originalEndDay: workingAssignment.originalEndDay
                  }

                  if chunk.start < viewEndDate && chunk.end >= rangeData.startDate
                     chunks.push(chunk)
                     pendingChunks.push(chunk) if chunk.isPending

               if DateUtils.getDetachedDay(processingDate) > endDay
                  if processingIndex >= assignments.length
                     return filterAndReturnChunks()
                  else
                     processingIndex++
                     processAssignmentsInstances()

               else
                  # May need to put a setTimeout here to prevent stack opverflow on long runs.
                  return processInstance(processingDate)

            processInstance(DateUtils.getAttachedDate(startDay))
            
         processAssignmentsInstances()
      else
         for workingAssignment in assignments
            workingAssignment['start'] = DateUtils.getAttachedDate(Number(workingAssignment.startDay))
            workingAssignment['end'] = DateUtils.getAttachedDate(Number(workingAssignment.endDay))
         return assignments
         
   # Assignment Bars
   assignmentBar = projectSection.selectAll("people-gantt__person-row-wrapper")
   .data((d) -> getAssignmentChunks(d.assignments))
   .enter().append("rect")
      .attr "class", (d) ->
         if d.isRestrictedProject
            return "to-bar"
         return if d.isPending then "bar bar--pending" else "bar"
      .attr("rx", 2)
      .attr("ry", 2)
      # Get the temporal width and subject padding to make gaps between assignments.
      .attr("width", (d) ->
         if rangeData.days == 182
            return getElementWidth(d) - 2
         else if rangeData.days > 182
            return getElementWidth(d)
         else
            ewidth = getElementWidth(d)
            return ewidth - (barPadding * 2)
      )
      .attr("height", barHeight)
      # Get horizonal displacement and move over by 1 padding to center.
      .attr("x", (d) -> return getXDisplacement(d) + barPadding + infoSecWidth)
      .attr "y", (d) ->
         return d.leadingAssBars * (barHeight + barPadding)
         
      .attr "fill", (d) ->
         if d.isRestrictedProject
            return "url(#crosshatch)"
         if rangeData.peopleViewConfig.barColor == "project-color"
            return d.color or "#40464b"
         else if rangeData.peopleViewConfig.barColor == "allocation"
            if d.percentAllocated?
               allocation = d.percentAllocated
            else
               assignmentHours = DateUtils.getDurationHours(d.startTime, d.endTime)
               if assignmentHours >= rangeData.paidShiftHours and !d.overtime
                  allocation = 100
               else
                  allocation = (assignmentHours / rangeData.paidShiftHours) * 100

            if Number(allocation) == 0
               assBarColor = allocationGray
            else if Number(allocation) == 100
               assBarColor = allocatedGreen
            else if Number(allocation) >= 200
               assBarColor = allocatedRed
            else if allocation < 100
               assBarColor = ColorUtils.blendColors("ffffff", allocatedGreen, allocation)
            else
               # between 101 - 199%
               assBarColor = ColorUtils.blendColors("fab9bd", allocatedRed, (allocation % 100))

            return "##{assBarColor}"
         else if rangeData.peopleViewConfig.barColor == "overtime"
            return if d.overtime then "#ed5858" else "#40cc91"

         else if rangeData.peopleViewConfig.barColor == "status"
            if d.status?
               return ko.unwrap(d.status.color) or "black"
            else
               return "black"

      .on "click", (d) ->
         d3.select(@)
         callbacks.assignmentClicked(d, d3.event)

      .on "mouseover", () ->
         # Add classes to element.
         thisBar = d3.select(@)
         thisBar.attr("class", "#{thisBar.attr("class")} gantt-bar-hover")

      .on "mouseout", () ->
         # Reset classes on element.
         thisBar = d3.select(@)
         thisBar.attr("class", thisBar.attr("class").replace("gantt-bar-hover", ""))

   if callbacks.assignmentMouseOver
      assignmentBar.on "mouseover", (d) ->
         callbacks.assignmentMouseOver(d)

      assignmentBar.on "mouseout", (d) ->
         callbacks.assignmentMouseOut(d)

   adjustTextLabels = (selection) ->
      if 91 <= rangeData.days < 182
         thisWeekStartDate = DateUtils.getAttachedDate(todayDetachedDay)
         thisWeekStartMs = thisWeekStartDate.setDate(thisWeekStartDate.getDate() - thisWeekStartDate.getDay())
         thisWeeksStartingDay = DateUtils.getDetachedDay(new Date(thisWeekStartMs))
      else if 182 <= rangeData.days
         thisMonthChunk = String(todayDetachedDay).slice(0, -2)

      selection.selectAll('.tick').each (data) ->
         if 1 < rangeData.days < 91
            detachedDay = DateUtils.getDetachedDay(data)
            day = data.getDay()
            if day == 0 or day == 6
               d3.select(@).append("rect")
                  .attr("class", "axis-weekend-bg")
                  .attr("height", 20)
                  .attr("width", daysToPixels(1))
                  .attr("fill", "black")
                  .attr("opacity", "0.2")

            if detachedDay == todayDetachedDay
               d3.select(@).append("rect")
                  .attr("class", "axis-today-bg")
                  .attr("height", 20)
                  .attr("width", daysToPixels(1))
                  .attr("fill", "orange")
                  .attr("opacity", "0.3")

         else if 91 <= rangeData.days < 182
            # Weekly
            # Decouple
            weekStartDate = new Date(data.getTime())
            weekStartMs = weekStartDate.setDate(weekStartDate.getDate() - weekStartDate.getDay())
            weeksStartingDay = DateUtils.getDetachedDay(new Date(weekStartMs))

            if weeksStartingDay == thisWeeksStartingDay
               d3.select(@).append("rect")
                  .attr("class", "axis-today-bg")
                  .attr("height", 20)
                  .attr("width", daysToPixels(7))
                  .attr("fill", "orange")
                  .attr("opacity", "0.3")

         else if 182 <= rangeData.days
            detachedDay = DateUtils.getDetachedDay(data)
            monthChunk = String(detachedDay).slice(0, -2)

            if monthChunk == thisMonthChunk
               sectionStart = xScale(data)
               
               nextTickDate = new Date(data.getTime())
               nextTickDate.setMonth(nextTickDate.getMonth() + 1)
               nextTickDate.setDate(1)
               sectionEnd = xScale(nextTickDate)

               offset = (sectionEnd - sectionStart)

               d3.select(@).append("rect")
                  .attr("class", "axis-today-bg")
                  .attr("height", 20)
                  .attr("width", offset)
                  .attr("fill", "orange")
                  .attr("opacity", "0.3")

         if rangeData.days == 1
            xDelta = hoursToPixels(1)
         else if rangeData.days < 91
            xDelta = daysToPixels(1)
         else if rangeData.days < 182
            # TODO: evaluate per tick calculation to handle non-whole segmeents (like secondary axis).
            xDelta = daysToPixels(7)
         else if rangeData.days < 730
            # TODO: evaluate per tick calculation to handle non-whole segmeents (like secondary axis).
            xDelta = monthsToPixels(1)
         else
            xDelta = monthsToPixels(3)

         d3.select(@).selectAll('text')
            .attr('transform', 'translate(' + xDelta / 2 + ',-14)')

   adjustSecondaryTextLabels = (selection) ->
      selection.selectAll('.tick').each (data) ->
         sectionStart = xScale(data)
         if rangeData.days < 182
            nextTickDate = new Date(data.getTime())
            nextTickDate.setMonth(nextTickDate.getMonth() + 1)
            nextTickDate.setDate(1)
            sectionEnd = xScale(nextTickDate)
         else
            nextTickDate = new Date(data.getTime())
            nextTickDate.setYear(nextTickDate.getFullYear() + 1)
            nextTickDate.setMonth(0)
            nextTickDate.setDate(1)
            sectionEnd = xScale(nextTickDate)

         offset = (sectionEnd - sectionStart) / 2
         d3.select(@).selectAll('text')
            .attr('transform', "translate(#{offset},-14)")

   adjustTotalsLabels = (selection) ->
      selection.selectAll('.tick').each (data) ->
         if 1 <= rangeData.days < 91
            day = data.getDay()
            if day == 0 or day == 6
               d3.select(@).append("rect")
                  .attr("class", "axis-weekend-bg")
                  .attr("height", 20)
                  .attr("width", daysToPixels(1))
                  .attr("fill", "black")
                  .attr("opacity", "0.2")

         if rangeData.days < 91
            xDelta = daysToPixels(1)
         else if rangeData.days < 182
            # TODO: evaluate per tick calculation to handle non-whole segmeents (like secondary axis).
            xDelta = daysToPixels(7)
         else if rangeData.days < 730
            if rangeData.peopleViewConfig.totalsUnit == "allocation"
               xDelta = daysToPixels(7)
            else
            # TODO: evaluate per tick calculation to handle non-whole segmeents (like secondary axis).
               xDelta = monthsToPixels(1)
         else
            if rangeData.peopleViewConfig.totalsUnit == "allocation"
               xDelta = monthsToPixels(1)
            else
               xDelta = monthsToPixels(3)

         parentData = d3.select(@.parentNode).datum()
         day = DateUtils.getDetachedDay(data)
         totalsValue = 0

         if rangeData.days < 91
            # Daily
            if rangeData.peopleViewConfig.totalsUnit == "cost"
               totalsData = parentData.personsDailyCost
               totalsValue = totalsData[day] or 0
            else if rangeData.peopleViewConfig.totalsUnit == "allocation"
               totalsData = parentData.personsDailyHours
               if totalsData[day]?
                  totalsValue = (totalsData[day] / rangeData.paidShiftHours) * 100
               else
                  totalsValue = 0
            else
               totalsData = parentData.personsDailyHours
               totalsValue = totalsData[day] or 0

         else if rangeData.days < 182
            # Weekly
            if rangeData.peopleViewConfig.totalsUnit == "cost"
               totalsData = parentData.personsDailyCost
               totalsValue = 0
               for key, val of totalsData
                  if val.weekStart == day
                     totalsValue += val.count
            else if rangeData.peopleViewConfig.totalsUnit == "allocation"
               totalsData = parentData.personsDailyHours
               totalsValue = 0
               for key, val of totalsData
                  if val.weekStart == day
                     totalsValue += val.count
               totalsValue = (totalsValue / (rangeData.paidShiftHours * 5)) * 100
            else
               totalsData = parentData.personsDailyHours
               totalsValue = 0
               for key, val of totalsData
                  if val.weekStart == day
                     totalsValue += val.count

         else if rangeData.days < 730
            # Months
            monthChunk = String(day).slice(0, -2)

            if rangeData.peopleViewConfig.totalsUnit == "cost"
               totalsData = parentData.personsDailyCost
               totalsValue = 0
               for key, val of totalsData
                  if String(key).slice(0, -2) == monthChunk
                     totalsValue += val.count
            else if rangeData.peopleViewConfig.totalsUnit == "allocation"
               # Tabulating like weeks because we are slicing it up further.
               totalsData = parentData.personsDailyHours
               totalsValue = 0
               for key, val of totalsData
                  if val.weekStart == day
                     totalsValue += val.count
               totalsValue = (totalsValue / (rangeData.paidShiftHours * 5)) * 100
            else
               totalsData = parentData.personsDailyHours
               totalsValue = 0
               for key, val of totalsData
                  if String(key).slice(0, -2) == monthChunk
                     totalsValue += val.count
         else
            # Quarterly
            processingQuarter = Math.ceil((Number(String(day).slice(4, 6)) + 1) / 3)
            yearQuarter = "#{String(day).slice(0, 4)}-#{processingQuarter}"

            if rangeData.peopleViewConfig.totalsUnit == "cost"
               totalsData = parentData.personsDailyCost
               totalsValue = 0
               for key, val of totalsData
                  if "#{String(key).slice(0, 4)}-#{Math.ceil((Number(String(key).slice(4, 6)) + 1) / 3)}" == yearQuarter
                     totalsValue += val
            else if rangeData.peopleViewConfig.totalsUnit == "allocation"
               # Tabulating like weeks because we are slicing it up further.
               dayString = String(day)
               monthChunk = dayString.slice(0, -2)
               monthsWorkDays = DateUtils.getMonthsWorkDaysCount(Number(dayString.slice(0,4)), Number(dayString.slice(4,6)))
               totalsData = parentData.personsDailyHours
               totalsValue = 0
               for key, val of totalsData
                  if String(key).slice(0, -2) == monthChunk
                     totalsValue += val
               totalsValue = (totalsValue / (rangeData.paidShiftHours * monthsWorkDays)) * 100
            else
               totalsData = parentData.personsDailyHours
               totalsValue = 0
               for key, val of totalsData
                  if "#{String(key).slice(0, 4)}-#{Math.ceil((Number(String(key).slice(4, 6)) + 1) / 3)}" == yearQuarter
                     totalsValue += val

         unless rangeData.peopleViewConfig.totalsUnit == "allocation"
            totalsValue = '' if totalsValue == 0

         if rangeData.peopleViewConfig.totalsUnit == "man-days" and totalsValue != ''
            # TODO: Update if we change what a man-day means to be configurable.
            # Forcing max 1 decimal.
            totalsValue = Math.round( (totalsValue / 8) * 10) / 10

         tickFont = "11px OpenSans"
         if rangeData.days < 91
            if xDelta < 30
               # Short format.
               tickFont = "8px OpenSans"
               if totalsValue != ''
                  if totalsValue < 999
                     totalsValue = Math.round(totalsValue)
                  else if totalsValue < 9999
                     totalsValue = "#{Math.round((totalsValue / 1000) * 10) / 10}K"
                  else if totalsValue < 99999
                     totalsValue = "#{Math.round((totalsValue / 1000) * 10) / 10}K"
                  else if totalsValue < 999999
                     totalsValue = "#{Math.round((totalsValue / 1000000) * 10) / 10}M"
            else
               # Long format.
               totalsValue = String(Math.round(totalsValue)).replace(/\B(?=(\d{3})+(?!\d))/g, ",")
         else if rangeData.peopleViewConfig.totalsUnit == "allocation" and 
         (rangeData.days > 182 or rangeData.days == 56)
            tickFont = "8px OpenSans"
            totalsValue = Math.round(totalsValue)
         else
            if xDelta < 50
               # Long format.
               tickFont = "9px OpenSans"
               if totalsValue != ''
                  if totalsValue < 999
                     totalsValue = Math.round(totalsValue)
                  else if totalsValue < 9999
                     totalsValue = "#{Math.round((totalsValue / 1000) * 10) / 10}K"
                  else if totalsValue < 99999
                     totalsValue = "#{Math.round((totalsValue / 1000) * 10) / 10}K"
                  else if totalsValue < 999999
                     totalsValue = "#{Math.round((totalsValue / 1000000) * 10) / 10}M"
            else
               # Long format.
               totalsValue = String(Math.round(totalsValue)).replace(/\B(?=(\d{3})+(?!\d))/g, ",")

         if rangeData.peopleViewConfig.totalsUnit == "allocation"
            # These conditions are modeled after the calculateColorForAllocation method
            # from lc-node-modules/common/src/charts/processors/utils/gantt.ts
            if Number(totalsValue) < 1
               totalsBackgroundColor = allocationGray
            else if Number(totalsValue) < 100
               totalsBackgroundColor = ColorUtils.blendColors("ffffff", allocatedGreen, (totalsValue % 100))
            else if Number(totalsValue) < 101
               totalsBackgroundColor = allocatedGreen
            else if Number(totalsValue) < 200
               totalsBackgroundColor = ColorUtils.blendColors("fab9bd", allocatedRed, (totalsValue % 100))
            else
               totalsBackgroundColor = allocatedRed

            totalsBackgroundColor = "##{totalsBackgroundColor}"
         else
            totalsBackgroundColor = "#6A6A6A"

         if rangeData.peopleViewConfig.totalsUnit == "allocation"
            totalsValue = "#{totalsValue}"
            totalsTextColor = ColorUtils.getVisibleTextColor(totalsBackgroundColor)
         else
            totalsTextColor = "white"

         d3.select(@).insert("rect", ':first-child')
            .attr("class", "axis-assignment-bg")
            .attr("height", 20)
            .attr("width", xDelta)
            .attr("fill", totalsBackgroundColor)

         d3.select(@).selectAll('text')
            .text(totalsValue)
            .attr('transform', 'translate(' + xDelta / 2 + ',-14)')
            .attr("fill", totalsTextColor)
            .style('font', tickFont)

   unless appending
      if rangeData.days == 1
         tickFormat = d3.timeFormat("%I:%M %p")
      else if rangeData.days < 28
         tickFormat = d3.timeFormat("%a %e")
      else if rangeData.days < 56
         if frameWidth <= 1200
            tickFormat = d3.timeFormat("%e")
         else
            tickFormat = d3.timeFormat("%a %e")
      else if rangeData.days < 91
         tickFormat = d3.timeFormat("%e")
      else if rangeData.days < 182
         tickFormat = if DateUtils.hasDayBeforeMonth(defaultStore.getDateFormat()) then d3.timeFormat("%e %b") else d3.timeFormat("%b %e")
      else if rangeData.days < 730
         tickFormat = d3.timeFormat("%b")
      else
         tickFormat = (date) ->
            month = date.getMonth() + 1
            return "Q#{Math.ceil(month / 3)}"

      if rangeData.days == 1
         secondaryTickFormat = (date) -> DateUtils.formatDate(date, defaultStore.getDateFormat(), DateUtils.LONG_FORM_OPTIONS)
      else if rangeData.days < 182
         secondaryTickFormat = d3.timeFormat("%B")
      else
         secondaryTickFormat = d3.timeFormat("%Y")

      xAxis = d3.axisBottom(xScale)
      .tickFormat(tickFormat)
      .tickSize(18.6)

      xAxis.tickValues(ticks)

      # For secondary axis
      secondaryStart = new Date(rangeStart.getTime())
      unless rangeData.days == 1
         secondaryStart.setDate(1)

      unless rangeData.days < 182
         secondaryStart.setMonth(0)

      if rangeData.days == 1
         secondaryEnd = new Date(rangeStart.getTime())
      else
         secondaryEnd = new Date(rangeEnd.getTime())
         secondaryEnd.setDate(1)

      secondaryXScale = d3.scaleTime()
      .domain([secondaryStart, secondaryEnd])
      .range([0, chartWidth])
      .clamp(true)

      if rangeData.days == 1
         secondaryTicks = secondaryXScale.ticks(d3.timeDay.every(1))
      else if rangeData.days >= 182
         secondaryTicks = secondaryXScale.ticks(d3.timeYear.every(1))
      else if rangeData.days < 730
         secondaryTicks = secondaryXScale.ticks(d3.timeMonth.every(1))
      else
         secondaryTicks = secondaryXScale.ticks(d3.timeMonth.every(3))

      secondaryXAxis = d3.axisBottom(xScale)
      .tickFormat(secondaryTickFormat)
      .tickSize(19.6)

      secondaryXAxis.tickValues(secondaryTicks)

      xAxisBar = d3.select("##{rangeData.graphBaseId}-x-axis")
      .append("svg")
         .attr("class", "x-axis-wrapper")
         .attr("width", frameWidth)
         .attr("height", 40)

      xAxisBar.append("g")
         .attr("class", "x-axis-group")
         .attr("transform", "translate(#{infoSecWidth},20)")
         .style('font', -> if rangeData.days == 1 and frameWidth < 1200 then "8px OpenSans" else "10px OpenSans")
         .call(xAxis)
         .call(adjustTextLabels)

      xAxisBar.append("g")
         .attr("class", "x-axis-group--secondary")
         .attr("transform", "translate(#{infoSecWidth},-1)")
         .style('font', "11px OpenSans")
         .call(secondaryXAxis)
         .call(adjustSecondaryTextLabels)

   # Adding assignment totals
   # This chunk is to round out the starting week if needed.
   # if rangeData.days >= 182 and rangeData.peopleViewConfig.totalsUnit == "allocation"
   #    # Need to make sure we start starting on the first day of the week that the
   #    # first day of the month falls within.
   #    totalsRangeStart = new Date(rangeStart.getTime())
   #    totalsRangeStartDay = totalsRangeStart.getDay()
   #    totalsRangeStart.setDate(totalsRangeStart.getDate() - totalsRangeStart.getDay())
   #    unless totalsRangeStartDay == 0
   #       # Calculating how far back to slide the starting range.
   #       totalsLeftRange = -Math.abs(totalsRangeStartDay * (chartWidth / rangeData.days))
   # else
   #    totalsRangeStart = rangeStart
   totalsRangeStart = rangeStart

   totalsLeftRange = 0 unless totalsLeftRange?
   totalsScale = d3.scaleTime()
   # This needs to be be 1 tick longer so that the labels can be
   # centered and it will still display properly.
   .domain([totalsRangeStart, rangeEnd])
   .range([totalsLeftRange, chartWidth])
   .clamp(true)

   # Gets valid temporal values.
   if rangeData.days == 1
      totalsTicks = totalsScale.ticks(d3.timeDay.every(1))
   else if rangeData.days < 91
      totalsTicks = totalsScale.ticks(d3.timeDay.every(1))
   # else if rangeData.days < 182 or rangeData.peopleViewConfig.totalsUnit == "allocation"
   else if rangeData.days < 182
      # Breaking totals down into weeks when month view by allocation.
      totalsTicks = totalsScale.ticks(d3.timeWeek.every(1))
   else if rangeData.days < 730
      if rangeData.peopleViewConfig.totalsUnit == "allocation"
         totalsTicks = totalsScale.ticks(d3.timeWeek.every(1))
      else
         totalsTicks = totalsScale.ticks(d3.timeMonth.every(1))
   else
      if rangeData.peopleViewConfig.totalsUnit == "allocation"
         totalsTicks = totalsScale.ticks(d3.timeMonth.every(1))
      else
         totalsTicks = totalsScale.ticks(d3.timeMonth.every(3))

   totalsAxis = d3.axisBottom(totalsScale)
   .tickFormat(tickFormat)
   .tickSize(18.6)

   totalsAxis.tickValues(totalsTicks)

   d3.selectAll(".assignment-total-bar")
      .filter (d) ->
            return d.containedAssBars > 0
      .append("g")
      .attr("transform", "translate(#{infoSecWidth},-1)")
      .style('font', "11px OpenSans")
      .call(totalsAxis)
      .call(adjustTotalsLabels)

   updateAllPageYaxis()

   # Update heights after draw.
   chartHeight = d3.select(".chart").node().getBBox().height + 46
   svg.attr("height", chartHeight) if chartHeight > frameHeight
   grid = d3.select(".grid")

   grid.selectAll('.tick line')
      .attr("y2", if chartHeight < frameHeight then frameHeight else chartHeight)

   grid.selectAll('.tick .weekend-bg')
      .attr("height", if chartHeight < frameHeight then frameHeight else chartHeight)

   grid.selectAll('.tick .today-bg')
      .attr("height", if chartHeight < frameHeight then frameHeight else chartHeight)

resetChart = (graphBaseId) ->
   $("##{graphBaseId}-chart").empty()
   $("##{graphBaseId}-x-axis").empty()

ko.bindingHandlers["peopleGanttChart2"] =
   init: (element, valueAccessor) ->
      accessor = ko.unwrap(valueAccessor())
      callbacks = accessor.callbacks
      rowData = accessor.rows()
      rangeData = accessor.rangeData
      options = {
         permissions: if accessor.permissions? then ko.unwrap(accessor.permissions) else false
      }
      isDisposed = false
      pages = []

      if accessor.rows.length > 0
         pages.push({
            id: GUID()
            rows: accessor.rows
         })

      drawGantt(element, pages, rangeData, false, options, callbacks) unless pages.length == 0

      redraw = (newRowData, newRangeData) =>
         return if isDisposed
         rowData = newRowData
         rangeData = newRangeData

         pages = [{
            id: GUID()
            rows: rowData
         }]

         drawGantt(element, pages, rangeData, false, options, callbacks)

      appendData = (newData, newRangeData) =>
         return if isDisposed
         rangeData = newRangeData
         pages.push({
            id: GUID()
            rows: newData,
         })

         drawGantt(element, pages, rangeData, true, options, callbacks)

      prependData = (newData, newRangeData) => 
         return if isDisposed
         rangeData = newRangeData
         pages.unshift({
            id: GUID()
            rows: newData,
         })

         drawGantt(element, pages, rangeData, true, options, callbacks)

      resizeTimeout = null
      resizeHandler = () =>
         if accessor.showing()
            # This causes it to only fire after resizing stops.
            clearTimeout(resizeTimeout)
            resizeTimeout = setTimeout () ->
               # To make sure it's still showing.
               if accessor.showing()
                  drawGantt(element, pages, rangeData, false, options, callbacks)
            , 250

      $(window).on("resize", resizeHandler)

      ko.utils.domNodeDisposal.addDisposeCallback element, () =>
         isDisposed = true
         resetChart(rangeData.graphBaseId)
         $(window).off("resize", resizeHandler)

      accessor.mediator.initialize({redraw: redraw, appendData: appendData, prependData: prependData})
