ko.bindingHandlers["scrolledToTop"] =
   init: (element, valueAccessor) ->
      $element = $(element)
      callback = ko.unwrap(valueAccessor().callback)
      
      handler = (e) =>
         target = e.currentTarget
         if target.scrollTop <= 0
            callback()
            
      $element.on("scroll", handler)
      $element.on("wheel", handler)
      ko.utils.domNodeDisposal.addDisposeCallback(element, -> $element.off("scroll", handler))
      ko.utils.domNodeDisposal.addDisposeCallback(element, -> $element.off("wheel", handler))