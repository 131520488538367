 ko.bindingHandlers["inputOnEnter"] =
     "init": (element, valueAccessor) ->
         accessor = ko.unwrap(valueAccessor())
         callback = ko.unwrap(accessor.callback)
         clearAfter = ko.unwrap(accessor.clearAfter)
         $(element).keypress (e) ->
             keyCode = if e.which then e.which else e.keyCode
             if keyCode == 13
                 value = $(element).val()
                 callback(value)
                 # clear input
                 $(element).val("") if clearAfter